
import { AfterViewInit, Component, Inject, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatLegacyPaginator as MatPaginator, LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { MatSort, Sort } from '@angular/material/sort';
import { MatLegacyTable as MatTable, MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { Terminals } from 'src/app/models/terminals.model';
import { VIWER_ENTORNO, environment } from 'src/environments/environment';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { QueryAggregationList, QueryFilterList, RequestFilter } from 'src/app/models/requestFilter.model';
import { TerminalService } from './terminal.service';
import { TransactionComponent } from '../transaction/transaction.component';
import { ChartPayload } from 'src/app/shared/interfaces/chart.model';
import { CommonService } from 'src/app/services/common.service';
import { HasFilesResponse, FileRequest } from 'src/app/models/Logfiles.model';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { Subscription, debounceTime, distinctUntilChanged } from 'rxjs';
import { FormNewOrdersComponent } from '../orders/form-new-orders/form-new-orders.component';
import * as XLSX from "xlsx";
import { DataExport_Terminals } from 'src/app/models/dataExport/dataExport_terminals.model';
import { DateFormats, ENTORNO, FormCreateActions } from 'src/app/util/constants';
import { Organization } from 'src/app/models/organization.model';
import { OrganizationsService } from '../organizations/organizations.service';
import { Merchants } from 'src/app/models/merchants.model';
import { MerchantService } from '../merchants/merchant.service';
import { DataExport_Terminals_Xiibero } from 'src/app/models/dataExport/dataExport_terminals_Xiibero.model';
import { UtilService } from 'src/app/services/util.service';
import { AuthService } from 'src/app/auth/auth.service';
import { ModalManagerService } from 'src/app/services/modalManager.service';
import { countryListAlpha2 } from 'src/app/util/country';
import { DashboardService } from '../../dashboard/dashboard.service';
import { TranslateService } from '@ngx-translate/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA} from '@angular/material/legacy-dialog';

enum Columns {
  expand = 'expand',
  terminalId = 'terminalId',
  serialnumber = 'serialnumber',
  merchant_name = 'merchant.name',
  merchant_merchantId = 'merchant.merchantId',
  //organization_name = 'organization.name', 
  model = 'model',
  //manufacturer = 'manufacturer',
  status = 'status',
  properties_currency = 'properties.currency',
  properties_country = 'properties.country',
  idSim = 'idSim',
  creationDate = 'createdAt',
  updatedDate = 'updatedAt',
  lastConnectionDate = 'lastConnectionAt',
  button = 'button',
}

enum ColumnsOrganization {
  expand = 'expand',
  terminalId = 'terminalId',
  serialnumber = 'serialnumber',
  merchant_name = 'merchant.name',
  merchant_merchantId = 'merchant.merchantId',
  //organization_name = 'organization.name', 
  model = 'model',
  //manufacturer = 'manufacturer',
  status = 'status',
  properties_currency = 'properties.currency',
  properties_country = 'properties.country',
  idSim = 'idSim',
  creationDate = 'createdAt',
  updatedDate = 'updatedAt',
  lastConnectionDate = 'lastConnectionAt',
  button = 'button',
}

enum ColumnsMerchant {
  expand = 'expand',
  terminalId = 'terminalId',
  serialnumber = 'serialnumber',
  merchant_name = 'merchant.name',
  merchant_merchantId = 'merchant.merchantId',
  //organization_name = 'organization.name', 
  model = 'model',
  //manufacturer = 'manufacturer',
  status = 'status',
  properties_currency = 'properties.currency',
  properties_country = 'properties.country',
  idSim = 'idSim',
  creationDate = 'createdAt',
  updatedDate = 'updatedAt',
  lastConnectionDate = 'lastConnectionAt',
  button = 'button',
}


enum ColumnsXiibero {
  terminalId = 'terminalId',
  serialnumber = 'serialnumber',
  organization_xiibero = 'hierarchy.ORGANIZATION.name',
  region_xiibero = 'hierarchy.REGION.name',
  division_xiibero = 'hierarchy.DIVISION.name',
  merchant_xiibero = 'hierarchy.MERCHANT.name',
  //merchant_name = 'merchant.name', 
  //merchant_merchantId = 'merchant.merchantId', 
  manufacturer = 'manufacturer',
  model = 'model',
  status = 'status',
  button = 'button',
  // expand = 'expand'
}

@Component({
  selector: 'app-terminal',
  templateUrl: './terminal.component.html',
  styleUrls: ['./terminal.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})



export class TerminalComponent implements OnInit, AfterViewInit {


  graph1Data: ChartPayload = {
    labels: [],
    values: []
  }
  graph2Data: ChartPayload = {
    labels: [],
    values: []
  }


  listaPaises: any = countryListAlpha2

  graficaTransaction: boolean = false;
  tablaTransaction: boolean = true;

  //  displayedColumns =  Object.keys(Columns).filter((v) => isNaN(Number(v)));
  displayedColumns = Object.keys(Columns);
  expandedElement: Terminals | null = new Terminals;
  dataSource = new MatTableDataSource<Terminals>();

  pageLength = 0;
  pageIndex = 0;
  pageSize = environment.defaultPaginacion;
  pageSizeOptions = environment.paginacion;
  sortId = "terminalId,desc";//idColumna + "," + direccion

  terminalCountryCodeSelect: string[] = ['', 'ES', 'EN'];
  terminalCurrencyCodeSelect: string[] = ['', 'EUR'];
  terminalStatusSelect: string[] = [''];

  manufacturerFilter = new FormControl();
  modelFilter = new FormControl();
  serialnumberFilter = new FormControl();
  terminalIdFilter = new FormControl();
  merchantIdFilter = new FormControl();
  statusFilter = new FormControl();
  creationFilter = new FormControl();
  updatedFilter = new FormControl();
  idSimFilter = new FormControl();
  lastConnectionFilter = new FormControl();
  merchantFilter = new FormControl();
  organizationFilter = new FormControl();

  regionXiiberoSelect!: Merchants;
  divisionXiiberoSelect!: Merchants;
  organizationXiiberoSelect!: Organization;
  organizationXiiberoList: Organization[] = [];
  merchantXiiberoSelect!: Merchants;
  regionXiiberoList: Merchants[] = [];
  divisionXiiberoList: Merchants[] = [];
  merchantXiiberoList: Merchants[] = [];

  mapQuery = new Map();

  entorno = VIWER_ENTORNO;
  isEntornoXiibero: boolean = false;
  isEntornoGetnet: boolean = false;
  verBotones: boolean = false;


  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  private confirmDialogRef!: MatDialogRef<ConfirmDialogComponent>;
  private dialogRefTer!: MatDialogRef<FormNewOrdersComponent>;

  requestFilter: RequestFilter = new RequestFilter(null, 0, this.pageSize, this.sortId);

  jerarquiaSearchSbscription!: Subscription;

  private dialogRef!: MatDialogRef<TransactionComponent>;
  

  constructor(public dialog: MatDialog,
    private terminalService: TerminalService,
    private orgService: OrganizationsService,
    private merchService: MerchantService,
    private modalManager: ModalManagerService,
    public authServer: AuthService,
    public utilService: UtilService,
    public commonService: CommonService,
    private translate: TranslateService,
    private readonly dashboardService: DashboardService,
    public dialogRefTerminal: MatDialogRef<TerminalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any | null
  ) {
    if(data?.obj?.domain){
      this.verBotones=true
    }
    this.terminalStatusSelect = ['', $localize`Active`, $localize`Inactive`, $localize`Deleted`]

  }


  exportIt() {
    let newDate = new Date();
    let nombre = "terminal-" + newDate.toISOString().slice(0, 10) + "-" + newDate.getTime();
    const fileName = nombre + ".xlsx";

    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.procesarDataExport(this.dataSource.data));
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    let Heading
    if (this.entorno == ENTORNO.XIIBERO) {
      Heading = [[this.translate.instant("Organization"), this.translate.instant("Merchant"), this.translate.instant("Terminal id"), this.translate.instant("S/N"), this.translate.instant("Manufacturer"), this.translate.instant("Model"), this.translate.instant("Status")]];
    } else {
      Heading = [[this.translate.instant("Terminal id"), this.translate.instant("S/N"), this.translate.instant("Merchant"),
      this.translate.instant("Merchant Id"), this.translate.instant("Manufacturer"),
      this.translate.instant("Model"), this.translate.instant("Status"), this.translate.instant("Currency"), this.translate.instant("Country")]];
    }
    XLSX.utils.sheet_add_aoa(ws, Heading);
    XLSX.utils.book_append_sheet(wb, ws, "terminal");

    XLSX.writeFile(wb, fileName);
  }

  private procesarDataExport(data: Terminals[]): any[] {

    if (this.entorno == ENTORNO.XIIBERO) {

      let result: DataExport_Terminals_Xiibero[] = [];
      data.forEach(row => {
        let tempRow: DataExport_Terminals_Xiibero = new DataExport_Terminals_Xiibero(row);
        result.push(tempRow);
      });
      return result;

    } else {
      let result: DataExport_Terminals[] = [];
      data.forEach(row => {
        let tempRow: DataExport_Terminals = new DataExport_Terminals(row);
        result.push(tempRow);
      });

      return result;
    }

  }


  async cargar() {
    //organizaciones
    let rfOrg: RequestFilter = new RequestFilter(null, 0, 100, "name,desc");
    let srOrg = await this.orgService.find(rfOrg, true);
    if (srOrg) {
      this.organizationXiiberoList = srOrg.content;
    }

    //traemos las REGIONES
    let sr = await this.utilService.pedirRegiones(undefined, "R");
    if (sr) {
      this.regionXiiberoList = sr.content;
    }

    //traemos las DIVISION
    sr = await this.utilService.pedirDivision(undefined, "R");
    if (sr) {
      this.divisionXiiberoList = sr.content;
    }

    //merchants  
    let srMerch = await this.utilService.pedirMerchant(undefined, "R");
    if (srMerch) {
      this.merchantXiiberoList = srMerch.content;
    }

  }



  async updateModels() {
    let filter: RequestFilter = new RequestFilter(null, 0, 500, 'model,desc');
    filter.filter = this.requestFilter.filter;
    filter.aggregation = QueryAggregationList.construirAggregation(filter.aggregation, "model", "count");

    await this.terminalService.sendAggrService(filter, this.graph1Data).then(
      value => this.graph1Data = { ...value });
  }

  updateCurrencies() {
    let filter: RequestFilter = new RequestFilter(null, 0, 500, 'model,desc');
    filter.filter = this.requestFilter.filter;
    filter.aggregation = QueryAggregationList.construirAggregation(filter.aggregation, "properties.currency", "count");

    this.terminalService.sendAggrService(filter, this.graph2Data).then(
      value => {
        this.graph2Data = { ...value };
        value.labels.forEach(element => {
          const savedCurrency = this.terminalCurrencyCodeSelect.find(currency => currency === element);
          !savedCurrency && this.terminalCurrencyCodeSelect.push(element);
        });

      });
  }

  updateCountries() {
    let filter: RequestFilter = new RequestFilter(null, 0, 500, 'model,desc');
    filter.filter = this.requestFilter.filter;
    filter.aggregation = QueryAggregationList.construirAggregation(filter.aggregation, "properties.country", "count");

    this.terminalService.sendAggrService(filter, this.graph2Data).then(
      value => {
        value.labels.forEach(element => {
          const savedCountry = this.terminalCountryCodeSelect.find(currency => currency === element);
          !savedCountry && this.terminalCountryCodeSelect.push(element);
        });

      });
  }

  async getDataSourceAndElements() {

    const dataSourceAndPageLength = await this.terminalService.lanzarLlamada(this.requestFilter);
    this.dataSource = (await dataSourceAndPageLength as any).dataSource;
    this.pageLength = (await dataSourceAndPageLength as any).pageLength;
    this.updateModels();
    this.updateCurrencies();
    this.updateCountries();
  }



  cargarCache() {

    let value = this.utilService.cargarCacheDomain();

    if (value && value != "undefined") {
      this.requestFilter.filter = QueryFilterList.construirFilter(this.mapQuery, "domain", value);
    } else {
      this.requestFilter.filter = QueryFilterList.construirFilter(this.mapQuery, "domain", undefined);
    }

    if(this.data?.obj?.domain){
      const domain = "domain";
      this.requestFilter.filter = QueryFilterList.construirFilter(this.mapQuery, domain, this.data?.obj?.domain);
    }
    this.getDataSourceAndElements();
  }




  public getPaisLogo(name: string) {
    if (name) {
      return name === 'UNKNOWN' ? '' : 'assets/flags/' + name.toUpperCase() + '.svg';
    }
    return ''
  }
  public getPaisLogName(name: string) {
    if (name) {
      return this.listaPaises[name];
    }
    return ''
  }



  verGrafica() {

    this.getDataSourceAndElements();

    this.tablaTransaction = false
    this.graficaTransaction = true

    document.getElementById('icoVerGrafica_terminal')?.classList.add("botonMarcado");
    document.getElementById('icoVerTabla_terminal')?.classList.remove("botonMarcado");

  }
  verTabla() {
    this.tablaTransaction = true
    this.graficaTransaction = false

    document.getElementById('icoVerTabla_terminal')?.classList.add("botonMarcado");
    document.getElementById('icoVerGrafica_terminal')?.classList.remove("botonMarcado");
  }


  async ngOnInit() {


    this.jerarquiaSearchSbscription = this.authServer.getJerarquiaSearchClicked().subscribe(() => this.cargarCache());


    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;



    if (this.entorno == ENTORNO.XIIBERO) {

      await this.cargar();

      this.displayedColumns = Object.keys(ColumnsXiibero);
      this.isEntornoXiibero = true;

    } else {
      this.isEntornoGetnet = true;
      if (this.commonService.isOrganizationlevel()) {
        this.displayedColumns = Object.keys(ColumnsOrganization);
      } else if (this.commonService.isMerchantlevel()) {
        this.displayedColumns = Object.keys(ColumnsMerchant);
      } else {
        this.displayedColumns = Object.keys(Columns);
      }
    }

    /*this.manufacturerFilter.valueChanges.pipe(debounceTime(700),distinctUntilChanged()).subscribe((value) => {
      console.log("manufacturerFilter="+value);   
      const idColumn = Columns.manufacturer;       
      this.requestFilter.filter = QueryFilterList.construirFilter(this.mapQuery, idColumn, value);        
      this.getDataSourceAndElements();
    });*/
    this.modelFilter.valueChanges.pipe(debounceTime(700), distinctUntilChanged()).subscribe((value) => {
      console.log("modelFilter=" + value);
      const idColumn = Columns.model;
      this.requestFilter.filter = QueryFilterList.construirFilter(this.mapQuery, idColumn, value);
      this.getDataSourceAndElements();
    });
    this.serialnumberFilter.valueChanges.pipe(debounceTime(700), distinctUntilChanged()).subscribe(value => {
      console.log("serialnumberFilter=" + value);
      const idColumn = Columns.serialnumber;
      this.requestFilter.filter = QueryFilterList.construirFilter(this.mapQuery, idColumn, value);
      this.getDataSourceAndElements();
    });
    this.terminalIdFilter.valueChanges.pipe(debounceTime(700), distinctUntilChanged()).subscribe(value => {
      console.log("terminalIdFilter=" + value);
      const idColumn = Columns.terminalId;
      this.requestFilter.filter = QueryFilterList.construirFilter(this.mapQuery, idColumn, value);
      this.getDataSourceAndElements();
    });

    this.statusFilter.valueChanges.pipe(debounceTime(700), distinctUntilChanged()).subscribe(value => {
      console.log("statusFilter=" + value);
      const idColumn = Columns.status;
      this.requestFilter.filter = QueryFilterList.construirFilter(this.mapQuery, idColumn, value);
      this.getDataSourceAndElements();
    });
    this.idSimFilter.valueChanges.pipe(debounceTime(700), distinctUntilChanged()).subscribe(value => {
      console.log("idSimFilter=" + value);
      const idColumn = Columns.idSim;
      this.requestFilter.filter = QueryFilterList.construirFilter(this.mapQuery, idColumn, value);
      this.getDataSourceAndElements();
    });

    this.creationFilter.valueChanges.pipe(debounceTime(700), distinctUntilChanged()).subscribe(value => {
      console.log("creationFilter=" + value);
       let valuePlus = null;
      if(value != null){
        valuePlus = new Date(value);
        valuePlus.setDate(value.getDate());
      }
      const idColumn = Columns.creationDate;
      this.requestFilter.filter = QueryFilterList.construirRangoFechaFilter(this.mapQuery, idColumn, value, valuePlus);
      this.getDataSourceAndElements();
    });
    this.updatedFilter.valueChanges.pipe(debounceTime(700), distinctUntilChanged()).subscribe(value => {
      console.log("updateFilter=" + value);
      let valuePlus = null;
      if(value != null){
        valuePlus = new Date(value);
        valuePlus.setDate(value.getDate());
      }
      const idColumn = Columns.updatedDate;
      this.requestFilter.filter = QueryFilterList.construirRangoFechaFilter(this.mapQuery, idColumn, value, valuePlus);
      this.getDataSourceAndElements();
    });

    this.lastConnectionFilter.valueChanges.pipe(debounceTime(700), distinctUntilChanged()).subscribe(value => {
      console.log("lastConnectionFilter=" + value);
      const idColumn = Columns.lastConnectionDate;
      let valuePlus = null;
      if(value != null){
        valuePlus = new Date(value);
        valuePlus.setDate(value.getDate());
      }
        this.requestFilter.filter = QueryFilterList.construirRangoFechaFilter(
          this.mapQuery,
          idColumn,
          value,
          valuePlus
        );
       
      this.getDataSourceAndElements();
    });


    /*this.merchantFilter.valueChanges.pipe(debounceTime(700),distinctUntilChanged()).subscribe(value => {
      console.log("statusFilter="+value);
      const idColumn = Columns.status;
      this.requestFilter.filter = QueryFilterList.construirFilter(this.mapQuery, idColumn, value);        
      this.getDataSourceAndElements();  
    });  */
    this.merchantFilter.valueChanges.pipe(debounceTime(700), distinctUntilChanged()).subscribe(value => {
      const idColumn = Columns.merchant_name;
      console.log(idColumn + "=" + value);
      this.requestFilter.filter = QueryFilterList.construirFilter(this.mapQuery, idColumn, value);
      this.getDataSourceAndElements();
    });

    this.merchantIdFilter.valueChanges.pipe(debounceTime(700), distinctUntilChanged()).subscribe(value => {
      const idColumn = Columns.merchant_merchantId;
      console.log(idColumn + "=" + value);
      this.requestFilter.filter = QueryFilterList.construirFilter(this.mapQuery, idColumn, value);
      this.getDataSourceAndElements();
    });
    /* this.organizationFilter.valueChanges.pipe(debounceTime(700),distinctUntilChanged()).subscribe(value => {
       const idColumn = Columns.organization_name;
       console.log(idColumn+"="+value);     
       this.requestFilter.filter = QueryFilterList.construirFilter(this.mapQuery, idColumn, value);        
       this.getDataSourceAndElements();   
   }); */

    this.cargarCache()

  }

  terminalStatusFilter(ob: MatSelectChange) {
    let value = ob.value;
    const idColumn = Columns.status;
    if (value && value == "") {
      value = null
    }
    this.requestFilter.filter = QueryFilterList.construirFilter(this.mapQuery, idColumn, value);
    this.getDataSourceAndElements();
  }

  terminalCountryCodeFilter(ob: MatSelectChange) {
    let value = ob.value;
    const idColumn = Columns.properties_country;
    console.log(idColumn + "=" + value);
    if (value && value == "") {
      value = null
    }
    this.requestFilter.filter = QueryFilterList.construirFilter(this.mapQuery, idColumn, value);
    this.getDataSourceAndElements();
  }

  terminalCurrencyCodeSelectFilter(ob: MatSelectChange) {
    let value = ob.value;
    const idColumn = Columns.properties_currency;
    console.log(idColumn + "=" + value);
    if (value && value == "") {
      value = null
    }
    this.requestFilter.filter = QueryFilterList.construirFilter(this.mapQuery, idColumn, value);
    this.getDataSourceAndElements();
  }



  xiiberoFilter(ob: MatSelectChange, tipo: string) {

    const idColumn = "domain";
    let value: string | undefined

    this.organizationXiiberoSelect = new Organization;
    this.regionXiiberoSelect = new Merchants;
    this.divisionXiiberoSelect = new Merchants;

    if (tipo == "Org") {
      let org: Organization
      org = ob.value;
      this.organizationXiiberoSelect = org;
      if (org) {
        value = org.domainRoot + "."
      }
    } else if (tipo == "Region") {
      let org: Merchants
      org = ob.value;
      this.regionXiiberoSelect = org;
      if (org) {
        value = org.domain + "."
      }
    } else if (tipo == "Division") {
      let org: Merchants
      org = ob.value;
      this.divisionXiiberoSelect = org;
      if (org) {
        value = org.domain + "."
      }
    }


    this.requestFilter.filter = QueryFilterList.construirFilter(this.mapQuery, idColumn, value);
    this.getDataSourceAndElements();
  }


  merchantXiiberoFilter(ob: MatSelectChange) {
    let org: Merchants
    org = ob.value;

    let value: string | undefined

    const idColumn = "domain";
    if (org) {
      value = org.domain
    } else if (this.organizationXiiberoSelect) {
      value = this.organizationXiiberoSelect.domainRoot
    }

    this.requestFilter.filter = QueryFilterList.construirFilter(this.mapQuery, idColumn, value);
    this.getDataSourceAndElements();
  }


  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  pageEvent(pag: PageEvent) {
    console.log("pageEvent=" + JSON.stringify(pag));
    this.pageSize = pag.pageSize;
    this.pageIndex = pag.pageIndex;
    this.requestFilter = RequestFilter.construirPage(this.requestFilter.filter, pag.pageIndex, pag.pageSize, this.sortId);
    this.getDataSourceAndElements();
  }

  announceSortChange(sortState: Sort) {
    console.log("ordenar=" + JSON.stringify(sortState))

    let active = sortState.active.replace("_", ".");

    if(active.startsWith("creationDate")) {
      active = Columns.creationDate;
    } else if (active.startsWith("updatedDate")) {
      active = Columns.updatedDate;
    }else if (active.startsWith("lastConnectionDate")) {
      active = Columns.lastConnectionDate;
    }

    let direccion = "";
    if (sortState.direction) {
      direccion = "," + sortState.direction
    }
    this.sortId = active + direccion;
    this.requestFilter = RequestFilter.construirPage(this.requestFilter.filter, this.pageIndex, this.pageSize, this.sortId);
    this.getDataSourceAndElements();

  }


  formExit(): void {
    console.log('Form exit action');
    this.dialogRefTerminal.close(FormCreateActions.EXIT);
  }


  viewTransaction(row: any) {

    this.dialogRef = this.dialog.open(TransactionComponent, {
      width: '95%', panelClass: 'custom-modalbox',
      data: {
        obj: row
      }
    });
    this.modalManager.registerModal(this.dialogRef);

    this.dialogRef.afterClosed().subscribe((result) => {
      if (result === 1) {
        console.log('CONFIRM recived from dialog window');
      } else if (result === 0) {
        console.log('CANCEL recived from dialog window');
      }
    });
  }


  async hasFiles(t: Terminals) {
    console.log("hasFiles call")
    let value = false;

    if (t.id !== undefined) {
      value = await this.terminalService.hasFiles(new FileRequest(t.id)).then(val => {
        let tieneFiles = val as HasFilesResponse;
        return tieneFiles.result;
      }).catch(err => {
        return false;
      });
    }
    t.hasFiles = value;

    return t.hasFiles;
  }

  async downloadFiles(row: any) {

    let requestFile: FileRequest = new FileRequest(row.id);

    let valueBlob = await this.terminalService.getTerminalFiles(requestFile);

    if (valueBlob) {

      /* DESCARGA EL PDF
       let link = document.createElement('a');
        link.download = 'hello.pdf';
        link.href = URL.createObjectURL(valueBlob);
        link.click();
        URL.revokeObjectURL(link.href);
        */

      //ABRE UNA PESTAÑA CON EL PDF
      var fileURL = URL.createObjectURL(valueBlob);
      window.open(fileURL);
    }
  }


  refrescar() {
    this.getDataSourceAndElements();
  }

  onNewOrder() {
    this.dialogRefTer = this.dialog.open(FormNewOrdersComponent, {
      width: '70%', panelClass: 'custom-modalbox-big',
      data: {//vacio al ser nuevo
      }
    });
    this.modalManager.registerModal(this.dialogRef);

    this.dialogRefTer.afterClosed().subscribe((result) => {
      if (result === 1) {
        console.log('CONFIRM recived from dialog window');
        //this.getDataSourceAndElements();
      } else if (result === 0) {
        console.log('CANCEL recived from dialog window');
      }
    });
  }

  public formatDatebyLocale() {
    return DateFormats[this.dashboardService.setFormat(navigator.language)] + ', HH:mm:ss'
  }


}
/*
function terminalStatusFilter(ob: any, MatSelectChange: typeof MatSelectChange) {
  throw new Error('Function not implemented.');
} 
*/