import { AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { CurrencyPipe} from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { DEFAULT_LOCALE, LOCALES } from '../../util/chartsLocales';
import { ApexAxisChartSeries, ApexChart, ApexXAxis, ApexTitleSubtitle, ApexYAxis } from 'ng-apexcharts';

@Component({
  selector: 'app-chart-last-months-sales-tx-amount',
  templateUrl: './chart-last-months-sales-tx-amount.component.html',
  styleUrls: ['./chart-last-months-sales-tx-amount.component.css'],
  providers: [CurrencyPipe]
})
export class ChartLastMonthsSalesTxAmountComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() chartData: { dates: string[]; values: Map<string, number[]>; operations: Map<string, number[]> } | undefined;
  @Input() currency: string = '';
  chartOptions: any;
  private chartInstance: any;

  constructor(private translate: TranslateService, private currencyPipe: CurrencyPipe) {
  }

  ngAfterViewInit(): void {

  }

  ngOnChanges(): void {
    if ( this.chartData) {
      this.initializeChartOptions();
      this.updateChartOptions();
    }
  }

  ngOnInit(): void {
 
  }

  private initializeChartOptions(): void {
    this.chartOptions = {
      series: [
        {
          name: this.translate.instant("Transactions"),
          type: 'column',
          data: []
        },
        {
          name: this.translate.instant("Amount"),
          type: 'line',
          data: []
        }
      ],
      chart: {
        type: 'line',
        stacked: false,
        toolbar: {
          show: false
        },
        zoom: {
          enabled: false
        }
      },
      stroke: {
        width: [0, 4],
      },
      dataLabels: {
        enabled: false,
        enabledOnSeries: [1]
      },
      labels: [],
      yaxis: [
        {
          title: {
            text: this.translate.instant("Transactions"),
          },
          labels: {
            formatter: function (value: number) {
              return Math.floor(value).toString();
            }
          },
          
        },
        {
          opposite: true,
          title: {
            text: this.translate.instant("Amount")
          },
          labels: {
            formatter: (val: number) => {
              return this._transformedValue(val) as any;
            }
          }
        }
      ],
    };
  }

  private _transformedValue(val: number): string | null {
    return this.currencyPipe.transform(val, this.currency, 'symbol', '1.2-2', this.translate.currentLang)
  }

  private updateChartOptions(): void {

    if (this.chartData && this.chartData.dates && this.chartData.values && this.chartData.operations) {
      const formattedDates = this.chartData.dates.map(date => {
        const [year, month] = date.split('-');
        const dateObj = new Date(Number(year), Number(month) - 1);
      
        const options: { year: 'numeric'; month: 'short' } = { year: 'numeric', month: 'short' };
        
        let formattedDate = dateObj.toLocaleDateString(localStorage.getItem("language") ?? DEFAULT_LOCALE, options);
        const [monthStr, yearStr] = formattedDate.split(' ');
        formattedDate = `${yearStr} ${monthStr.charAt(0).toUpperCase() + monthStr.slice(1)}`;
      
        return formattedDate;
      });

      this.chartOptions.labels = formattedDates;

      const transactions: number[] = [];
      const amounts: number[] = [];
      
      this.chartData.values.forEach((value, key) => {
        if (key === this.currency) {
          amounts.push(...value);
        }
      });

      this.chartData.operations.forEach((value, key) => {
        if (key === this.currency) {
          transactions.push(...value);
        }
      });

      this.chartOptions.series[0].data = transactions;
      this.chartOptions.series[1].data = amounts;
      if (this.chartInstance) {
          this.chartInstance.updateOptions(this.chartOptions);
      }
      
    }
  }
}