import { Injectable } from '@angular/core';
import { IndividualConfig, ToastrService } from 'ngx-toastr';
import { AuthService } from '../auth/auth.service';
import { OrganizationTemplate } from '../models/organization.model';
import { TypeJerarquia } from '../util/constants';

enum TransactionTypeTotype  {
  PAYMENT = 'SALE',
  PREAUTH = 'PRE_AUTH',
  TOPUP = 'MOD_PRE_AUTH',
  CONFIRM = 'CONFIRMATION',
  REFUND= 'REFUND',
  MANUAL_VOID= 'MANUAL_VOID',
  DUPLICATE= 'DUPLICATE',
  VOID= 'VOID',
  DIGITIZED_SIGNATURE= 'DIGITIZED_SIGNATURE',
  TOTALS= 'TOTALS',		
  SETTLEMENT= 'SETTLEMENT',
  TRANSACTIONS_DETAIL= 'DETAIL',
  COMMUNICATION_TEST= 'COMM_TEST',
  OTHERS= 'OTHERS',
}	

export interface toastPayload {
  message: string;
  title: string;
  ic: IndividualConfig;
  type: string;
}

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  constructor(
      private toastr: ToastrService,
      private authService: AuthService) {}

  showSuccess(toast: toastPayload) {
    this.toastr.success(
      toast.message,
      toast.title,
      toast.ic);
  }

  showWarning(toast: toastPayload) {
    this.toastr.warning(
      toast.message,
      toast.title,
      toast.ic);
  }

  isOrganizationlevel():boolean {
    try {
      let accesslvl = 100; // preguntar chema y alvaro
      let tk = this.authService.getUserAccessToken();
      let access = tk!.auth!.access as Array<any>;
      access.forEach(element => {
        let lvl = element.domain.split('.');
        if (lvl != undefined) {
          if (lvl.length < accesslvl) 
            accesslvl = lvl.length;
        }
      });
      return accesslvl == 1;
    } catch(err) {
      return false;
    }
  }

  isMerchantlevel():boolean {
    try {
      let accesslvl = 100;
      let tk = this.authService.getUserAccessToken();
      let access = tk!.auth!.access as Array<any>;
      access.forEach(element => {
        let lvl = element.domain.split('.');
        if (lvl != undefined) {
          if (lvl.length < accesslvl) 
            accesslvl = lvl.length;
        }
      });
      return accesslvl == 2;
    } catch(err) {
      return false;
    }
  }




  public checkPermiso(lstPermisos:any[],item:string,key:string,perm:string): boolean  {    
      return this.checkPermisoDominio(lstPermisos,item,key,perm,"ALL");     
  }

  public checkPermisoDominio(lstPermisos:any[],item:string,key:string,perm:string,domain:string): boolean  {
    try {
      let conPermiso=false
      lstPermisos.forEach((element) =>{

        if(element.domain==="ALL" || domain==="ALL"  || domain.includes(element.domain)){            
            //element.domain
            let permission: string | undefined
            let permissions= element!.permissions![key]
            if(permissions){
                permission  = permissions[item]          
            }

            if(((permission != undefined) && (permission.length > 0)  &&  (permission.includes(perm)))  ){
                conPermiso=true               
            }
          }
         // console.log("conPermiso="+conPermiso+" item="+item+" key="+key+" domain="+domain,"   "+JSON.stringify(element))
      });
      return conPermiso;
    } catch(err) {
      return false;
    }   
}



public checkItemRights(item:string, perm:string, domainElement:string): boolean  {
  if (this.authService.hasValidAccessToken() && this.authService.hasValidIdToken()) {
    try {

      let tk = this.authService.getUserAccessToken();
      let lstPermisos:any[]
      lstPermisos = tk!.auth!.access;
      let conPermiso=false;

        if(lstPermisos){
          conPermiso=this.checkPermisoDominio(lstPermisos,item,'merchantsApi',perm,domainElement);
          if(!conPermiso){
            conPermiso=this.checkPermisoDominio(lstPermisos,item,'adminXiibero',perm,domainElement);
            //console.log("2---conPermiso="+conPermiso+"---item="+item+" domainElement="+domainElement+" lstPermisos"+JSON.stringify(lstPermisos));

          }
      }

      return conPermiso;
    } catch(err) {
      return false;
    }
  } else {
    return false;
  }
}
  
  public checkAuthServerItemRights(item:string, perm: string, domain: string): boolean  {
    if (this.authService.hasValidAccessToken() && this.authService.hasValidIdToken()) {
      try {

        let tk = this.authService.getUserAccessToken();
        let lstPermisos:any[]
        lstPermisos = tk!.auth!.access;
        let conPermiso=false;

        if(lstPermisos){ 
            conPermiso=this.checkPermisoDominio(lstPermisos,item,'authserver',perm,domain);            
        }
        return conPermiso;
      } catch(err) {
        return false;
      }
    } else {
      return false;
    }
  }




  phoneticLookup(val:string):string {
    let result = "";
  
    // Only change code below this line
    enum lookup  {
      "alpha"= "Adams",
      "bravo"= "Boston",
    };
    
    result = <lookup>val;
    
    // Only change code above this line
    return result;
  }

  public getClientType(tp : string): string {

    let typedString = tp as keyof typeof TransactionTypeTotype;

    return TransactionTypeTotype[typedString];
  }





  public checkJerarquia(templace:OrganizationTemplate, nivel:TypeJerarquia): boolean  {
    if (templace && nivel) {     
        let permiso = false;

        if(nivel == TypeJerarquia.REGION && templace.hasRegions) {     
            permiso = templace.hasRegions;
        }else if(nivel == TypeJerarquia.DIVISION && templace.hasDelegations) {     
            permiso = templace.hasDelegations;
        }else if(nivel == TypeJerarquia.SUBSIDIARY && templace.hasSubsidiary) {     
            permiso = templace.hasSubsidiary;
        }
     
        return permiso;
      
    } 
      
    return false;

  }

  public checkMenuAccess(item:string): boolean  {
    if (this.authService.hasValidAccessToken() && this.authService.hasValidIdToken()) {
      try {
        let conPermiso=false;
  
        let tk = this.authService.getUserAccessToken();
        let lstPermisos:any[]
        lstPermisos = tk!.auth!.access;
  
        if(lstPermisos){
            conPermiso=this.checkPermissions(lstPermisos,item,'merchantsApi') || this.checkPermissions(lstPermisos,item,'adminXiibero');
        }
  
        return conPermiso;
      } catch(err) {
        return false;
      }
    } else {
      return false;
    }
  }
  
  public checkPermissions(lstPermisos:any[],item:string,key:string): boolean  {
      try {
        let conPermiso=false
        lstPermisos.forEach((element) =>{
            //element.domain
            let permission: string | undefined
            let permissions= element!.permissions![key]
            if(permissions){
              permission  = permissions[item]          
            }
            
            if(((permission != undefined) && (permission.length > 0))){
                conPermiso=true               
            }
  
        });
  
        return conPermiso;
      } catch(err) {
        return false;
      }   
  }







}