
<div class="pagetitle">
    <h1  i18n>{{"Region" | translate}}</h1>      
 </div>
 <section class="section">
    <form [formGroup]="registerForm">


     <div class="row">             
         <div class="col-lg-4">
            <div class="card cardCustos">
                <mat-form-field ngDefaultControl>
                     <h5 i18n>{{"Name" | translate}} <span class="obligatorio">*</span></h5>
                     <input formControlName="name" matInput type="text" matInput required maxlength="40">
                     <mat-error *ngIf="registerForm.get('name')?.hasError('required')"  i18n>{{"Please enter a name!" | translate}}</mat-error>
                     <mat-error *ngIf="registerForm.get('name')?.hasError('pattern')"  i18n>{{"Invalid input format" | translate}}</mat-error>
                     <mat-error *ngIf="registerForm.get('name')?.hasError('maxlength')"  i18n>{{"Maximum length exceeded" | translate}}</mat-error>
                 </mat-form-field>
             </div>
         </div>  
         <div class="col-lg-4">
            <div class="card cardCustos">
                <mat-form-field ngDefaultControl>
                     <h5 i18n>{{"Short Name" | translate}}</h5>
                     <input formControlName="shortname" matInput type="text" maxlength="5">
                     <mat-error *ngIf="registerForm.get('shortname')?.hasError('pattern')"  i18n>{{"Invalid input format" | translate}}</mat-error>
                     <mat-error *ngIf="registerForm.get('shortname')?.hasError('maxlength')"  i18n>{{"Maximum length exceeded" | translate}}</mat-error>
                 </mat-form-field>
             </div>
         </div>                 
        <div class="col-lg-4">
           <div class="card cardCustos">
                <mat-form-field ngDefaultControl>
                    <h5 i18n>{{"Organization" | translate}}  <span class="obligatorio">*</span></h5>                   
                    <mat-select formControlName="orgMarcado" [(value)]="selectedOrg"  (selectionChange)="selectorganizacion($event.value)">
                        <mat-option *ngFor="let op of listaOrg" [value]="op">
                            {{op.name}}
                        </mat-option>
                    </mat-select>  
                    <mat-error *ngIf="registerForm.get('orgMarcado')?.hasError('required')"  i18n>{{"REQUIRED_FIELD" | translate}}</mat-error>
                </mat-form-field>                  
            </div>
        </div>           
    </div>


     <div class="row">
        <div class="col-lg-4">
           <div class="card cardCustos">
               <mat-form-field ngDefaultControl>
                    <h5 i18n>{{"Phone" | translate}} </h5>
                    <input formControlName="phone" matInput type="text" matInput >
               </mat-form-field>
            </div>
        </div>
        <div class="col-lg-4">
            <div class="card cardCustos">
                <mat-form-field ngDefaultControl>
                     <h5 i18n>{{"Email" | translate}} </h5>
                     <input formControlName="email" matInput type="text" matInput >
                     <mat-error *ngIf="registerForm.get('email')?.hasError('pattern')"  i18n>{{"Invalid input format" | translate}}</mat-error>
                     <mat-error *ngIf="registerForm.get('email')?.hasError('maxLength')"  i18n>{{"Maximum length exceeded" | translate}}</mat-error>
                     <mat-error *ngIf="registerForm.get('email')?.hasError('email')"  i18n>{{"email format error" | translate}}</mat-error>
                </mat-form-field>
             </div>
        </div>
        <div class="col-lg-4">
            <div class="card cardCustos">
                <mat-form-field ngDefaultControl>
                     <h5 i18n>{{"Processor" | translate }} <span class="obligatorio">*</span></h5>
                     <mat-select formControlName="procesador" [(value)]="selectedProc"  (selectionChange)="selectProcess($event.value)">
                         <mat-option *ngFor="let op of listProcesadorFinal" [value]="op">
                             {{op.name}}
                         </mat-option>
                     </mat-select> 
                   
                </mat-form-field>
             </div>
         </div>
    </div>


    <div class="row" *ngIf="verProcessor && commonService.checkItemRights('processor','W','ALL')">
           <div class="col-lg-12">
               <div class="card cardCustos">
                   <h5 i18n>{{"Processor" | translate }}</h5>
                        
                            <ng-container formArrayName="mapaDatos" *ngIf="mapaDatosFormGroups['controls'].length > 0">

                                <div  *ngFor="let fila of mapaDatosFormGroups['controls']; index as i" [formGroupName]="i">
                                    <div class="row mb-2 col-12" *ngIf="getshortNamemapaDatosFormGroups(i)===selectedProc.shortName">
                                        
                                        <div class="col-5">
                                            <input type="text" class="form-control" formControlName="llave" placeholder="{{'key' | translate }}">
                                        </div>
                                        
                                        <div class="col-6">
                                            <input type="text" class="form-control" formControlName="valor" placeholder="{{'value' | translate }}">
                                        </div>
                                    
                                        <div class="col-1">
                                            <button class="btn btn-danger" (click)="borrarGrupo(i)">{{"delete" | translate }}</button>
                                        </div>
                                    </div>
                                </div>
                            
                            </ng-container>

                            <button  class="btn btn-aceptar" (click)="onAddLista()" i18n>{{"Add" | translate }}</button> 
                </div>
            </div>
    </div>

    <div class="row botonera">
         <div class="col-lg-12">
                 <button  class="btn btn-aceptar" (click)="onFormSubmit()" i18n>{{"Submit" | translate}}</button> 
                 <button  class="btn btn-cancelar" (click)="formExit()" i18n>{{"Cancel" | translate}}</button>
         </div>
     </div>


   
 </form>
 </section>

             
             
             
             
             
             

