import { Organization } from "./organization.model";
import { Terminals } from "./terminals.model";

export class ObjHasMap {
  llave!: string;
  valor!: string;
}



