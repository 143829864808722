import { Injectable } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Observable, catchError, from, of, shareReplay } from 'rxjs';
import { Merchants } from 'src/app/models/merchants.model';
import { QueryFilterList, RequestFilter } from 'src/app/models/requestFilter.model';
import { MerchantInfo } from 'src/app/models/transaction.model';
import { RestService } from 'src/app/services/merchant/Rest.service';
import { ENTORNO, MerchantRestUrl } from 'src/app/util/constants';
import { VIWER_ENTORNO } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class MerchantService {
  constructor(private merchantRest : RestService) {
    this.initializeMerchants();
  }
  private merchants$!: Observable<any>;

  entorno = VIWER_ENTORNO;



  async lookup(type:string, showLoading: boolean){
    let value = null;
//    if(this.entorno==ENTORNO.XIIBERO){
      // Ignoramos el objeto de entrada
      var endpoint:string="merchant/lookup";
      switch(type){
        case "REGION":
          endpoint += "/regions"
          break;
        case "DIVISION":
          endpoint += "/divisions"
          break;
        case "MERCHANT":
          endpoint += "/merchants"
          break;
        case "ORGANIZATION":
            endpoint = "/organization/lookup"
            break;
        default:
      }

      value = await this.merchantRest.commonRestCallXiibero({},endpoint,showLoading,true)   
      .catch(err => {
        return null
      })

    // } else {
    //   // MERCHANT_PORTAL | GETNET
    //   value = await this.findRead(_body);
    // }
    return value;   
  }

    /**
   * Funciona para traer los Objetos sobre los que podemos VER
   * @param _body 
   * @returns 
   */
  async findRead(_body: RequestFilter){
    let value = null;
    if(this.entorno==ENTORNO.XIIBERO){
      value = await this.merchantRest.commonRestCallXiibero(_body,'merchant/',true,true)   
      .catch(err => {
        return null
      })
      }else{       
        value = await this.merchantRest.commonRestCallMerchant(_body,'merchant/query',true,true)   
        .catch(err => {
          return null
        })
      }
    return value;   
  }

  async lanzarLlamada(requestFilter: RequestFilter){
    let value = await this.findRead(requestFilter);
    console.log(JSON.stringify(value))
    if(value){
      console.log(JSON.stringify(value.content))
    }
    return {
      dataSource: new MatTableDataSource<Merchants>(value?.content),
      pageLength: value?.totalElements
    }
  }

  private initializeMerchants(): void {
    
      if (this.entorno === ENTORNO.XIIBERO) {

        let mapQuery = new Map();
        let filter = QueryFilterList.construirFilter(mapQuery, "", "");       
        if(this.entorno==ENTORNO.XIIBERO){
          filter = QueryFilterList.construirFilter(mapQuery, "type", "MERCHANT"); 
        }    
        
        let rfRegi: RequestFilter= new RequestFilter(filter,0, 100,"name,desc");   

        this.merchants$ = from(
          this.merchantRest.commonRestCallXiibero(rfRegi, 'merchant/', false, true))
          .pipe(
            catchError(err => {
              console.error('Error fetching merchants', err);
              return of([]);
            }),
            shareReplay(1)
          );
      } else {
        
        let rfOrg: RequestFilter= new RequestFilter(null,0, 200,"name,desc");  
        this.merchants$ = from(
          this.merchantRest.commonRestCallMerchant(rfOrg, 'merchant/query', false, true))
          .pipe(
            catchError(err => {
              console.error('Error fetching merchants', err);
              return of([]);
            }),
            shareReplay(1)
          );
      }
    
  }


  getFindResult() {
    console.log('se realizo operacion de merchants');
    return this.merchants$; 
  }  


  /**
   * Funciona para traer los Objetos sobre los que podemos EDITAR
   * @param _body 
   * @returns 
   */
  async findWrite(_body: RequestFilter){
    let value = null;
    if(this.entorno==ENTORNO.XIIBERO){
      value = await this.merchantRest.commonRestCallXiibero(_body,'merchant/findToWrite',true,true)   
      .catch(err => {
        return null
      })
      }else{       
        value = await this.merchantRest.commonRestCallMerchant(_body,'merchant/query',true,true)   
        .catch(err => {
          return null
        })
      }
    return value;   
  }

   

  async saveEditService(_body: Merchants){
    let value = await this.merchantRest.commonRestCallXiibero(_body, 'merchant/save',true,true)   
    .catch(err => {
      return null
    })

    return value;   
  }
  
  async updateEditService(_body: Merchants) {
    let value = await this.merchantRest.commonRestCallXiibero(_body, 'merchant/update',true,true)   
    .catch(err => {
      return null
    })

    return value;   
  }

  async deleteService(_id: string){
    let _body={"id":_id} 
    let value = await this.merchantRest.commonRestCallXiibero(_body, 'merchant/delete',true,true)   
    .catch(err => {
      return null
    })
    return value;   
  }

}