<div>
    <apx-chart
      [series]="chartOptions.series!"
      [chart]="chartOptions.chart!"
      [plotOptions]="chartOptions.plotOptions!"
      [legend]="chartOptions.legend!"
      [xaxis]="chartOptions.xaxis!"
      [yaxis]="chartOptions.yaxis!"
      [dataLabels]="chartOptions.dataLabels!">
    </apx-chart>
  </div>
