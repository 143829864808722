<main id="main" class="main">


  <div class="pagetitle">
    <h1  i18n>{{"Users" | translate }}</h1>  
    <div>
      <!-- chema preguntar -->
      <button  *ngIf="commonService.checkAuthServerItemRights('user','W',domainSelect)" mat-mini-fab (click)="onNew()"> 
       <i class="bi bi-plus-lg"></i>
      </button>
    </div>
</div>
  
  
    
    <div class="mat-elevation-z8 tablaPersonal">
      <table mat-table [dataSource]="dataSource" matSort (matSortChange)="announceSortChange($event)">  
  
  
        <!-- firstName Column -->
        <ng-container matColumnDef="firstName">
          <th mat-header-cell *matHeaderCellDef><div mat-sort-header i18n>{{"FirstName" | translate }}</div>  
            <div>
              <mat-form-field>
                      <mat-icon matPrefix class="my-icon">filter_list </mat-icon>
                      <input matInput class="form-field" [formControl]="firstNameFilter" placeholder="">
              </mat-form-field>
            </div>
          </th>       
          <td mat-cell *matCellDef="let element"> {{element.firstName}} </td>
        </ng-container>
  
  
        <!-- lastName Column -->
        <ng-container matColumnDef="lastName">
          <th mat-header-cell *matHeaderCellDef><div mat-sort-header i18n>{{"LastName" | translate }}</div>  
            <div>
              <mat-form-field>
                <mat-icon matPrefix class="my-icon">filter_list </mat-icon>
                      <input matInput class="form-field" [formControl]="lastNameFilter" placeholder="">
              </mat-form-field>
            </div>
          </th>       
          <td mat-cell *matCellDef="let element"> {{element.lastName}} </td>
        </ng-container>

    <!-- username Column-->
    <ng-container matColumnDef="username">
      <th mat-header-cell *matHeaderCellDef><div mat-sort-header i18n>{{"Username" | translate }}</div>  
        <div>
          <mat-form-field>
            <mat-icon matPrefix class="my-icon">filter_list </mat-icon>
                  <input matInput class="form-field" [formControl]="userNameFilter" placeholder="">
          </mat-form-field>
        </div>
      </th>       
      <td mat-cell *matCellDef="let element"> {{element.username}} </td>
    </ng-container> 

    <!-- organization Column-->
    <ng-container matColumnDef="organization">
      <th mat-header-cell *matHeaderCellDef><div mat-sort-header i18n>{{"Organization" | translate }}</div>  
        <div>
          <mat-form-field>
            <mat-icon matPrefix class="my-icon"><i class="bi bi-filter"></i> </mat-icon>         
            <mat-select  [(ngModel)]="organizationSelect" (selectionChange)="domainFilter($event,'Org')">            
               
              <mat-form-field class="filterSelect">
                <mat-label> </mat-label>
                <input matInput placeholder="filter..." #filterplc>
              </mat-form-field>
  
              <mat-option [value]=""> </mat-option>
              <div *ngFor="let op of organizationList">
                <mat-option  *ngIf="op.name.includes(filterplc.value)" [value]="op" >
                  {{op.name}}
                </mat-option>
              </div>         
            </mat-select>
          </mat-form-field>
        </div>
      </th>       
      <td mat-cell *matCellDef="let element" [matTooltip]="isAdmin ? (element.domain | formatOrganizationName:true) : ''"> {{element.domain | formatOrganizationName}} </td>
    </ng-container> 

    <!-- merchant Column-->
    <ng-container matColumnDef="merchant">
      <th mat-header-cell *matHeaderCellDef><div mat-sort-header i18n>{{"Merchant" | translate }}</div>  
        <div>
          <mat-form-field>
            <mat-icon matPrefix class="my-icon"><i class="bi bi-filter"></i> </mat-icon>         
            <mat-select  [(ngModel)]="merchantSelect" (selectionChange)="domainFilter($event,'Merchant')">            
               
              <mat-form-field class="filterSelect">
                <mat-label> </mat-label>
                <input matInput placeholder="filter..." #filterplc>
              </mat-form-field>
  
              <mat-option [value]=""> </mat-option>
              <div *ngFor="let op of merchantList">
                <mat-option  *ngIf="op.name.includes(filterplc.value)" [value]="op" >
                  {{op.name}}
                </mat-option>
              </div>         
            </mat-select>
          </mat-form-field>
        </div>
      </th>       
      <td mat-cell *matCellDef="let element" [matTooltip]="isAdmin ? (element.domain) : ''"> {{element.domain | formatMerchantName}} </td>
    </ng-container> 


  <!-- email Column -->
  <ng-container matColumnDef="email">
    <th mat-header-cell *matHeaderCellDef><div mat-sort-header i18n>{{"Email" | translate }}</div>  
      <div>
        <mat-form-field>
            <mat-icon matPrefix class="my-icon">filter_list </mat-icon>
                <input matInput class="form-field" [formControl]="emailFilter" placeholder="">
        </mat-form-field>
      </div>
    </th>       
    <td mat-cell *matCellDef="let element"> {{element.email}} </td>
  </ng-container>
    
  <!-- status Column -->
  <ng-container matColumnDef="status">
    <th mat-header-cell *matHeaderCellDef><div mat-sort-header i18n>{{"Status" | translate }}</div>  
      <div>
        <mat-form-field>
          <mat-icon matPrefix class="my-icon">filter_list </mat-icon>
          <mat-label></mat-label>
          <mat-select  (selectionChange)="statusSelectFilter($event)">
            <mat-option *ngFor="let op of statusSelect" [value]="op">
              {{translations[op]}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </th>       
    <td mat-cell *matCellDef="let element"  class="DEFAULT {{element.status}} "> <div>{{ translations[element.status] }}</div> </td>
  </ng-container>

  <!-- lastLogin Column -->        
  <ng-container matColumnDef="lastLogin">
    <th mat-header-cell *matHeaderCellDef > <div mat-sort-header i18n>{{"Last Login" | translate }}</div> 
      <div>
        <mat-form-field> 
            <mat-icon matPrefix class="my-icon">filter_list </mat-icon>
          <mat-label></mat-label>
          <mat-date-range-input [formGroup]="lastLoginFilter" [rangePicker]="picker">
            <input matStartDate formControlName="start" placeholder="{{'Start Date' | translate }}">
            <input matEndDate formControlName="end" placeholder="{{'End Date' | translate}}">
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>
      </div>
    </th>
    <td mat-cell *matCellDef="let element"> {{calculateTimeZone(element.lastLogin)}}</td>
  </ng-container>


    <!-- Create Column -->        
    <ng-container matColumnDef="createdAt">
      <th mat-header-cell *matHeaderCellDef > <div mat-sort-header i18n>{{"Create Date" | translate }}</div> 
        <div>
          <mat-form-field> 
              <mat-icon matPrefix class="my-icon">filter_list </mat-icon>
            <mat-label></mat-label>
            <mat-date-range-input [formGroup]="dateCreateFilter" [rangePicker]="picker">
              <input matStartDate formControlName="start" placeholder="{{'Start Date' | translate }}">
              <input matEndDate formControlName="end" placeholder="{{'End Date' | translate}}">
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
          </mat-form-field>
        </div>
      </th>
      <td mat-cell *matCellDef="let element"> {{calculateTimeZone(element.createdAt)}}</td>
    </ng-container>
    


  <ng-container matColumnDef="view" >
    <th mat-header-cell *matHeaderCellDef ></th>
    <td mat-cell *matCellDef="let element" class="button">   
      <div >
        <button *ngIf="commonService.checkAuthServerItemRights('user','R', element.domain) && element.status==='ACTIVE'" matTooltip="{{'reset password' | translate }}" (click)="onResetPassword(element)" mat-icon-button color="primary">
          <i class="bi bi-key"></i>
        </button>
        <button *ngIf="commonService.checkAuthServerItemRights('user','R', element.domain) && element.status==='PREACTIVE'" matTooltip="{{'resend invitation' | translate}}" (click)="onResetInvitacion(element)" mat-icon-button color="primary">
          <i class="bi bi-send"></i>
        </button>
        <button *ngIf="commonService.checkAuthServerItemRights('user','W', element.domain) && element.status==='QUARENTINE' || element.status==='QUARANTINE'" matTooltip="{{'reactivate quarantined user' | translate}}" (click)="onActivateQuarantinedUser(element)" mat-icon-button color="primary">
          <i class="bi bi-unlock"></i>
        </button>
        <button *ngIf="commonService.checkAuthServerItemRights('user','D', element.domain)" matTooltip="{{'delete' | translate }}" (click)="onDelete(element)" mat-icon-button color="primary">
          <i class="bi bi-trash"></i>
        </button>
      </div>
    </td>
  </ng-container>
      
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>

      <mat-paginator [length]="pageLength"
        [pageSize]="pageSize"
        [pageSizeOptions]="pageSizeOptions"
        (page)="pageEvent($event)"
        showFirstLastButtons 
        aria-label="Select page of periodic elements">
      </mat-paginator>

    </div>
    </main>
