import { Organization } from "../organization.model";

export class DataExport_Organization {

  name: string | undefined; 
  created: string | undefined;


  constructor(values: Organization = new Organization) {
    this.name= values.name
    this.created= values.createdAt
  }


}


