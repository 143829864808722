import { AfterViewInit, ChangeDetectorRef, Component, Inject, OnChanges, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ConfirmDialogActions, ConfirmDialogData } from './confirm-dialog.model';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.css']
})



export class ConfirmDialogComponent implements OnInit, AfterViewInit {

  enableCancel: boolean=true;
  visibleButtom: boolean=true;

  constructor(@Inject(MAT_DIALOG_DATA) public data: ConfirmDialogData,
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,    
    private cdr: ChangeDetectorRef) {

      if(data.enableCancel){    this.enableCancel=data.enableCancel;}
      if(data.visibleButtom){   this.visibleButtom=data.visibleButtom;}    

    }


  ngOnInit() {
    this.cdr.detectChanges();
  }

  ngAfterViewInit(): void {
    this.cdr.detectChanges();
  }
  
  confirm(): void {
    this.dialogRef.close(ConfirmDialogActions.CONFIRM);
  }

  cancel(): void {
      this.dialogRef.close(ConfirmDialogActions.CANCEL);
  }


}
