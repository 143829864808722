<h1 mat-dialog-title i18n>{{type==="REFUND"? ("Refund Operation" | translate) : ("Confirm Operation" | translate)}}</h1>
<div *ngIf="!response" mat-dialog-content >
    <p i18n>{{getEntireOrPartial()}}</p>
    <p i18n *ngIf="trx.trx?.amount">{{"Transaction Amount" | translate }} <b>{{trx.trx?.amount?.toFixed(2)}} {{trx.trx?.currency}}</b>.</p>
    <p i18n>{{"Max. Amount" | translate }} <b>{{maxAmount.toFixed(2)}} {{trx.trx?.currency}}</b></p>
    <p i18n *ngIf="trx.trx?.amountRefunded">{{"Amount already refunded" | translate }} <b>{{trx.trx?.amountRefunded?.toFixed(2)}} {{trx.trx?.currency}}</b></p>
    <form [formGroup]="registerForm">

        <mat-form-field style="width: 80%;" ngDefaultControl>
                <h5 i18n>{{"Amount" | translate }} <span class="obligatorio">*</span></h5>
                <div style="display: flex; align-items: baseline">
                    <input formControlName="valor" matInput type="number" required style="font-size: 50px;"> 
                    <h1 style="font-size: 30px; padding-left: 10px;">{{trx.trx?.currency}}</h1>
                </div>
                <mat-error *ngIf="registerForm.get('valor')?.hasError('required')"  i18n>{{"Please enter a number!" | translate}}</mat-error>
                <mat-error *ngIf="registerForm.get('valor')?.hasError('pattern')"  i18n>{{"Invalid input format" | translate}}</mat-error>
                <mat-error *ngIf="registerForm.get('valor')?.hasError('max')"  i18n>{{"The value cannot exceed the maximum amount" | translate }}</mat-error>
        </mat-form-field>

    </form>
</div>  
<div *ngIf="response" mat-dialog-content >
    <p i18n>{{response}}</p>
</div>
<div  mat-dialog-actions class=" botonera">     
    <button *ngIf="!response" mat-button (click)="confirm()" class="btn btn-aceptar" i18n>{{"Confirm" | translate }}</button>       
    <button *ngIf="!response" mat-button (click)="cancel()" class="btn btn-cancelar" i18n>{{"Cancel" | translate }}</button>     
    <button *ngIf="response" mat-button (click)="close()" class="btn btn-aceptar" i18n>{{"Close" | translate }}</button> 
</div>

