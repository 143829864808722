 
export function valueOrDefault<T>(value: T | undefined, defaultValue: T) {
  return typeof value === 'undefined' ? defaultValue : value;
}
 
 const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

export function months(config: any) {
  var cfg = config || {};
  var count = cfg.count || 12;
  var section = cfg.section;
  var values = [];
  var i, value;

  for (i = 0; i < count; ++i) {
    value = MONTHS[Math.ceil(i) % 12];
    values.push(value.substring(0, section));
  }

  return values;
}

const COLORS = [
  '#4dc9f6',
  '#f67019',
  '#f53794',
  '#537bc4',
  '#acc236',
  '#166a8f',
  '#00a950',
  '#58595b',
  '#8549ba'
];

export function color(index: any) {
  return COLORS[index % COLORS.length];
}
/*
export function transparentize(value, opacity) {
  var alpha = opacity === undefined ? 0.5 : 1 - opacity;
  return colorLib(value).alpha(alpha).rgbString();
}
*/
export const CHART_COLORS = {
  blue: '#008FFB',
  purple: '#9966FF',
  pink: '#F131B9',
  red: '#E16384',
  orange: '#FF9F40',
  yellow: '#FFCD56',
  vividGreen: '#27DF0E',
  vividBlue: '#00F0FB',
  viviPurple: '#DA66FF',
  vividRed: '#FF2424',
  vividOrange: '#FF8700',
  vividYellow: '#FFED88',
  darkerGreen: '#048B04',
  darkerBlue: '#0A30BE',
  darkerPurple: '#6943A3',
  darkerPink: '#941C71',
  darkerRed: '#B41414',
  darkerOrange: '#B05308',
  daarkerYellow: '#72540C',
  cyan: '#00FFFF',
  magenta: '#FF00FF',
  trueGreen: '#008000',
  green: '#00E396'
};

export const NAMED_COLORS = [
  CHART_COLORS.green,
  CHART_COLORS.blue,
  CHART_COLORS.purple,
  CHART_COLORS.pink,
  CHART_COLORS.red,
  CHART_COLORS.orange,
  CHART_COLORS.yellow,
  CHART_COLORS.green,
  CHART_COLORS.blue,
  CHART_COLORS.purple,
  CHART_COLORS.pink,
  CHART_COLORS.red,
  CHART_COLORS.orange,
  CHART_COLORS.yellow,
];

export function namedColor(index: any) {
  return NAMED_COLORS[index % NAMED_COLORS.length];
} 
