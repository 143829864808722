import { AfterViewInit, Component, Input, OnChanges, OnInit } from '@angular/core';
import { CurrencyPipe} from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import {
  ApexAxisChartSeries,
  ApexChart,
  ChartComponent,
  ApexDataLabels,
  ApexPlotOptions,
  ApexYAxis,
  ApexLegend,
  ApexStroke,
  ApexXAxis,
  ApexFill,
  ApexTooltip,
} from 'ng-apexcharts';

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  yaxis: ApexYAxis;
  xaxis: ApexXAxis;
  fill: ApexFill;
  tooltip: ApexTooltip;
  stroke: ApexStroke;
  legend: ApexLegend;
};
@Component({
  selector: 'app-chart-last-months-amount',
  templateUrl: './chart-last-months-amount.component.html',
  styleUrls: ['./chart-last-months-amount.component.css'],
  providers: [CurrencyPipe]
})
export class ChartLastMonthsAmountComponent implements OnInit, OnChanges, AfterViewInit{
  @Input() chartData: { dates: string[]; values: Map<string, Map<string, number[]>>; operations: Map<string, Map<string, number[]>>; amounts: Map<string, Map<string, number[]>> } | undefined;
  @Input() currency: string = '';
  chartOptions: any;
  private chartInstance: any;
  

  constructor(private translate: TranslateService, private currencyPipe: CurrencyPipe) {
  }

  ngAfterViewInit(): void {

  }

  ngOnChanges(): void {
    if ( this.chartData) {
      this.initializeChartOptions();
      this.updateChartOptions();
    }
  }

  ngOnInit(): void {
 
  }

  private initializeChartOptions(): void {

  this.chartOptions = {
    series: [
    ],
    chart: {
      type: 'bar',
      stacked: true,
      toolbar: {
        show: false
      },
      zoom: {
        enabled: false
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 10,
        borderRadiusApplication: 'end',
        borderRadiusWhenStacked: 'last',
        dataLabels: {
          total: {
            enabled: false,
            formatter: (val: number) => {
              return this._transformedValue(val);
            },
            style: {
              fontSize: '8px',
            }
          }
        }
      }
    },
    xaxis: {
      type: 'category',
      categories: [],
      labels: {
        style: {
          fontSize: '12px'
        }
      }
    },
    yaxis: [
      {
        labels: {
          formatter: (val: number) => {
            return this._transformedValue(val) as any;
          }
        }
      }
    ],
    legend: {
      position: 'right',
      fontSize: '7px',
      offsetY: 10
    },
    fill: {
      opacity: 1,
    },
    dataLabels: {formatter: (val: number) => {
      return this._transformedValue(val) as any;
    }}
  };
  }

  private _transformedValue(val: number): string | null {
    return this.currencyPipe.transform(val, this.currency, 'symbol', '1.2-2', this.translate.currentLang)
  }

  private updateChartOptions(): void {
    if (this.chartData && this.chartData.dates && this.chartData.values) {
      const formattedDates = this.chartData.dates.map(date => {
        const [year, month] = date.split('-');
        const dateObj = new Date(Number(year), Number(month) - 1);
        const options: { year: 'numeric'; month: 'short' } = { year: 'numeric', month: 'short' };
        let formattedDate = dateObj.toLocaleDateString(localStorage.getItem("language") ?? 'en', options);
        const [monthStr, yearStr] = formattedDate.split(' ');
        formattedDate = `${yearStr} ${monthStr.charAt(0).toUpperCase() + monthStr.slice(1)}`;
        return formattedDate;
      });
  
      this.chartOptions.xaxis.categories = formattedDates;
  
      const paymentData: number[] = [];
      const refundData: number[] = [];
      const voidData: number[] = [];
      const preauthData: number[] = [];
      const topupData: number[] = [];
  
      this.chartData.values.forEach((currencyDataMap: Map<string, number[]>, currency: string) => {
        if (currency === this.currency) {
          currencyDataMap.forEach((transactionData: number[], transactionType: string) => {
            switch (transactionType) {
              case 'PAYMENT': paymentData.push(...transactionData); break;
              case 'REFUND': refundData.push(...transactionData); break;
              case 'VOID': voidData.push(...transactionData); break;
              case 'PREAUTH': preauthData.push(...transactionData); break;
              case 'TOPUP': topupData.push(...transactionData); break;
            }
          });
        }
      });
  
      const totalData = paymentData.map((_, index) => {
        return (paymentData[index] || 0) +
               (refundData[index] || 0) +
               (voidData[index] || 0) +
               (preauthData[index] || 0) +
               (topupData[index] || 0);
      });
  
      this.chartOptions.series = [
        { name: this.translate.instant("PAYMENT"), data: paymentData },
        { name: this.translate.instant("REFUND"), data: refundData },
        { name: this.translate.instant("VOID"), data: voidData },
        { name: this.translate.instant("PREAUTH"), data: preauthData },
        { name: this.translate.instant("Modify_preauthoritations"), data: topupData }
      ];
  
      this.chartOptions.dataLabels = {
        enabled: true,
        formatter: (val: number, opts: any) => {
          const index = opts?.dataPointIndex;
          if (index === undefined || index < 0 || index >= totalData.length) {
            return '0%';
          }
          const total = totalData[index];
          return total > 0 ? Math.round((val / total) * 100) + '%' : '0%';
        }
      };
  
      if (this.chartInstance) {
        this.chartInstance.updateOptions(this.chartOptions);
      }
    }
  }

  
}

