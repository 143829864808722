import { CurrencyPipe, formatDate } from '@angular/common';
import { Component, OnInit, ElementRef, Input, AfterViewInit, OnChanges, ViewChild } from '@angular/core';
import { Chart } from 'chart.js';
import { NAMED_COLORS, CHART_COLORS } from '../../util/chartsutils';
import { TranslateService } from '@ngx-translate/core';
import { DateFormats, ShortDateFormats } from 'src/app/util/constants';
import { DashboardService } from 'src/app/pages/dashboard/dashboard.service';

export interface chartPayload {
  labels: string[];
  values: number[];
}

@Component({
  selector: 'app-chart-tx-compare',
  templateUrl: './chart-tx-compare.component.html',
  styleUrls: ['./chart-tx-compare.component.css'],
  providers: [CurrencyPipe]
})
export class ChartTxDailyCompareComponent implements OnInit, OnChanges, AfterViewInit  {
  @Input() sequence!: string;
  @Input() chartTitle !: string;
  @Input() dashboardCurrency: string = 'EUR';
  @Input() dataToCompare: any = {
      dates : [],
      values : [],
      prevValues : [],
      prevDates: []
  };
  @Input() importsChart?: boolean;
  @ViewChild('chartCmpTx') chartCmpTx!: ElementRef<HTMLCanvasElement>;

  private localeFormat: keyof typeof DateFormats= 'en';

  chart: any;
  data : any;
  private initialized: boolean = false;
  private translations: any;
  private transactionsLiteral: any;

  constructor(
    private translate: TranslateService,
    private readonly dashboardService: DashboardService,
    private currencyPipe: CurrencyPipe
   ) {}

  ngOnInit(): void {
    
  }

  ngAfterViewInit(): void {
    console.log(this.dataToCompare, 'view init')

    this.data = {
      labels: [], //this.chartDataset.labels,
      datasets: [
        {
          data: [], //this.chartDataset.values,
          backgroundColor: Object.values(CHART_COLORS),
        }
      ],
    };
    this.initialized = true;
    this.translate.get([
      'TABLES_CONFIG.TRANSACTIONS_DAILY.YAXIS_TITLE',
      'FROM',
      'TO'
    ])
    .subscribe(translations => {
      this.localeFormat = this.dashboardService.setFormat(navigator.language);
      this.transactionsLiteral = translations['TABLES_CONFIG.TRANSACTIONS_DAILY.YAXIS_TITLE'];
      this.translations = {FROM: translations.FROM, TO: translations.TO};
      this.createChart();
      this.changeChart();
    });
  }

  ngOnChanges() {
    console.log(this.dataToCompare, 'view init')
    this.initialized && this.changeChart();
  }

  changeChart() {
    if (!(this.chart == undefined)) {
      this.chart.data.labels = []; // this.chartDataset.labels;
      this.chart.data.datasets = [];
      for (let i=0; i<this.dataToCompare?.dates.length; i++) {
        this.chart.data.labels.push(
          formatDate(
            this.dataToCompare?.dates[i],
            ShortDateFormats[this.localeFormat],
            this.translate.currentLang));
      }

      if (this.dataToCompare?.dates.length > 0) {
        var index = 0;
        if ((this.dataToCompare?.prevValues != undefined) && (this.dataToCompare?.prevValues!.size > 0)) {
          for (let entry of this.dataToCompare?.prevValues.entries()) {
            if (entry[0]==this.dashboardCurrency) {
              const amount: number[] = [];
              for (let i=0; i<this.dataToCompare?.prevDates.length; i++) {
                amount.push(entry[1][i]);
              }
              this.chart.data.datasets!.push({
                label : [
                  (this._setPeriodLabel(this.dataToCompare?.prevDates) as any)?.firstLine,
                  (this._setPeriodLabel(this.dataToCompare?.prevDates) as any)?.secondLine
                ],
                data : amount,
                stack: 'Stack 1',
                backgroundColor: NAMED_COLORS[1]
              });
            }
            index = index + 1;
            this.chart.options.plugins.tooltip = {
              callbacks: {
                title: (tooltipItem: any)=> {
                  const thisYear = new Date().getFullYear();
                  const date = `${tooltipItem[0].label}${thisYear}`;
                  return formatDate(date, DateFormats[(this.localeFormat)] as string, this.translate.currentLang);
                },
                label: (tooltipItem: any) => {
                  const data = tooltipItem.dataset.data[tooltipItem.dataIndex];
                  return `${this.transactionsLiteral} ${
                    this.importsChart
                      ? this.currencyPipe.transform(data, this.dashboardCurrency, 'symbol', '1.2-2', this.translate.currentLang)
                      : data
                  } `;
                }
              }
            }
          }
        }
        index = 0;

        for (let entry of this.dataToCompare?.values.entries()) {
          if (entry[0]==this.dashboardCurrency) {
            const amount: number[] = [];
            for (let i=0; i<this.dataToCompare?.dates.length; i++) {
              amount.push(entry[1][i]);
            }
            this.chart.data.datasets!.push({
              label : [
                (this._setPeriodLabel(this.dataToCompare?.dates) as any)?.firstLine,
                (this._setPeriodLabel(this.dataToCompare?.dates) as any)?.secondLine
              ],
              data : amount,
              stack: 'Stack 0',
              backgroundColor: NAMED_COLORS[0]
            });
            this.chart.data
          }
          index = index + 1;
        }
      }
    }
    this.chart?.update();
  }

  private _setPeriodLabel ( datesArray: string[] ): {firstLine: string, secondLine: string} | string {
    
    return datesArray && {
      firstLine: this.translations.FROM + formatDate(
        datesArray[0], DateFormats[(this.localeFormat)] as string, this.translate.currentLang),
      secondLine: this.translations.TO + formatDate(
        datesArray[datesArray.length - 1],  DateFormats[(this.localeFormat)] as string, this.translate.currentLang)
    } 
  }

  createChart(){
    const str = this.chartCmpTx?.nativeElement?.getContext('2d');
    str && (this.chart = new Chart(str, {
      type: 'bar',
      data: this.data,
      options: {
        plugins: {
          title: {
            display: true,
          },
          legend: {
            display: true,
            position: 'bottom',
            labels: {
              boxWidth: 12,
              boxHeight: 12,
              font: {
                lineHeight: 1
              }
            }
          }
        },
        layout: {
          padding: {
            bottom: 10
          }
        },
        responsive: true,
        interaction: {
          intersect: false,
        },
        scales: {
          x: {
            stacked: true,
          },
          y: {
            stacked: true,
            min: 0,
            ticks: {
              stepSize: 1,
              callback: (data: any) => {
                return this.importsChart
                  ? this.currencyPipe.transform(data, this.dashboardCurrency, 'symbol', '1.2-2', this.translate.currentLang)
                  : data;
              }
            }
          }
        }
      }    
    }));
  }
}
