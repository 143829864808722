import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import {MatLegacyPaginator as MatPaginator, LegacyPageEvent as PageEvent} from '@angular/material/legacy-paginator';
import {MatSort, Sort} from '@angular/material/sort';
import {MatLegacyTableDataSource as MatTableDataSource} from '@angular/material/legacy-table';
import { Organization } from 'src/app/models/organization.model';
import { QueryFilterList, RequestFilter } from 'src/app/models/requestFilter.model';
import { CommonService } from 'src/app/services/common.service';
import { VIWER_ENTORNO, environment } from 'src/environments/environment';
import { OrganizationsService } from './organizations.service';
import { ConfirmDialogActions, ConfirmDialogData } from 'src/app/components/confirm-dialog/confirm-dialog.model';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { FormOrgComponent } from './form-new/form-org.component';
import { Subscription, debounceTime, distinctUntilChanged } from 'rxjs';
import { Columns, Columns_xiibero, ENTORNO, TypeJerarquia } from 'src/app/util/constants';
import { AuthService } from 'src/app/auth/auth.service';
import { UtilService } from 'src/app/services/util.service';
import { TranslateService } from '@ngx-translate/core';
import * as XLSX from "xlsx";
import { DataExport_Organization_Xiibero } from 'src/app/models/dataExport/dataExport_organization_Xiibero.model copy';
import { DataExport_Organization } from 'src/app/models/dataExport/dataExport_organization.model';
import { ModalManagerService } from '../../../services/modalManager.service';

@Component({
  selector: 'app-organizations',
  templateUrl: './organizations.component.html',
  styleUrls: ['./organizations.component.css']
})
export class OrganizationsComponent implements OnInit, AfterViewInit {

  displayedColumns =  Object.keys(Columns);  
  entorno = VIWER_ENTORNO

  dataSource = new MatTableDataSource<Organization>();
  
  pageLength=0;
  pageIndex=0;
  pageSize=environment.defaultPaginacion;
  pageSizeOptions=environment.paginacion;
  sortId=Columns.name+",desc";//idColumna + "," + direccion


  
  createdAtFilter = new FormGroup({
    start: new FormControl<Date | null>(null),
    end: new FormControl<Date | null>(null),
  });

  nameFilter = new FormControl();
  statusFilter = new FormControl();
  domainRootFilter = new FormControl();
  routingFilter = new FormControl();

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  private confirmDialogRef!: MatDialogRef<ConfirmDialogComponent>;
  private dialogRef!: MatDialogRef<FormOrgComponent>;

 
  jerarquiaSearchSbscription!: Subscription;


  mapQuery = new Map();
  requestFilter: RequestFilter = new RequestFilter(null,0,this.pageSize,this.sortId);

  constructor(private organizationsService : OrganizationsService,
    public dialog: MatDialog,
    public authServer: AuthService,
    public utilServer: UtilService,
    public commonService : CommonService,
    private translate: TranslateService,
    private modalManager: ModalManagerService) {  

  }

  async getDataSourceAndElements() {
    
    const dataSourceAndPageLength = this.organizationsService.lanzarLlamada(this.requestFilter);
    this.dataSource = (await dataSourceAndPageLength)?.dataSource;// as MatTableDataSource<Organization>;
    this.pageLength = (await dataSourceAndPageLength)?.pageLength;// as number;
  }

  cargarCache() {

    let value = this.utilServer.cargarCacheDomainOnlyOrg();

    if(value && value!="undefined"){
      value = value.replace(".","")
      this.requestFilter.filter = QueryFilterList.construirFilter(this.mapQuery, "domainRoot", value);        
    }else{
      this.requestFilter.filter = QueryFilterList.construirFilter(this.mapQuery, "domainRoot", undefined);            
    }

    this.getDataSourceAndElements();  
  }

  isXiibero(): boolean {
    return this.entorno===ENTORNO.XIIBERO;
  }

  ngOnInit(): void {

    if(this.entorno==ENTORNO.XIIBERO){
      this.displayedColumns =  Object.keys(Columns_xiibero);
    } else{
      this.displayedColumns =  Object.keys(Columns);
    }

 
    this.jerarquiaSearchSbscription = this.authServer.getJerarquiaSearchClicked().subscribe(() => this.cargarCache());


    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    
    this.nameFilter.valueChanges.pipe(debounceTime(700),distinctUntilChanged()).subscribe((value) => {
      const idColumn = Columns.name;
      console.log(idColumn+"="+value);     
      this.requestFilter.filter = QueryFilterList.construirFilter(this.mapQuery, idColumn, value);        
      this.getDataSourceAndElements();       
    });   
    this.domainRootFilter.valueChanges.pipe(debounceTime(700),distinctUntilChanged()).subscribe((value) => {
      const idColumn = Columns_xiibero.domainRoot;
      console.log(idColumn+"="+value);     
      this.requestFilter.filter = QueryFilterList.construirFilter(this.mapQuery, idColumn, value);        
      this.getDataSourceAndElements();       
    }); 
    this.statusFilter.valueChanges.pipe(debounceTime(700),distinctUntilChanged()).subscribe((value) => {
      const idColumn = Columns_xiibero.status;
      console.log(idColumn+"="+value);     
      this.requestFilter.filter = QueryFilterList.construirFilter(this.mapQuery, idColumn, value);        
      this.getDataSourceAndElements();       
    }); 
    this.routingFilter.valueChanges.pipe(debounceTime(700),distinctUntilChanged()).subscribe((value) => {
      const idColumn = Columns_xiibero.properties_routing;
      console.log(idColumn+"="+value);     
      this.requestFilter.filter = QueryFilterList.construirFilter(this.mapQuery, idColumn, value);        
      this.getDataSourceAndElements();       
    }); 
    
    if(this.isXiibero()){
      this.createdAtFilter.valueChanges.pipe(debounceTime(700),distinctUntilChanged()).subscribe(value => {
        const idColumn = Columns_xiibero.createdAt;    
        console.log(idColumn+"->start="+value.start+"   end="+value.end);        
        this.requestFilter.filter = QueryFilterList.construirRangoFechaFilter(this.mapQuery, idColumn, value.start,value.end);        
        this.getDataSourceAndElements(); 
      });
    }

    this.cargarCache()
   
  }

  

 
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  pageEvent(pag:PageEvent){
    console.log("pageEvent="+JSON.stringify(pag))
    this.pageSize = pag.pageSize;
    this.pageIndex = pag.pageIndex;
    this.requestFilter = RequestFilter.construirPage(this.requestFilter.filter , pag.pageIndex,pag.pageSize,this.sortId);
    this.getDataSourceAndElements();
  }
 
  announceSortChange(sortState: Sort) {
    console.log("ordenar="+JSON.stringify(sortState)) 
    let active = sortState.active.replace("_",".")
    let direccion="";
    if(sortState.direction){
      direccion=","+sortState.direction
    }
    this.sortId=active+direccion;
    this.requestFilter = RequestFilter.construirPage(this.requestFilter.filter , this.pageIndex,this.pageSize,this.sortId);
    this.getDataSourceAndElements();
  }

  refrescar() {
    this.getDataSourceAndElements();
  }

  onNew(){
    this.dialogRef = this.dialog.open(FormOrgComponent, {
      width: '70%', panelClass: 'custom-modalbox-big',
      data: {//vacio al ser nuevo
      }
    });
    this.modalManager.registerModal(this.dialogRef);

    this.dialogRef.afterClosed().subscribe((result) => {
      if (result === 1) {        
        console.log('CONFIRM recived from dialog window');
        this.getDataSourceAndElements();
      } else if (result === 0) {
        console.log('CANCEL recived from dialog window');
      }
    });
   }

   onEdit(row: any) {

    this.dialogRef = this.dialog.open(FormOrgComponent, {
      width: '70%', panelClass: 'custom-modalbox-big',
      data: {
        obj: row
      }
    });
    this.modalManager.registerModal(this.dialogRef);

    this.dialogRef.afterClosed().subscribe((result) => {
      if (result === 1) {        
        console.log('CONFIRM recived from dialog window');
        this.getDataSourceAndElements();
      } else if (result === 0) {
        console.log('CANCEL recived from dialog window');
      }
    });
  }



   onDelete(row: any) {

    console.log('Delete pressed: ', row);
 
      const dialogData = new ConfirmDialogData();
      this.translate
      .get([
        "Delete Organization",        
        "Are you sure you want to delete it?"
      ])
      .subscribe(translation => {
          dialogData.titleI18n = translation["Delete Organization"];
          dialogData.textI18n = translation["Are you sure you want to delete it?"];
          dialogData.textValue = row.name;
      
        this.confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
          width: '25%', panelClass: 'custom-modalbox',
          data: dialogData
        });
        this.modalManager.registerModal(this.dialogRef);

      });

    this.confirmDialogRef.afterClosed().subscribe(async (result: ConfirmDialogActions) => {
      if (result === ConfirmDialogActions.CONFIRM) {
        await this.organizationsService.deleteService(row.id);
        console.log('CONFIRM recived from dialog window');
        this.getDataSourceAndElements();
      } else if (result === ConfirmDialogActions.CANCEL) {
        console.log('CANCEL recived from dialog window');
      }
    });
  }









  exportIt() {
      let newDate = new Date();
      let nombre = "organization-"+newDate.toISOString().slice(0, 10)+"-"+newDate.getTime();
      const fileName = nombre+".xlsx";

      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.procesarDataExport(this.dataSource.data));
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      let Heading
      if(this.entorno==ENTORNO.XIIBERO){
        Heading = [[this.translate.instant("Name"),this.translate.instant("Domain"),this.translate.instant("Route type"), this.translate.instant("Status"),this.translate.instant("Created")]];
      }else{  
        Heading = [[this.translate.instant("Organization"), this.translate.instant("Created")]];
      }
      XLSX.utils.sheet_add_aoa(ws, Heading);
      XLSX.utils.book_append_sheet(wb, ws, "Organization");

      XLSX.writeFile(wb, fileName);
  }

  private procesarDataExport(data:Organization[]): any[]  {
    
    if(this.entorno==ENTORNO.XIIBERO){
      
      let result : DataExport_Organization_Xiibero[] = [] ;
      data.forEach(row => {
        let tempRow:DataExport_Organization_Xiibero = new DataExport_Organization_Xiibero(row);
        result.push(tempRow);
      });
      return result;

    }else{        
        let result : DataExport_Organization[] = [] ;
        data.forEach(row => {
          let tempRow:DataExport_Organization = new DataExport_Organization(row);
          result.push(tempRow);
        });

        return result;
    }

  }

}
