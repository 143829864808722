

<input *ngIf="isAdmin" type="checkbox"  checked="true" (change)="checkValue($event)"  id="scales" ><label *ngIf="isAdmin" for="scales">{{"Ver todos los usuarios" | translate }} </label>

<div class="row">
    <div class="col-lg-12">
        <div class="card cardCustos">
            <app-dual-list [filter]="true" [sort]="true" [key]="keyDualList"  [display]="displayDualList" [format]="formartDualList" 
            [source]="listaTotalUser" [(destination)]="listaResultSelect" height="350px"></app-dual-list>
        </div>
    </div>
</div>

<div class="row botonera">
    <div class="col-lg-12">
            <button  class="btn btn-aceptar" (click)="onFormSubmit()" i18n>{{"Submit" | translate}}</button> 
            <button  class="btn btn-cancelar" (click)="formExit()" i18n>{{"Cancel" | translate}}</button>
    </div>
</div>