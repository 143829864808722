import { Component, OnInit, ElementRef, Input, ViewChild, AfterViewInit, OnChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DEFAULT_LOCALE, LOCALES } from 'src/app/util/chartsLocales';


export interface chartPayload {
  labels: string[];
  values: number[];
}

import {
  ApexAxisChartSeries,
  ApexTitleSubtitle,
  ApexDataLabels,
  ApexChart,
  ChartComponent,
  ApexXAxis,
  ApexYAxis
} from "ng-apexcharts";

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  title: ApexTitleSubtitle;
  colors: any;
  xaxis: ApexXAxis;
  yaxis: ApexYAxis;
};

@Component({
  selector: 'app-chart-tx-heatmap',
  templateUrl: './chart-tx-heatmap.component.html',
  styleUrls: ['./chart-tx-heatmap.component.css']
})
export class ChartTxHeatmapComponent implements OnInit, OnChanges, AfterViewInit  {
  @Input() chartTitle: string = "";
  @Input() monday: number[] = [];
  @Input() tuesday: number[] = [];
  @Input() wednesday: number[] = [];
  @Input() thursday: number[] = [];
  @Input() friday: number[] = [];
  @Input() saturday: number[] = [];
  @Input() sunday: number[] = [];

  private initialized: boolean = false;

  public chartOptions?: Partial<ChartOptions>;

  tablesTranlations: {xTitle: string, yTitle : string} = {
    xTitle: '',
    yTitle: ''
  };


  constructor(private translate: TranslateService ) { 
    this.translate.get('TABLES_CONFIG.TRANSACTIONS_HOURLY').subscribe(tablesTranslation => {
      this.tablesTranlations = {
        xTitle: tablesTranslation.XAXIS_TITLE,
        yTitle: tablesTranslation.YAXIS_TITLE
      }
    });
  }

  ngAfterViewInit(): void {
  }

  @ViewChild('chartObj', { static: true }) chart!: ChartComponent;

  ngOnInit(): void {
    
      this.initialized = true;
  }

  ngOnChanges() {
    this.initialized && this.init();
    
  }

  public generateDataSerie(count:number, yrange:any) {
    var i = 0;
    var series = [];
    while (i < count) {
      var x = (i *2).toString() + ":00";
      var y = yrange[i*2] + yrange[(i*2)+1];
//        Math.floor(Math.random() * (yrange.max - yrange.min + 1)) + yrange.min;

      series.push({
        x: x,
        y: y
      });
      i++;
    }
    return series;
  }

  private init() {
    this.translate
      .get([
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
        'Sunday',
      ])      
      .subscribe(translation => {
        this.createChart(translation);
        this.setchartOptions();
      });

  }

  private setchartOptions() {
    if (this.initialized && (this.chart != undefined) && (this.chartOptions?.series != undefined) && (this.chartOptions?.series.length > 0)) {
      this.chartOptions.series[0].data = this.generateDataSerie(12, this.monday);
      this.chartOptions.series[1].data = this.generateDataSerie(12, this.tuesday);
      this.chartOptions.series[2].data = this.generateDataSerie(12, this.wednesday);
      this.chartOptions.series[3].data = this.generateDataSerie(12, this.thursday);
      this.chartOptions.series[4].data = this.generateDataSerie(12, this.friday);
      this.chartOptions.series[5].data = this.generateDataSerie(12, this.saturday);
      this.chartOptions.series[6].data = this.generateDataSerie(12, this.sunday);
      this.chart.updateSeries(this.chartOptions.series);
    }
  }

  private createChart(translation: any){
      this.chartOptions = {
        series: [
          {
            name: translation.Monday,
            data: this.generateDataSerie(12, this.monday)
          },
          {
            name: translation.Tuesday,
            data: this.generateDataSerie(12, this.tuesday)
          },
          {
            name: translation.Wednesday,
            data: this.generateDataSerie(12, this.wednesday)
          },
          {
            name: translation.Thursday,
            data: this.generateDataSerie(12, this.thursday)
          },
          {
            name: translation.Friday,
            data: this.generateDataSerie(12, this.friday)
          },
          {
            name: translation.Saturday,
            data: this.generateDataSerie(12, this.saturday)
          },
          {
            name: translation.Sunday,
            data: this.generateDataSerie(12, this.sunday)
          }
        ],
        chart: {
          defaultLocale : DEFAULT_LOCALE,
          height: 'auto',
          type: "heatmap",
          locales: LOCALES,
          zoom: {
            enabled: false
          }
        },
        dataLabels: {
          enabled: false
        },
        colors: ["#008FFB"],
        title: {
          text: this.chartTitle
        },
        xaxis: {
          title: {
            text: this.tablesTranlations.xTitle
          }
        },
        yaxis: {
          title: {
            text: this.tablesTranlations.yTitle
          }
        }
      };      
  }
}
