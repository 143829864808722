import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import {MatLegacyPaginator as MatPaginator, LegacyPageEvent as PageEvent} from '@angular/material/legacy-paginator';
import {MatSort, Sort} from '@angular/material/sort';
import {MatLegacyTableDataSource as MatTableDataSource} from '@angular/material/legacy-table';
import { Merchants } from 'src/app/models/merchants.model';
import { QueryFilterList, RequestFilter } from 'src/app/models/requestFilter.model';
import { CommonService } from 'src/app/services/common.service';
import { VIWER_ENTORNO, environment } from 'src/environments/environment';
import { ConfirmDialogActions, ConfirmDialogData } from 'src/app/components/confirm-dialog/confirm-dialog.model';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { Subscription, debounceTime, distinctUntilChanged } from 'rxjs';
import { ENTORNO } from 'src/app/util/constants';
import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { OrganizationsService } from '../organizations/organizations.service';
import { Organization } from 'src/app/models/organization.model';
import { FormSubsidiaryComponent } from './form-new/form-subsidiary.component';
import { UtilService } from 'src/app/services/util.service';
import { AuthService } from 'src/app/auth/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { MerchantService } from '../merchants/merchant.service';
import { ModalManagerService } from 'src/app/services/modalManager.service';

declare let require: any


enum Columns_Xiibero {
  name = 'name', 
  organization_xiibero = 'hierarchy.ORGANIZATION.name',
  info_address_country = 'info.address.country', 
  info_address_state = 'info.address.state',
  info_address_city = 'info.address.city',
  info_email = 'info.email', 
  view = 'view'
}


@Component({
  selector: 'app-subsidiary',
  templateUrl: './subsidiary.component.html',
  styleUrls: ['./subsidiary.component.css']
})
export class SubsidiaryComponent implements OnInit ,AfterViewInit {

  displayedColumns =  Object.keys(Columns_Xiibero);
  dataSource = new MatTableDataSource<Merchants>();

  entorno = VIWER_ENTORNO
  
  pageLength=0;
  pageIndex=0;
  pageSize=environment.defaultPaginacion;
  pageSizeOptions=environment.paginacion;
  sortId=Columns_Xiibero.name+",desc";//idColumna + "," + direccion


  merchantEmailFilter = new FormControl();
  nameFilter = new FormControl();
  organizationFilter = new FormControl();
  cityFilter = new FormControl();
  stateFilter = new FormControl();
  countryFilter = new FormControl();
  organizationXiiberoSelect!: Organization;
  organizationXiiberoList:Organization[] = [];


  mapQuery = new Map();
  rf: RequestFilter= new RequestFilter(null,0,this.pageSize,this.sortId);

  
  jerarquiaSearchSbscription!: Subscription;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  private confirmDialogRef!: MatDialogRef<ConfirmDialogComponent>;
  private dialogRef!: MatDialogRef<FormSubsidiaryComponent>;



  constructor(
    private merchantService : MerchantService,
    public dialog: MatDialog,
    public utilServer: UtilService,
    public authServer: AuthService,
    private orgService: OrganizationsService,
    public commonService : CommonService,
    private translate: TranslateService,
    private modalManager: ModalManagerService) {  }
  
  categoryCodeDesc(cod:any){
    let mcc = require('merchant-category-code');  
    let val = mcc(cod);
    if(val){
      return val.usda_description;
    }
    return this.translate.instant("NO_define");
  }

  async lanzarLlamada(){

    if(this.entorno==ENTORNO.XIIBERO){
      this.rf.filter = QueryFilterList.construirFilter(this.mapQuery, "type", "SUBSIDIARY"); 
    }
    let value = await this.merchantService.findRead(this.rf);
    console.log(JSON.stringify(value))
    if(value){
      console.log(JSON.stringify(value.content))
      this.dataSource = new MatTableDataSource<Merchants>(value.content);
      this.pageLength=value.totalElements;
    }
  }

  async ngOnInit(): Promise<void> {
    this.jerarquiaSearchSbscription = this.authServer.getJerarquiaSearchClicked().subscribe(() => this.cargarCache());
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    if(this.entorno==ENTORNO.XIIBERO){
      this.displayedColumns =  Object.keys(Columns_Xiibero);
    } 

    await this.cargar();

    this.merchantEmailFilter.valueChanges.pipe(debounceTime(700),distinctUntilChanged()).subscribe((value) => {
      const idColumn = Columns_Xiibero.info_email;
      console.log(idColumn+"="+value);     
      this.rf.filter = QueryFilterList.construirFilter(this.mapQuery, idColumn, value);        
      this.lanzarLlamada();     
    });
    this.nameFilter.valueChanges.pipe(debounceTime(700),distinctUntilChanged()).subscribe(value => {
      const idColumn = Columns_Xiibero.name;
      console.log(idColumn+"="+value);     
      this.rf.filter = QueryFilterList.construirFilter(this.mapQuery, idColumn, value);        
      this.lanzarLlamada();  
    });   
    this.countryFilter.valueChanges.pipe(debounceTime(700),distinctUntilChanged()).subscribe((value) => {
      const idColumn = Columns_Xiibero.info_address_country;
      console.log(idColumn+"="+value);     
      this.rf.filter = QueryFilterList.construirFilter(this.mapQuery, idColumn, value);        
      this.lanzarLlamada();     
    });
    this.cityFilter.valueChanges.pipe(debounceTime(700),distinctUntilChanged()).subscribe((value) => {
      const idColumn = Columns_Xiibero.info_address_city;
      console.log(idColumn+"="+value);     
      this.rf.filter = QueryFilterList.construirFilter(this.mapQuery, idColumn, value);        
      this.lanzarLlamada();     
    });
    this.stateFilter.valueChanges.pipe(debounceTime(700),distinctUntilChanged()).subscribe((value) => {
      const idColumn = Columns_Xiibero.info_address_state;
      console.log(idColumn+"="+value);     
      this.rf.filter = QueryFilterList.construirFilter(this.mapQuery, idColumn, value);        
      this.lanzarLlamada();     
    });

    this.cargarCache();
  }
  
  cargarCache() {
    let value = this.utilServer.cargarCacheDomain();
      
    if(value && value!="undefined"){
      this.rf.filter = QueryFilterList.construirFilter(this.mapQuery, "domain", value);        
    }else{
      this.rf.filter = QueryFilterList.construirFilter(this.mapQuery, "domain", undefined);            
    }
      
    this.lanzarLlamada();  
  }

  async cargar(){
    //organizaciones
    let rfOrg: RequestFilter= new RequestFilter(null,0, 100,"name,desc");       
    let srOrg = await this.orgService.find(rfOrg,true);     
    if(srOrg){
      this.organizationXiiberoList= srOrg.content;
      if(this.organizationXiiberoList.length==1){
        // Si solo hay 1 ponemos por defecto valor
        this.organizationXiiberoSelect = this.organizationXiiberoList[0];
      }
    }
  }

  organizationXiiberoFilter(ob:MatSelectChange) {
    let org:Organization
    org=ob.value; 

    let value:string | undefined
    
    const idColumn = "domain";
    if(org){
      value=org.domainRoot+"."
    }

    this.rf.filter = QueryFilterList.construirFilter(this.mapQuery, idColumn, value);        
    this.lanzarLlamada();  
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  pageEvent(pag:PageEvent){
    console.log("pageEvent="+JSON.stringify(pag)) 
    this.pageSize = pag.pageSize;
    this.pageIndex = pag.pageIndex;
    this.rf = RequestFilter.construirPage(this.rf.filter , pag.pageIndex,pag.pageSize,this.sortId);
    this.lanzarLlamada();
  }

  announceSortChange(sortState: Sort) {
    console.log("ordenar="+JSON.stringify(sortState)) 
    let active = sortState.active.replace("_",".")
    let direccion="";
    if(sortState.direction){
      direccion=","+sortState.direction
    }
    this.sortId=active+direccion;
    this.rf = RequestFilter.construirPage(this.rf.filter , this.pageIndex,this.pageSize,this.sortId);
    this.lanzarLlamada();
  }

  refrescar() {
    this.lanzarLlamada();
  }

  onNew(){
    this.dialogRef = this.dialog.open(FormSubsidiaryComponent, {
      width: '70%', panelClass: 'custom-modalbox-big',
      data: {//vacio al ser nuevo
      }
    });
    this.modalManager.registerModal(this.dialogRef);

    this.dialogRef.afterClosed().subscribe((result) => {
      if (result === 1) {        
        console.log('CONFIRM recived from dialog window');
        this.lanzarLlamada();
      } else if (result === 0) {
        console.log('CANCEL recived from dialog window');
      }
    });
  }

  onEdit(row: any) {

    this.dialogRef = this.dialog.open(FormSubsidiaryComponent, {
      width: '70%', panelClass: 'custom-modalbox-big',
      data: {
        obj: row
      }
    });
    this.modalManager.registerModal(this.dialogRef);

    this.dialogRef.afterClosed().subscribe((result) => {
      if (result === 1) {        
        console.log('CONFIRM recived from dialog window');
        this.lanzarLlamada();
      } else if (result === 0) {
        console.log('CANCEL recived from dialog window');
      }
    });
  }



  onDelete(row: any) {

    console.log('Delete pressed: ', row);

      const dialogData = new ConfirmDialogData();
      this.translate
      .get([
        "Delete Subsidiary",        
        "Are you sure you want to delete it?"
      ])
      .subscribe(translation => {
          dialogData.titleI18n = translation["Delete Subsidiary"];
          dialogData.textI18n = translation["Are you sure you want to delete it?"];
          dialogData.textValue = row.name;
      
        this.confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
          width: '25%', panelClass: 'custom-modalbox',
          data: dialogData
        });
        this.modalManager.registerModal(this.dialogRef);

      });

    this.confirmDialogRef.afterClosed().subscribe(async (result: ConfirmDialogActions) => {
      if (result === ConfirmDialogActions.CONFIRM) {
        await this.merchantService.deleteService(row.id);
        console.log('CONFIRM recived from dialog window');
        this.lanzarLlamada();
      } else if (result === ConfirmDialogActions.CANCEL) {
        console.log('CANCEL recived from dialog window');
      }
    });
  }

}
