import { Pipe, PipeTransform } from '@angular/core';
import { Organization } from 'src/app/models/organization.model';
import { OrganizationsService } from 'src/app/pages/views/organizations/organizations.service';

@Pipe({
  name: 'formatOrganizationName'
})
export class FormatOrganizationNamePipe implements PipeTransform {
  private organizations: Organization[] = [];

  constructor(private organizationService: OrganizationsService) {
    this.organizationService.getFindResult().subscribe({
      next: (organizations) => {
        this.organizations = organizations.content;
      },
      error: (err) => {
        console.error('Failed to load organizations in Pipe:', err);
      },
      complete: () => console.log('Organizations loading completed.')
    });
  }

  
  transform(domain: string,  isTooltip: boolean = false): string {
    if (!domain || domain.trim() === '') {
      return 'N/A';
    }
    if (domain === "ALL") return domain;
    const domainParts = domain.split('.');
    const domainRoot = domainParts[0]; 
    if(isTooltip) return domainRoot;
    const organization = this.organizations.find(org => org.domainRoot === domainRoot);
    return organization ? organization.name : 'N/A';
  }

}
