import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { PagesContactComponent } from './pages/pages-contact/pages-contact.component';
import { PagesError404Component } from './pages/pages-error404/pages-error404.component';
import { PagesFaqComponent } from './pages/pages-faq/pages-faq.component';
import { PagesLoginComponent } from './pages/pages-login/pages-login.component';
import { PagesRegisterComponent } from './pages/pages-register/pages-register.component';
import { UsersProfileComponent } from './pages/users-profile/users-profile.component';
import { TransactionComponent } from './pages/views/transaction/transaction.component';
import { TerminalComponent } from './pages/views/terminal/terminal.component';
import { MerchantsComponent } from './pages/views/merchants/merchants.component';
import { AlertsComponent as AlertasComponent} from './pages/views/alerts/alerts.component';
import { OrganizationsComponent } from './pages/views/organizations/organizations.component';
import { UsersComponent } from './pages/admin/users/users.component';
import { RolComponent } from './pages/admin/rol/rol.component';
import { GroupsComponent } from './pages/admin/groups/groups.component';
import { WelcomeComponent } from './pages/welcome/welcome.component';
import { TrxReferencedComponent } from './pages/trx-referenced/trx-referenced.component';
import { AuthGuard } from './auth/auth-guard.service';
import { TermsConditionsComponent } from './pages/terms-conditions/terms-conditions.component';
import { OrgSettingComponent } from './pages/org-setting/org-setting.component';
import { OrdersComponent } from './pages/views/orders/orders.component';
import { RegionComponent } from './pages/views/region/region.component';
import { DivisionComponent } from './pages/views/division/division.component';
import { SubsidiaryComponent } from './pages/views/subsidiary/subsidiary.component';

const routes: Routes = [
  { path: '', redirectTo: 'welcome', pathMatch: 'full' },
  { path: 'welcome', component: WelcomeComponent },
  { path: 'trxreferenced', component: TrxReferencedComponent },
  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
  { path: 'organizations', component: OrganizationsComponent, canActivate: [AuthGuard], data: {authorg:'merchantsApi,adminXiibero',authkey:'organization'} },
  { path: 'orgSetting', component: OrgSettingComponent, canActivate: [AuthGuard], data: {authorg:'merchantsApi,adminXiibero',authkey:'organization'} },
  { path: 'merchants', component: MerchantsComponent, canActivate: [AuthGuard], data: {authorg:'merchantsApi,adminXiibero',authkey:'merchant'} },
  { path: 'terminal', component: TerminalComponent, canActivate: [AuthGuard], data: {authorg:'merchantsApi,adminXiibero',authkey:'terminal'} },
  
  { path: 'region', component: RegionComponent, canActivate: [AuthGuard], data: {authorg:'merchantsApi,adminXiibero',authkey:'region'} },
  { path: 'division', component: DivisionComponent, canActivate: [AuthGuard], data: {authorg:'merchantsApi,adminXiibero',authkey:'merchant'} },
  { path: 'subsidiary', component: SubsidiaryComponent, canActivate: [AuthGuard], data: {authorg:'merchantsApi,adminXiibero',authkey:'merchant'} },

  { path: 'transaction', component: TransactionComponent, canActivate: [AuthGuard], data: {authorg:'merchantsApi,adminXiibero',authkey:'transaction'} },
  { path: 'alerts', component: AlertasComponent, canActivate: [AuthGuard], data: {authorg:'merchantsApi,adminXiibero',authkey:'notification'} },
  { path: 'orders', component: OrdersComponent, canActivate: [AuthGuard], data: {authorg:'merchantsApi,adminXiibero',authkey:'terminal'} },
  { path: 'users', component: UsersComponent, canActivate: [AuthGuard], data: {authorg:'authserver',authkey:'user'} },
  { path: 'rols', component: RolComponent, canActivate: [AuthGuard], data: {authorg:'authserver',authkey:'role'} },
  { path: 'groups', component: GroupsComponent, canActivate: [AuthGuard], data: {authorg:'authserver',authkey:'group'} },
  { path: 'signup', component: PagesRegisterComponent},

  { path: 'terms-conditions', component: TermsConditionsComponent },
  { path: 'pages-contact', component: PagesContactComponent },
  { path: 'pages-error404', component: PagesError404Component },
  { path: 'pages-faq', component: PagesFaqComponent },
  { path: 'pages-login', component: PagesLoginComponent },
  { path: 'pages-register', component: PagesRegisterComponent },
  { path: 'user-profile', component: UsersProfileComponent },
  { path: '**', redirectTo: 'welcome' }, // redirecciona todo a welcome si no se puede cargar, para que haga login.
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
