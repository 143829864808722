<div class="chart-container">
   <!-- <div *ngIf="graphCurrencies.length > 1">
      <a class="icon" href="#" data-bs-toggle="dropdown"><i class="bi bi-currency-exchange"></i></a>
      <ul class="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
         <li class="dropdown-header text-start">
            <h3 i18n>{{ "Currency" | translate }}</h3>
         </li>
         <li *ngFor="let item of graphCurrencies" (click)="selectChangeCurrency($event, item   )"
            class="dropdown-item" href="#">
            <img height="40px" width="40px" [src]="'assets/flags/' + item + '.svg' | lowercase" onerror="this.src='assets/flags/default.jpg'" alt="" >
            {{ item }}
         </li>
      </ul>
   </div> -->
   <canvas  #chartCmpTx></canvas>
</div>
