import { Component, OnInit, Input, ViewChild, OnChanges } from '@angular/core';
import { ChartOptions, ChartPayload } from 'src/app/shared/interfaces/chart.model';

import {
  ApexNonAxisChartSeries,
  ChartComponent
} from "ng-apexcharts";
import { CHART_COLORS } from 'src/app/util/chartsutils';
import { TranslateService } from '@ngx-translate/core';
import { DEFAULT_LOCALE, LOCALES } from 'src/app/util/chartsLocales';


@Component({
  selector: 'app-chart-tx-donut',
  templateUrl: './chart-tx-donut.component.html',
  styleUrls: ['./chart-tx-donut.component.css']
})
export class ChartTxDonutComponent implements OnInit, OnChanges {
  @Input() chartTitle !: string;
  @Input() chartDataset!: ChartPayload;

  public chartOptions!: Partial<ChartOptions>;
  private originalSeries: ApexNonAxisChartSeries = [];
  private excludedSeries: string[] = [];  
  @ViewChild('chartTYP', { static: true }) chart!: ChartComponent;
  private partitionsLiterals: any;

  constructor(private translate: TranslateService ) { }

  ngOnInit(): void {
    this.init();
  }

  private init() {
    this.translate.get('TABLES_CONFIG.TRANSACTIONS_PARTITIONS').subscribe(literals => {
      this.partitionsLiterals = literals;
      this.createChart();
    });
  }

  createChart(){
    this.chartOptions = {
      series: [],
      chart: {
        width: "100%",
        height: "auto",
        type: "donut",
        locales: LOCALES,
        defaultLocale : DEFAULT_LOCALE,
        events: {
          legendClick: (_, seriesIndex) => {
                this.toggleSeriesVisibility(seriesIndex!);
          }
        },
        zoom: {
          enabled: false
        }
      },
      plotOptions: {
        pie: {
          donut: {
            size: '55%'
          }
        }
      },
      colors: Object.values(CHART_COLORS),
      labels: [],
      fill: {
        type: 'gradient',
      },      
      legend: {
        position: 'bottom',
        fontSize: 'small',
        formatter: (seriesName) => {
          return this.excludedSeries.includes(seriesName) ?
            `<span style="opacity: 0.45">${seriesName}</span>` : seriesName;
        }
    },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
              locales: LOCALES,
            },
            legend: {
              position: "bottom"
            }
          }
        }
      ]
    };
  }

  ngOnChanges() {
    if (!(this.chart == undefined) && !(this.chartOptions == undefined) && !(this.chartDataset == undefined)) {
      this.chartOptions.series = this.chartDataset.values;
      this.chartOptions.labels =  this.translateLabelLiterals(this.chartDataset.labels);
      if(this.chartOptions.labels < 1) {
        this.chartOptions.labels = ['no data returned'];
        this.chartOptions.series = [];
      }

      this.chart?.updateOptions(this.chartOptions);
      this.originalSeries = [...this.chartDataset.values];
    }
  }

 toggleSeriesVisibility(index: number): void {
    const isCurrentlyVisible = this.chartOptions.series![index] !== 0;
    this.chartOptions.series![index] = isCurrentlyVisible ? 0 : this.originalSeries[index];
      const seriesName = this.chartOptions.labels![index];
    if (isCurrentlyVisible) {
      if (!this.excludedSeries.includes(seriesName)) {
        this.excludedSeries.push(seriesName);
      }
    } else {
      this.excludedSeries = this.excludedSeries.filter(name => name !== seriesName);
    }
    this.chart.updateOptions({
      series: this.chartOptions.series
    }, false, true);

  }

  translateLabelLiterals(labelArray: string[]) {
    const tranlatedLabelsArray: string[] = [];
    labelArray.forEach((literal: string) => {
      tranlatedLabelsArray.push(this.partitionsLiterals[literal] ?? literal);
    });
    return tranlatedLabelsArray;
  };
}