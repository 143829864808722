import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { ChartPayload } from 'src/app/shared/interfaces/chart.model';


interface currencyInfo {
  name: string,
  isoname: string,
}

@Component({
  selector: 'app-summary-section',
  templateUrl: './summary-section.component.html',
  styleUrls: ['./summary-section.component.css']
})

export class SummarySectionComponent implements  OnInit, OnChanges {

  @Input() public staticTerminals!: ChartPayload;
  @Input() public staticTerminalsByCurrency!: ChartPayload;
  @Input() public staticTerminalsSuccess!: ChartPayload;
  @Input() public staticTerminalsDeclined!: ChartPayload;
  @Input() public staticTerminalsOthers!: ChartPayload;
  @Input() public staticTerminalsCurrency!: string;

  public staticTerminalsAmounts: {terminalsOnline: number, operationsCarriedOut: number, operationsByCurrency: number,
    operationsSuccess: number,operationsDeclined: number, dashboardCurrency: string} = { //,operationsOthers: number
    terminalsOnline: 0,
    operationsCarriedOut: 0,
    operationsByCurrency: 0,
    operationsSuccess: 0,
    operationsDeclined: 0,
    dashboardCurrency: "",
    //operationsOthers: 0,
  };
  public cardLiterals: any = {};

  private _initialized: boolean = false;

  ngOnInit() {
    this._initialized = true;
  }

  ngOnChanges (): void {
    if (this._initialized) {
      this._setStaticTerminalsAmounts();
    }
  }

  private _setStaticTerminalsAmounts() {
    this.staticTerminalsAmounts = {
      terminalsOnline: this.staticTerminals.values.length,
      operationsCarriedOut: this.staticTerminals.values.reduce((accumulator, terminalAmount) => {
        return accumulator + terminalAmount;
      }, 0),
      operationsByCurrency: this.staticTerminalsByCurrency.values.reduce((accumulator, terminalAmount) => {
        return accumulator + terminalAmount;
      }, 0),
      operationsSuccess: this.staticTerminalsSuccess.values.reduce((accumulator, terminalAmount) => {
        return accumulator + terminalAmount;
      }, 0),
      operationsDeclined: this.staticTerminalsDeclined.values.reduce((accumulator, terminalAmount) => {
        return accumulator + terminalAmount;
      }, 0),
      dashboardCurrency: this.staticTerminalsCurrency,
      /*
      operationsOthers:
      operationsOthers: this.staticTerminalsOthers.values.reduce((accumulator, terminalAmount) => {
        return accumulator + terminalAmount;
      }, 0)*/
    
    };
  }

  calculateStatusOthers(operationsByCurrency:number, operationsDeclined:number, operationsSuccess: number){
    return  operationsByCurrency-(operationsDeclined + operationsSuccess);
  }

}
