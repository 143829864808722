import { DOCUMENT } from '@angular/common';
import { Component, OnInit, Inject } from '@angular/core';
import { NotifierService } from 'angular-notifier';
@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.css']
})
export class WelcomeComponent implements OnInit {
  isDefault: boolean = true;
  isXiibero: boolean = false;
  isGetnet: boolean = false;
  isUnicaja: boolean = false;
  isIbercaja: boolean = false;
  isEuronet: boolean = false;
  isIngenico: boolean = false;

  
  constructor(@Inject(DOCUMENT) document: any) {
    let dominio = document.location.href;

    this.isDefault = false;
    this.isGetnet = false;
    this.isUnicaja = false;
    this.isIbercaja = false;
    this.isEuronet = false;
    this.isIngenico = false;


    if(dominio.includes("getnet")){
      this.isGetnet = true;
      this.isUnicaja = false;
      this.isIbercaja = false;
    }else  if(dominio.includes("unicaja")){
      this.isUnicaja = true;
    }else  if(dominio.includes("ibercaja")){
      this.isIbercaja = true;
    }else  if(dominio.includes("euronet")){
      this.isEuronet = true;   
    }else  if(dominio.includes("ingenico")){
      this.isIngenico = true;      
    }else {
      this.isDefault = true;
    }
  

    //test
    //this.isDefault = false;
    //this.isEuronet = true;
  
  }


  ngOnInit(): void {
  }

}
