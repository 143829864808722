export class CloseDialogData {
  titleI18n: string | undefined;
  textI18n: string | undefined;
  textValue: string | undefined;
  enableCancel: boolean = true;
  visibleButtom: boolean = true;
}

export enum CloseDialogActions {
  CANCEL = 0
}
