<main id="main" class="main">

    <div class="pagetitle">
        <h1  i18n>{{"Groups" | translate}}</h1>  
        <div>
          <button  *ngIf="commonService.checkAuthServerItemRights('group','W',domainSelect)" mat-mini-fab (click)="onNew()">
           <i class="bi bi-plus-lg"></i>
          </button>
        </div>
   </div>
  
  
    
    <div class="mat-elevation-z8 tablaPersonal">
      <table mat-table [dataSource]="dataSource" matSort (matSortChange)="announceSortChange($event)">  
  
  
        <!-- name Column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef><div mat-sort-header i18n>{{"Name" | translate }}</div>  
            <div>
              <mat-form-field>
                      <mat-icon matPrefix class="my-icon">filter_list </mat-icon>
                      <input matInput class="form-field" [formControl]="nameFilter" placeholder="">
              </mat-form-field>
            </div>
          </th>       
          <td mat-cell *matCellDef="let element"> {{element.name}} </td>
        </ng-container>
  

    <!-- descripcion Column -->
    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef><div mat-sort-header i18n>{{"Description" | translate }}</div>  
        <div>
          <mat-form-field>
                  <mat-icon matPrefix class="my-icon">filter_list </mat-icon>
                  <input matInput class="form-field" [formControl]="descFilter" placeholder="">
          </mat-form-field>
        </div>
      </th>       
      <td mat-cell *matCellDef="let element"> {{element.description}} </td>
    </ng-container>


    <!-- descripcion Column -->
    <ng-container matColumnDef="roles">
      <th mat-header-cell *matHeaderCellDef><div mat-sort-header i18n>{{"Role" | translate }}</div>  
        <div>
          <mat-form-field>
                  <mat-icon matPrefix class="my-icon">filter_list </mat-icon>
                  <input matInput class="form-field" [formControl]="rolFilter" placeholder="">
          </mat-form-field>
        </div>
      </th>       
      <td mat-cell *matCellDef="let element"> {{element.access && element.access[0].role}} </td>
    </ng-container>

    <!-- organization Column-->
    <ng-container matColumnDef="organization">
      <th mat-header-cell *matHeaderCellDef><div mat-sort-header i18n>{{"Organization" | translate }}</div>  
        <div>
          <mat-form-field>
            <mat-icon matPrefix class="my-icon"><i class="bi bi-filter"></i> </mat-icon>         
            <mat-select  [(ngModel)]="organizationSelect" (selectionChange)="domainFilter($event,'Org')">            
               
              <mat-form-field class="filterSelect">
                <mat-label> </mat-label>
                <input matInput placeholder="filter..." #filterplc>
              </mat-form-field>
  
              <mat-option [value]=""> </mat-option>
              <div *ngFor="let op of organizationList">
                <mat-option  *ngIf="op.name.includes(filterplc.value)" [value]="op" >
                  {{op.name}}
                </mat-option>
              </div>         
            </mat-select>
          </mat-form-field>
        </div>
      </th>       
      <td mat-cell *matCellDef="let element" [matTooltip]="isAdmin ? (element.domain | formatOrganizationName:true) : ''"> {{element.domain | formatOrganizationName}} </td>
    </ng-container>
<!-- merchant Column-->
<ng-container matColumnDef="merchant">
  <th mat-header-cell *matHeaderCellDef><div mat-sort-header i18n>{{"Merchant" | translate }}</div>  
    <div>
      <mat-form-field>
        <mat-icon matPrefix class="my-icon"><i class="bi bi-filter"></i> </mat-icon>         
        <mat-select  [(ngModel)]="merchantSelect" (selectionChange)="domainFilter($event,'Merchant')">            
           
          <mat-form-field class="filterSelect">
            <mat-label> </mat-label>
            <input matInput placeholder="filter..." #filterplc>
          </mat-form-field>

          <mat-option [value]=""> </mat-option>
          <div *ngFor="let op of merchantList">
            <mat-option  *ngIf="op.name.includes(filterplc.value)" [value]="op" >
              {{op.name}}
            </mat-option>
          </div>         
        </mat-select>
      </mat-form-field>
    </div>
  </th>       
  <td mat-cell *matCellDef="let element" [matTooltip]="isAdmin ? (element.domain) : ''"> {{element.domain | formatMerchantName}} </td>
</ng-container> 
    <!-- descripcion isVisible -->
    <ng-container matColumnDef="isVisible">
      <th mat-header-cell *matHeaderCellDef><div mat-sort-header i18n>{{"Visibility" | translate }}</div>  
        <div>
          <mat-form-field>
                  <mat-icon matPrefix class="my-icon">filter_list </mat-icon>
                  <input matInput class="form-field" [formControl]="isVisibleFilter" placeholder="">
          </mat-form-field>
        </div>
      </th>       
      <td mat-cell *matCellDef="let element"> {{element.isVisible}} </td>
    </ng-container>
      <!-- Create Column -->        
      <ng-container matColumnDef="createdAt">
        <th mat-header-cell *matHeaderCellDef > <div mat-sort-header i18n>{{"Create Date" | translate }}</div> 
          <div>
            <mat-form-field> 
                <mat-icon matPrefix class="my-icon">filter_list </mat-icon>
              <mat-label></mat-label>
              <mat-date-range-input [formGroup]="dateCreateFilter" [rangePicker]="picker">
                <input matStartDate formControlName="start" placeholder="{{'Start Date' | translate }}">
                <input matEndDate formControlName="end" placeholder="{{'End Date' | translate}}">
              </mat-date-range-input>
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-date-range-picker #picker></mat-date-range-picker>
            </mat-form-field>
          </div>
        </th>
        <td mat-cell *matCellDef="let element"> {{ calculateTimeZone(element.createdAt) }}</td>
      </ng-container>

  <ng-container matColumnDef="view">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let element" class="button">   
      <div>
        <button *ngIf="commonService.checkAuthServerItemRights('group','W', element.domain)" matTooltip="{{'Members' | translate}}"  (click)="onEdit(element,1)" mat-icon-button color="primary" >
          <i class="bi bi-person"></i>
        </button>

        <button *ngIf="commonService.checkAuthServerItemRights('group','W', element.domain)" matTooltip="{{'edit' | translate}}"  (click)="onEdit(element,0)" mat-icon-button color="primary" >
          <i class="bi bi-pencil"></i>
        </button>

        <button *ngIf="commonService.checkAuthServerItemRights('group','D', element.domain)" matTooltip="{{'delete' | translate}}" (click)="onDelete(element)" mat-icon-button color="primary">
          <i class="bi bi-trash"></i>
        </button>
</div>

    </td>
  </ng-container>



  
  
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    
          <mat-paginator [length]="pageLength"
              [pageSize]="pageSize"
              [pageSizeOptions]="pageSizeOptions"
              (page)="pageEvent($event)"
              showFirstLastButtons 
              aria-label="Select page of periodic elements">
          </mat-paginator>
    </div>
    </main>