import { AppConfig } from "src/environments/environment";
import { LogLevel } from "./cm-types";

export class Log {
    static trace(txt: string) {
        if (AppConfig.LogLevel >= LogLevel.TRACE) {
            Log.logMaxLength("TRACE", txt);
        }
    }

    static debug(txt: string) {
        if (AppConfig.LogLevel >= LogLevel.DEBUG) {
            Log.logMaxLength("DEBUG", txt);
        }
    }

    static info(txt: string) {
        if (AppConfig.LogLevel >= LogLevel.INFO) {
            Log.logMaxLength("INFO", txt);
        }
    }

    static warn(txt: string) {
        if (AppConfig.LogLevel >= LogLevel.WARN) {
            Log.logMaxLength("WARN", txt);
        }
    }

    static error(txt: string) {
        if (AppConfig.LogLevel >= LogLevel.ERROR) {
            Log.logMaxLength("ERROR", txt);
        }
    }

    static fatal(txt: string) {
        if (AppConfig.LogLevel >= LogLevel.FATAL) {
            Log.logMaxLength("FATAL", txt);
        }
    }

    static logMaxLength(type: string, txt: string) {
        if (txt && txt.length > AppConfig.LogMsgMaxLength) {
            // En javascript no hay que restar uno como en java
            txt = txt.substring(0, AppConfig.LogMsgMaxLength) + "... (" + txt.length + " bytes length)";
        }
        // Log del texto que hayamos considerado
        console.log(type + ": " + txt);
    }
}