<notifier-container></notifier-container>
<app-header *ngIf="(isAuthenticated$ | async)" ></app-header>
<app-topbar *ngIf="_router.url !== '/terms-conditions' && (isAuthenticated$ | async) === false " ></app-topbar>


<!--<app-bar-jerarquia *ngIf="(isAuthenticated$ | async)" (propagar)="procesaPropagar($event)"></app-bar-jerarquia>-->


<app-sidebar *ngIf="(isAuthenticated$ | async)"></app-sidebar> <!--" && !(_router.url === '/welcome' || _router.url === '/pages-register' || _router.url === '/pages-login' || _router.url === '/pages-error404')" ></app-sidebar>-->

<router-outlet></router-outlet>

<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" color="#fff"  size="medium" [type]="typeSelected">
    <p style="color: white">{{textShown}}</p>
</ngx-spinner>
<app-footer *ngIf="!(_router.url === '/pages-register' || _router.url === '/pages-login' || _router.url === '/pages-error404' || _router.url === '/welcome' || _router.url.includes('/trxreferenced'))" ></app-footer>


<form action="{{logoutBaseUrl}}" method="post" name="logout" #logoutForm>
    <input type="hidden" id="redirect" name="redirect" value="{{redirect}}"/>
    <a class="d-flex align-items-center" role="button" routerLink="/" (click)="logout()"> </a>
</form>


