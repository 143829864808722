import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';
import { countryListAllIsoData } from '../country';

@Pipe({
  name: 'countryPipe'
})
export class CountryPipe implements PipeTransform {

  /**
   * 
   * @param value formato Name country
   * @returns 
   */
  listaCountry:any=countryListAllIsoData

  transform(cod: string): unknown {
  let resultado

 this.listaCountry.forEach((element: { code: string | undefined;name: string | undefined  }) => {
    if (element.code == cod) {
      resultado = element.name
    };
  });

 
    

   return resultado;
  }

}
