import { NgModule,CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { CommonModule, registerLocaleData } from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MAT_LEGACY_FORM_FIELD_DEFAULT_OPTIONS as MAT_FORM_FIELD_DEFAULT_OPTIONS} from '@angular/material/legacy-form-field';
import {MatLegacyFormFieldModule as MatFormFieldModule} from '@angular/material/legacy-form-field';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatNativeDateModule} from '@angular/material/core';
import {HttpClientModule, HttpClient, HTTP_INTERCEPTORS} from '@angular/common/http';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './layouts/header/header.component';
import { FooterComponent } from './layouts/footer/footer.component';
import { SidebarComponent } from './layouts/sidebar/sidebar.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { UsersProfileComponent } from './pages/users-profile/users-profile.component';
import { PagesFaqComponent } from './pages/pages-faq/pages-faq.component';
import { PagesContactComponent } from './pages/pages-contact/pages-contact.component';
import { PagesRegisterComponent } from './pages/pages-register/pages-register.component';
import { PagesLoginComponent } from './pages/pages-login/pages-login.component';
import { PagesError404Component } from './pages/pages-error404/pages-error404.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialExampleModule } from './material.module';
import { MerchantsComponent } from './pages/views/merchants/merchants.component';
import { TerminalComponent } from './pages/views/terminal/terminal.component';
import { TransactionComponent } from './pages/views/transaction/transaction.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ToastrModule } from 'ngx-toastr';
import { OrganizationsComponent } from './pages/views/organizations/organizations.component';
import { AlertsComponent as AlertasComponent } from './pages/views/alerts/alerts.component';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { UsersComponent } from './pages/admin/users/users.component';
import { RolComponent } from './pages/admin/rol/rol.component';
import { GroupsComponent } from './pages/admin/groups/groups.component';
import { FormRolComponent } from './pages/admin/rol/form-rol/form-rol.component';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { FormGroupsComponent } from './pages/admin/groups/form-groups/form-groups.component';
import { ChartTxDonutComponent } from './components/chart-tx-donut/chart-tx-donut.component';
import { ChartModelComponent } from './components/chart-model/chart-model.component';
import { ChartTop5MerchantComponent } from './components/chart-top5-merchant/chart-top5-merchant.component';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { NgxNumericRangeFormFieldModule } from './components/numeric-range/ngx-numeric-range-form-field.module';
import { AngularDualListBoxModule } from './components/dual-listbox/angular-dual-listbox.module';
import { NgApexchartsModule } from 'ng-apexcharts';
import { ChartTxHeatmapComponent } from './components/chart-tx-heatmap/chart-tx-heatmap.component';
import { ChartTxDailyNumberComponent } from './components/chart-tx-number/chart-tx-number.component';
import { ChartTxDailyAmountComponent } from './components/chart-tx-amount/chart-tx-amount.component';
import { ChartTxDailyCompareComponent } from './components/chart-tx-compare/chart-tx-compare.component';
import { ChartLastMonthsSalesTxAmountComponent } from './components/chart-last-months-sales-tx-amount/chart-last-months-sales-tx-amount.component';
import { ChartLastMonthsTxComponent } from './components/chart-last-months-tx/chart-last-months-tx.component';
import { ChartLastMonthsAmountComponent } from './components/chart-last-months-amount/chart-last-months-amount.component';
import { AuthModule } from './auth/auth.module';
import { TopbarComponent } from './pages/welcome/topbar/topbar.component';
import { WelcomeComponent } from './pages/welcome/welcome.component';
import { TrxReferencedComponent } from './pages/trx-referenced/trx-referenced.component';
import { AuthRestUrl, MerchantRestUrl, XiiberoRestUrl, XiiberoPlatformRestUrl, US_DATE_FORMATS, EU_DATE_FORMATS } from './util/constants';
import { AUTH_URL, MERCHANT_URL,XIIBERO_URL, XIIBERO_PLATFORM_URL } from './auth/app.oauth-tokens';
import { UnauthorizedInterceptor } from './auth/unauthorized.interceptor';
import { Router } from '@angular/router';
import { AuthService } from './auth/auth.service';
import { ChartCompareValuesComponent } from './components/chart-compare-values/chart-compare-values.component';
import { ChartCompareTipesByDayComponent } from './components/chart-compare-tipes-by-day/chart-compare-tipes-by-day.component';
import { FormUsersComponent } from './pages/admin/users/form-users/form-users.component';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { TermsConditionsComponent } from './pages/terms-conditions/terms-conditions.component';
import { OrgSettingComponent } from './pages/org-setting/org-setting.component';
import { FormOrgComponent } from './pages/views/organizations/form-new/form-org.component';
import { FormMerchComponent } from './pages/views/merchants/form-new/form-merch.component';
import { OrdersComponent } from './pages/views/orders/orders.component';
import { FormNewOrdersComponent } from './pages/views/orders/form-new-orders/form-new-orders.component';
import { MatTableExporterModule } from 'mat-table-exporter';
import { FormRegionComponent } from './pages/views/region/form-new/form-region.component';
import { FormDivisionComponent } from './pages/views/division/form-new/form-division.component';
import { FormSubsidiaryComponent } from './pages/views/subsidiary/form-new/form-subsidiary.component';
import { RegionComponent } from './pages/views/region/region.component';
import { DivisionComponent } from './pages/views/division/division.component';
import { SubsidiaryComponent } from './pages/views/subsidiary/subsidiary.component';
import { BarJerarquiaComponent } from './layouts/bar-jerarquia/bar-jerarquia.component';
import { ReferencedDialogComponent } from './pages/views/transaction/referenced-dialog/referenced-dialog.component';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { CustomMatPaginatorIntl } from './util/customMatPaginatorIntl ';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { NotifierModule, NotifierOptions } from 'angular-notifier';
import { CountryPipe } from './util/pipes/country.pipe';
import { TabsGroupsComponent } from './pages/admin/groups/tabs-groups/tabs-groups.component';
import { MembersGroupsComponent } from './pages/admin/groups/members-groups/members-groups.component';
import { FormatMerchantNamePipe } from './util/pipes/format-merchant-name.pipe';
import { FormatOrganizationNamePipe } from './util/pipes/format-organization-name.pipe';
import localeEs from '@angular/common/locales/es';
import { SummarySectionComponent } from './pages/dashboard/summary-section/summary-section.component';
import { ChartCompareTipesByHourComponent } from './components/chart-compare-tipes-by-hour/chart-compare-tipes-by-hour.component';
import { AceptarDialogComponent } from './components/aceptar-dialog/aceptar-dialog.component';
import { CloseDialogComponent } from './components/close-dialog/close-dialog.component';

registerLocaleData(localeEs, 'es')

const customNotifierOptions: NotifierOptions = {
  position: {
      horizontal: {
          position: 'right',
          distance: 12
      },
      vertical: {
          position: 'top',
          distance: 12,
          gap: 10
      }
  },
  theme: 'material',
  behaviour: {
      autoHide: 5000,
      onClick: false,
      onMouseover: 'pauseAutoHide',
      showDismissButton: true,
      stacking: 4
  },
  animations: {
      enabled: true,
      show: {
          preset: 'slide',
          speed: 300,
          easing: 'ease'
      },
      hide: {
          preset: 'fade',
          speed: 300,
          easing: 'ease',
          offset: 50
      },
      shift: {
          speed: 300,
          easing: 'ease'
      },
      overlap: 150
  }
};

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    TopbarComponent,
    SidebarComponent,
    DashboardComponent,
    SummarySectionComponent,
    MerchantsComponent,
    RegionComponent,
    DivisionComponent,
    SubsidiaryComponent,
    TerminalComponent,
    TransactionComponent,
    OrganizationsComponent,
    OrgSettingComponent,
    AlertasComponent,
    UsersComponent,
    RolComponent,
    OrdersComponent,
    GroupsComponent,
    FormRolComponent,
    FormGroupsComponent,
    FormUsersComponent,
FormRegionComponent,
FormDivisionComponent,
FormSubsidiaryComponent,    
    FormOrgComponent,
    FormNewOrdersComponent,
    FormMerchComponent,
    WelcomeComponent,
    TrxReferencedComponent,
    BarJerarquiaComponent,
    TermsConditionsComponent,
    ChartTxDonutComponent,
    ChartTop5MerchantComponent,
    ChartModelComponent,
    ChartTxHeatmapComponent,
    ChartTxDailyNumberComponent,
    ChartTxDailyAmountComponent,
    ChartTxDailyCompareComponent,
    ChartLastMonthsSalesTxAmountComponent,
    ChartLastMonthsTxComponent,
    ChartLastMonthsAmountComponent,
    ChartCompareValuesComponent,
    ChartCompareTipesByDayComponent,
    ChartCompareTipesByHourComponent,
    ConfirmDialogComponent,
    AceptarDialogComponent,
    ReferencedDialogComponent,
    UsersProfileComponent,
    PagesFaqComponent,
    TabsGroupsComponent,
    MembersGroupsComponent,
    PagesContactComponent,
    PagesRegisterComponent,
    PagesLoginComponent,
    PagesError404Component,
    CountryPipe,
    FormatMerchantNamePipe,
    FormatOrganizationNamePipe,
    CloseDialogComponent
   ],
  imports: [
    BrowserModule,
    CommonModule,
    FormsModule,
    NotifierModule.withConfig(customNotifierOptions),
    ReactiveFormsModule,
    AppRoutingModule,
    MatProgressSpinnerModule,
    MatFormFieldModule,
    MatInputModule, 
    BrowserAnimationsModule,
    HttpClientModule,
    MatNativeDateModule,
    MatTableExporterModule,
    MaterialExampleModule,
    AngularDualListBoxModule   ,
    NgxNumericRangeFormFieldModule,
    NgxSpinnerModule,
    ToastrModule.forRoot(),
    FontAwesomeModule,
    NgApexchartsModule,
    AuthModule.forRoot(),
    NgIdleKeepaliveModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
  })
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  providers: [
    { provide: MAT_DIALOG_DATA, useValue: {} },
    { provide: MatDialogRef, useValue: {} },
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: {floatLabel: 'always'}},
    { provide: AUTH_URL, useValue: AuthRestUrl },
    {
      provide: HTTP_INTERCEPTORS,
      useFactory: function(router: Router, authService: AuthService) {
        return new UnauthorizedInterceptor(router,authService);
      },
      multi: true,
      deps: [Router, AuthService]
   },
    { provide: MERCHANT_URL, useValue: MerchantRestUrl },
    { provide: XIIBERO_URL, useValue: XiiberoRestUrl },
    { provide: XIIBERO_PLATFORM_URL, useValue: XiiberoPlatformRestUrl },
    { provide: MatPaginatorIntl, useClass: CustomMatPaginatorIntl},
    { provide: MAT_DATE_LOCALE, useValue : 'en-US' },
    // { provide: MAT_DATE_FORMATS, useFactory: ()=> {
    //   return navigator.language === 'en-US' ? US_DATE_FORMATS : EU_DATE_FORMATS;
    // }},
    // { provide: LOCALE_ID, useValue: 'es-Es' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(library: FaIconLibrary) {
    library.addIconPacks(fas, far, fab);
  }
 }
 export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
