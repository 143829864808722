import { ElementRef, Inject, Injectable, ViewChild } from '@angular/core';
import { timeout } from 'rxjs/operators'
import { HttpHeaders } from '@angular/common/http'
import { ServerResponse } from './ServerResponse';
import { Feedbacks, HttpTimeouts } from 'src/app/util/constants'
import { ApiService } from '../api.service'
import { Log } from 'src/app/util/log'
import { NgxSpinnerService } from 'ngx-spinner';
import { CommonService, toastPayload } from '../common.service';
import { IndividualConfig } from 'ngx-toastr';
import '@angular/localize/init';
import { AUTH_URL, MERCHANT_URL,XIIBERO_PLATFORM_URL,XIIBERO_URL } from 'src/app/auth/app.oauth-tokens';
import { HasFilesResponse } from 'src/app/models/Logfiles.model';
import { AuthService } from 'src/app/auth/auth.service';
import { Observable, shareReplay } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RestService {

    toast!: toastPayload;
    
    public loadingText: string;
    
    constructor(
        public api: ApiService, 
        private spinnerService: NgxSpinnerService,
        private cs : CommonService,
        private authService: AuthService,
        @Inject(AUTH_URL) private authUrl: string,
        @Inject(MERCHANT_URL) private merchantUrl: string,
        @Inject(XIIBERO_URL) private xiiberoUrl: string,
        @Inject(XIIBERO_PLATFORM_URL) private xiiberoPlatformUrl: string
    ) { 
        this.loadingText = '';
    }

    ////////////////////////////////////////////////////////////////////
    //  Hasta aqui los servicios rest contemplados
    //  Ahora funciones de ayuda:
    ////////////////////////////////////////////////////////////////////

    public getMerchantObservable(_body: object, _path:string, showLoading: boolean, showToast: boolean , httpHeaders?: HttpHeaders ): Observable<ServerResponse> {
        
        return new Observable<ServerResponse>(subsbriber => {
            this.commonRestCall({ service: this.merchantUrl +_path, body: _body, showLoading, showToast,httpHeaders })
            .then(resp => {
                subsbriber.next(resp)
                subsbriber.complete()
            }).catch(err => {
                subsbriber.error(err)
                subsbriber.complete()
            });
        }).pipe( shareReplay(1));
        
    }

    public commonRestCallAuthObservable(_body: object, _path:string, showLoading: boolean, showToast: boolean) {
        return new Observable<ServerResponse>(subsbriber => {
            this.commonRestCall({ service: this.authUrl + _path, body: _body, showLoading, showToast })
            .then(resp => {
                subsbriber.next(resp)
                subsbriber.complete()
            }).catch(err => {
                subsbriber.error(err)
                subsbriber.complete()
            });
        }).pipe( shareReplay(1));
    }

    async commonRestCallAuth(_body: object, _path:string, showLoading: boolean, showToast: boolean){        
        let value = await this.commonRestCall({ service: this.authUrl + _path, body: _body, showLoading, showToast })   
        .catch(err => {      
            return null
        })
        return value;   
    }

    async commonRestCallMerchant(_body: object, _path:string, showLoading: boolean, showToast: boolean , httpHeaders?: HttpHeaders){
        let value = await this.commonRestCall({ service: this.merchantUrl +_path, body: _body, showLoading, showToast,httpHeaders })   
        .catch(err => {      
            return null
        })        
        return value;   
    }

    async commonRestCallXiibero(_body: object, _path:string, showLoading: boolean, showToast: boolean ){
        let value = await this.commonRestCall({ service: this.xiiberoUrl +_path, body: _body, showLoading, showToast })   
        .catch(err => {
            return null
        })        
        return value;   
    }

    async commonRestCallXiiberoPlatform(_body: object, _path:string, showLoading: boolean, showToast: boolean ){
        let value = await this.commonRestCall({ service: this.xiiberoPlatformUrl +_path, body: _body, showLoading, showToast })   
        .catch(err => {
            return null
        })        
        return value;   
    }


    async commonRestCallMerchantForResult(_body: object, _path:string, showLoading: boolean, showToast: boolean , httpHeaders?: HttpHeaders){
        let value = await this.commonRestCallResult({ service: this.merchantUrl +_path, body: _body, showLoading, showToast,httpHeaders })   
        .catch(err => {      
        return null
        })
        return value;   
    }

    async commonRestCallMerchantBlob(_body: object, _path:string, showLoading: boolean, showToast: boolean , httpHeaders?: HttpHeaders){
        let value = await this.commonRestCallBlob({ service: this.merchantUrl +_path, body: _body, showLoading, showToast,httpHeaders })   
        .catch(err => {
            return null
        })
        return value;
    }
  

    /**
     * Codigo tipico comun a llamadas rest que muestran un mensaje de espera en pantalla y si hay error lo indican en pantalla.
     * Observable del que al suscribirlo, la funcion next devuelve los server response validos (status >= 0).
     * En la funcion de error se devolveran ServerResponse con status < 0, por lo que hay que implementarla aunque sea vacia para que no de una excepcion
     * @param serviceName nombre del servicio rest
     * @param body json a enviar
     */

    // Si quisieramos retornar un new T() generico:  commonRestCall<T>(type: { new(): T ;}, serviceName: string, body: any) {
    //async commonRestCall(serviceName: string, body: any): Promise<ServerResponse> {
    private async commonRestCall(opts: MerchantRestOptions ): Promise<ServerResponse> {

        if (opts.showLoading) {
            let defaultmessage =  $localize `COMMON.server-please-wait`;
            this.loadingText = opts.textDisplay ?? defaultmessage;
            this.spinnerService.show();
        }


        let httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json;charset=utf-8',
                    'Accept-Language': 'en'
                })
            }
        
        if(opts.httpHeaders){
            httpOptions.headers = opts.httpHeaders;            
        }

       


        let timeoutDefault=HttpTimeouts.long
        if(opts.timeOut) {
            timeoutDefault=opts.timeOut
        }

        // Call the service
        let responsePromise = new Promise<ServerResponse>((resolve, reject) => {

            let apiCall = this.api.post(opts.service, opts.body, httpOptions).pipe(timeout(timeoutDefault),
                //catchError(error => of(`Request timed out after: ${HttpTimeouts.short}`))
                //catchError((error: TimeoutError) => of(`Request timed out after: ${HttpTimeouts.short}`))
                //catchError((error) => this.handlePostError(error))
            )



            //##### Log.debug('commonRestCall request: ' + opts.service + " body:" + JSON.stringify(opts.body))

            apiCall.subscribe((resp) => {
               
                 //Log.debug('commonRestCall serverResp: ' + JSON.stringify(resp))

                if (opts.showLoading) {
                   this.spinnerService.hide();
                   this.loadingText = '';
                }

              
                
                let sr = new ServerResponse(resp)

                if (sr) {
                    //##### Log.trace('commonRestCall isSuccessful')
                    // todo Okay, reportamos el objeto
                    resolve(sr);
                } else {
                    //##### Log.trace('commonRestCall !isSuccessful')
                    let errorDescription =  "Error de sistema"
                    
                    // show problem to the user
                    if (opts.showToast) {
                        this.toast = {
                            message: errorDescription,
                            title: 'Merchant Portal',
                            type: 'Info',
                            ic: {
                                timeOut: Feedbacks.timeoutShort,
                                closeButton: true,
                                positionClass: Feedbacks.position
                            } as IndividualConfig,
                        };
                        this.cs.showWarning(this.toast);
                    }
                    // El servidor reporta algun error, asi lo indicamos a los subscriptores
                    reject(sr)
                }

            }, err => {
                if (opts.showLoading) {
                    this.spinnerService.hide();
                    this.loadingText = '';
                }

                let textFromError = this.authService.noAccessMessage;
                Log.error("commonRestCall error-> " + err.message)

                let posibleJSON = JSON.parse(err.message)
                if(posibleJSON && posibleJSON.error && posibleJSON.error.errorDescription){
                    textFromError = posibleJSON.error.errorDescription  
                }


                // Unable to get response
                if (opts.showToast) {
                    this.toast = {
                        message: textFromError,
                        title: 'Merchant Portal',
                        type: 'Info',
                        ic: {
                            timeOut: Feedbacks.timeoutShort,
                            closeButton: true,
                            positionClass: Feedbacks.position
                        } as IndividualConfig,
                    };
                    this.cs.showWarning(this.toast);
                }
                reject({})
            })
        }) // promise

        // devolvemos nuestra promise
        return responsePromise
    }

    private async commonRestCallBlob(opts: MerchantRestOptions ): Promise<Blob> {

        if (opts.showLoading) {
            let defaultmessage =  $localize `COMMON.server-please-wait`;
            this.loadingText = opts.textDisplay ?? defaultmessage;
            this.spinnerService.show();
        }


        let httpOptions = {
                responseType:"blob",
                headers: new HttpHeaders({
                    'Content-Type': 'application/json;charset=utf-8',
                    'Accept': 'application/pdf, application/zip, application/octet-stream'
                })
            }

        if(opts.httpHeaders){
            httpOptions.headers = opts.httpHeaders;            
        }


        let timeoutDefault=HttpTimeouts.long
        if(opts.timeOut) {
            timeoutDefault=opts.timeOut
        }

        // Call the service
        let responsePromise = new Promise<Blob>((resolve, reject) => {

            let apiCall = this.api.post(opts.service, opts.body, httpOptions).pipe(timeout(timeoutDefault))

            //##### Log.debug('commonRestCall request: ' + opts.service + " body:" + JSON.stringify(opts.body))

            apiCall.subscribe((resp) => {
                
                if (opts.showLoading) {
                    this.spinnerService.hide();
                    this.loadingText = '';
                }
                

                if(resp instanceof Blob){
                    resolve(resp)
                }

                 //Log.debug('commonRestCall serverResp: ' + JSON.stringify(resp))
                 reject(null)
                            

            }, err => {
                if (opts.showLoading) {
                    this.spinnerService.hide();
                    this.loadingText = '';
                }

                let textFromError = "Excepción"             
                Log.error("commonRestCall error-> " + err.message)

                // Unable to get response
                if (opts.showToast) {
                    this.toast = {
                        message: textFromError,
                        title: 'Merchant Portal',
                        type: 'Info',
                        ic: {
                            timeOut: Feedbacks.timeoutShort,
                            closeButton: true,
                            positionClass: Feedbacks.position
                        } as IndividualConfig,
                    };
                    this.cs.showWarning(this.toast);
                }
                reject(null)
            })
        }) // promise


        
        // devolvemos nuestra promise
        return responsePromise
    }


    private async commonRestCallResult(opts: MerchantRestOptions ): Promise<HasFilesResponse> {

        if (opts.showLoading) {
            let defaultmessage =  $localize `COMMON.server-please-wait`;
            this.loadingText = opts.textDisplay ?? defaultmessage;
            this.spinnerService.show();
        }


        let httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json;charset=utf-8'
                })
            }
        
        if(opts.httpHeaders){
            httpOptions.headers = opts.httpHeaders;            
        }

       


        let timeoutDefault=HttpTimeouts.long
        if(opts.timeOut) {
            timeoutDefault=opts.timeOut
        }

        // Call the service
        let responsePromise = new Promise<HasFilesResponse>((resolve, reject) => {

            let apiCall = this.api.post(opts.service, opts.body, httpOptions).pipe(timeout(timeoutDefault),
                //catchError(error => of(`Request timed out after: ${HttpTimeouts.short}`))
                //catchError((error: TimeoutError) => of(`Request timed out after: ${HttpTimeouts.short}`))
                //catchError((error) => this.handlePostError(error))
            )



            //##### Log.debug('commonRestCall request: ' + opts.service + " body:" + JSON.stringify(opts.body))

            apiCall.subscribe((resp) => {
               
                 //Log.debug('commonRestCall serverResp: ' + JSON.stringify(resp))

                if (opts.showLoading) {
                   this.spinnerService.hide();
                   this.loadingText = '';
                }

              
                
                let sr = new HasFilesResponse(resp)

                if (sr) {
                    //##### Log.trace('commonRestCall isSuccessful')
                    // todo Okay, reportamos el objeto
                    resolve(sr)
                } else {
                    //##### Log.trace('commonRestCall !isSuccessful')
                    let errorDescription =  "Error de sistema"
                    
                    // show problem to the user
                    if (opts.showToast) {
                        this.toast = {
                            message: errorDescription,
                            title: 'Merchant Portal',
                            type: 'Info',
                            ic: {
                                timeOut: Feedbacks.timeoutShort,
                                closeButton: true,
                                positionClass: Feedbacks.position
                            } as IndividualConfig,
                        };
                        this.cs.showWarning(this.toast);
                    }
                    // El servidor reporta algun error, asi lo indicamos a los subscriptores
                    reject(sr)
                }

            }, err => {
                if (opts.showLoading) {
                    this.spinnerService.hide();
                    this.loadingText = '';
                }

                let textFromError = "Excepción"             
                Log.error("commonRestCall error-> " + err.message)

                // Unable to get response
                if (opts.showToast) {
                    this.toast = {
                        message: textFromError,
                        title: 'Merchant Portal',
                        type: 'Info',
                        ic: {
                            timeOut: Feedbacks.timeoutShort,
                            closeButton: true,
                            positionClass: Feedbacks.position
                        } as IndividualConfig,
                    };
                    this.cs.showWarning(this.toast);
                }
                reject({})
            })
        }) // promise

        // devolvemos nuestra promise
        return responsePromise
    }

}

export interface MerchantRestOptions {
    service: string
    body: any
    // Show the default loading dialog
    showLoading?: boolean
    // Show de toast error when appropriated
    showToast?: boolean
	timeOut?: number
    textDisplay?: string
    httpHeaders?: HttpHeaders
}


