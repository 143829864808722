<main>
   <div class="container">

     <section *ngIf="error" class="section error-404 min-vh-90 d-flex flex-column align-items-center justify-content-center">
         <h1>{{"There was an error completing your transaction. Please log into the merchants portal and send it again."| translate}}</h1>                  
      </section>

      
      <section *ngIf="success" class="section error-404 min-vh-90 d-flex flex-column align-items-center justify-content-center">         
         <h1>{{"Your transaction was completed."|translate}}</h1>
         <div class="container">
            <div *ngIf="resp?.trx?.amount?.refundedAmount" class="row justify-content-center">
               <div class="col-4">
                  <b>{{"Transaction Refunded Amount"|translate}}:</b> 
               </div>
               <div class="col-4">
                  {{resp?.trx?.amount?.refundedAmount}} {{resp?.trx?.amount?.currency}}
               </div>
            </div>

            <div *ngIf="resp?.trx?.amount?.totalAmount" class="row justify-content-center">
               <div class="col-4">
                  <b>{{"Original Transaction Amount"|translate}}</b> 
               </div>
               <div class="col-4">
                  {{resp?.trx?.amount?.totalAmount}} {{resp?.trx?.amount?.currency}}
               </div>
            </div>            
            <div *ngIf="resp?.trx?.amount?.refundedAcumulatedAmount" class="row justify-content-center">
               <div class="col-4">
                  <b>{{"Transaction Refunded Accumulated Amount"|translate}}:</b> 
               </div>
               <div class="col-4">
                  {{resp?.trx?.amount?.refundedAcumulatedAmount}} {{resp?.trx?.amount?.currency}}
               </div>
            </div>
            <div *ngIf="resp?.trx?.amount?.confirmedAmount" class="row justify-content-center">
               <div class="col-4">
                  <b>{{"Confirmed Amount"|translate}}</b> 
               </div>
               <div class="col-4">
                  {{resp?.trx?.amount?.confirmedAmount}} {{resp?.trx?.amount?.currency}}
               </div>
            </div>

            <p></p>

            <div *ngIf="resp?.trx?.merchant" class="row justify-content-center">
               <div class="col-4">
                  <b>{{"Merchant"|translate}}:</b> 
               </div>
               <div class="col-4">
                  {{resp?.trx?.merchant}}
               </div>
            </div>
            
            <div *ngIf="resp?.trx?.authcode" class="row justify-content-center">
               <div class="col-4">
                  <b>{{"Authorization Code"|translate}}:</b> 
               </div>
               <div class="col-4">
                  {{resp?.trx?.authcode}}
               </div>
            </div>

            <div *ngIf="resp?.trx?.ref" class="row justify-content-center">
               <div class="col-4">
                  <b>{{"Transaction Reference"|translate}}:</b> 
               </div>
               <div class="col-4">
                  {{resp?.trx?.ref}}
               </div>
            </div>
            
            <div *ngIf="resp?.trx?.terminalId" class="row justify-content-center">
               <div class="col-4">
                  <b>{{"TID"|translate}}:</b> 
               </div>
               <div class="col-4">
                  {{resp?.trx?.terminalId}}
               </div>
            </div>            

         </div>

      </section> 
   </div>
</main>
