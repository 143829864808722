import { Terminals } from "../terminals.model";

export class DataExport_Terminals_Xiibero {

  organizationName: string | undefined;
  terminalId: string | undefined;
  serialnumber: string | undefined;
  merchantName: string | undefined;
  merchantId:string | undefined;
  manufacturer: string | undefined;
  model: string | undefined;
  status: string | undefined; 
  currency:string | undefined;
  country:string | undefined;



  constructor(values: Terminals = new Terminals) {
    this.organizationName= values.hierarchy?.ORGANIZATION?.name
    this.merchantName= values.hierarchy?.MERCHANT?.name
    this.terminalId= values.terminalId
    this.serialnumber= values.serialnumber
    this.manufacturer= values.manufacturer
    this.model= values.model
    this.status= values.status
    //Object.assign(this, values);
  }



}


