import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, NgForm, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { ConfirmDialogActions, ConfirmDialogData } from 'src/app/components/confirm-dialog/confirm-dialog.model';
import { Merchants, MerchantsAddress, MerchantsContact, MerchantsInfo, MerchantsProcessors, MerchantsProperties } from 'src/app/models/merchants.model';
import { Organization } from 'src/app/models/organization.model';
import { Processor } from 'src/app/models/processor.model';
import { QueryFilterList, RequestFilter } from 'src/app/models/requestFilter.model';
import { MerchantService } from 'src/app/pages/views/merchants/merchant.service';
import { OrganizationsService } from 'src/app/pages/views/organizations/organizations.service';
import { FormCreateActions, TypeJerarquia } from 'src/app/util/constants';
import { ProcessorService } from '../../processors/processor.service';
import { CommonService } from 'src/app/services/common.service';
import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { UtilService } from 'src/app/services/util.service';
import { mcc } from 'src/app/util/merchant-codes';
import { countryListAllIsoData } from 'src/app/util/country';
import { ModalManagerService } from 'src/app/services/modalManager.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-form-merch',
  templateUrl: './form-merch.component.html',
  styleUrls: ['./form-merch.component.css']
})
export class FormMerchComponent implements OnInit {
  registerForm!: FormGroup;
  desactivado: boolean = true;
  verRegion:boolean = false;  
  verDivision:boolean = false;  

  visibleEmergencyContact:boolean = false; 
  visibleTechnicalContact:boolean = false; 

  listaOrg: Organization[] = [];
  listaRegion:Merchants[] = [];
  listaDivision:Merchants[] = [];
  
  listaMcc:any[]=mcc.sort((a,b) => (a.edited_description > b.edited_description) ? 1 : ((b.edited_description > a.edited_description) ? -1 : 0));
  listaCountry:any=countryListAllIsoData


  selectedProc!: Processor
  selectedMCC!: any
  selectedCountry!: any
  selectedOrg: Organization = new Organization();
  selectedRegion: Merchants = new Merchants();
  selectedDivision: Merchants = new Merchants();

  objBBDD: Merchants = new Merchants();

  verProcessCBS = false;    
  listprocessorBBDD: Processor[] = []
  listProcesadorFinal: Processor[] = []


  private confirmDialogRef!: MatDialogRef<ConfirmDialogComponent>;


  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private services: MerchantService,
    private processorServices: ProcessorService,
    private orgService: OrganizationsService,
    private commonService: UtilService,
    private translate: TranslateService,
    private modalManager: ModalManagerService,
    public dialogRef: MatDialogRef<FormMerchComponent>,
    public dialog: MatDialog) {

  }



  async cargar() {
    //organizaciones
    let rfOrg: RequestFilter = new RequestFilter(null, 0, 100, "name,desc");
   // let srOrg = await this.orgService.find(rfOrg,true);
    let srOrg = await this.orgService.findToWrite(rfOrg,true);

    if (srOrg) {
      this.listaOrg = srOrg.content;      
    }

    let rfProces: RequestFilter = new RequestFilter(null, 0, 100, "name,desc");
    let srProces = await this.processorServices.find(rfProces);
    if (srProces) {
      this.listprocessorBBDD = srProces.content;      
    }



  }


  async ngOnInit(): Promise<void> {

    this.registerForm = this.formBuilder.group({
      parentOrganizationId: ["", Validators.compose([Validators.required,
        Validators.minLength(4),
        Validators.maxLength(13),
        Validators.pattern("^(?=[a-zA-Z0-9\\s._-]{4,13}$)(?!.*[_.-]{2})[^_.-].*[^_.-]$")])]      ,
      name: ["", Validators.compose([Validators.required,
        Validators.minLength(4),
        Validators.maxLength(50),
        Validators.pattern("^(?=[a-zA-Z0-9\\s._-]{4,50}$)(?!.*[_.-]{2})[^_.-].*[^_.-]$")])]      ,
      location: ["", Validators.compose([
        Validators.maxLength(255),
        Validators.pattern("^[0-9a-zA-ZÀ-ÿ\\s\\Q.@[]{}()+_-,=:!?^$|/\\E]*$")])],
      orgMarcado: [null, Validators.compose([Validators.required])],
      regionMarcado: [null],
      divisionMarcado: [null],
      country: [""],
      local: ["", Validators.compose([Validators.maxLength(8),  Validators.pattern("^[a-zA-Z0-9]*$") ])], 
      franquicia: ["", Validators.compose([Validators.maxLength(7),  Validators.pattern("^[a-zA-Z0-9]*$") ])],   
      state: [""],
      city: [""],
      address: [""],
      postalCode: [""],
      phone: [""],
      procesador: [""],
      merchantCategoryCode: ["", Validators.compose([Validators.required])],
      merchantId: [""],
      web: [""],
      businessContact_firstName: [null, Validators.compose([Validators.required])],
      businessContact_lastName: [null, Validators.compose([Validators.required])],
      businessContact_phone: [null, Validators.compose([Validators.required])],
      businessContact_email: [null, Validators.compose([
        Validators.email,
        Validators.maxLength(255),
        Validators.pattern("^[a-zA-Z0-9._%+\-]+@[a-zA-Z0-9.\-]+\.[a-zA-Z]{2,4}$")
      ])],
      technicalContact_same: [false],
      technicalContact_firstName: [""],
      technicalContact_lastName: [""],
      technicalContact_phone: [""],
      technicalContact_email: ["", Validators.compose([
        Validators.email,
        Validators.maxLength(255),
        Validators.pattern("^[a-zA-Z0-9._%+\-]+@[a-zA-Z0-9.\-]+\.[a-zA-Z]{2,4}$")
      ])] ,
      emergencyContact_same: [false],
      emergencyContact_firstName: [""],
      emergencyContact_lastName: [""],
      emergencyContact_phone: [""],
      emergencyContact_email: ["", Validators.compose([
        Validators.email,
        Validators.maxLength(255),
        Validators.pattern("^[a-zA-Z0-9._%+\-]+@[a-zA-Z0-9.\-]+\.[a-zA-Z]{2,4}$")
      ])]      

    });
  


    await this.cargar();

    let nombre, email, phone, merchantId, proces,local,franquicia;
    let country, state, city, address, postalCode,web,merchantCCBBDD;
    let businessContact_firstName,businessContact_lastName,businessContact_phone,businessContact_email
    let technicalContact_firstName,technicalContact_lastName,technicalContact_phone,technicalContact_email,technicalContact_sameContac
    let emergencyContact_firstName,emergencyContact_lastName,emergencyContact_phone,emergencyContact_email,emergencyContact_sameContac

    let orgBBDD = null;
    let regionBBDD=null;
    let divisionBBDD=null;
    if (this.data?.obj) {
      //si entra es EDICION
      this.desactivado = true;

      this.objBBDD = this.data?.obj;

      // alert(JSON.stringify(this.objBBDD))
      if (this.objBBDD) {
        let hierarchy = this.objBBDD.hierarchy

        let dominioOrg = ""
        let dominioRegion=""
        let dominioDivision=""

        if(hierarchy){
          if(hierarchy.ORGANIZATION){
            dominioOrg = hierarchy.ORGANIZATION.domain
          }

          if(hierarchy.REGION){
            dominioRegion = hierarchy.REGION.domain

            let sr = await this.commonService.pedirRegiones(dominioOrg,"W");     
            if(sr){
              this.listaRegion= sr.content;
            }
          }

          if(hierarchy.DIVISION){
            dominioDivision = hierarchy.DIVISION.domain

            let dominioBuscar = dominioOrg;
            if(dominioRegion){dominioBuscar = dominioRegion}
            let sr = await this.commonService.pedirDivision(dominioBuscar,"W");   
            if(sr){
              this.listaDivision= sr.content;
            }
          }
         
        }
           

        this.listaOrg.forEach(element => {
          if (element.domainRoot == dominioOrg) {
            orgBBDD = element
          };
        });

        this.listaRegion.forEach(element => {
          if(element.domain==dominioRegion){
              regionBBDD=element  
              this.verRegion=true              
            };
        });

        this.listaDivision.forEach(element => {
          if(element.domain==dominioDivision){
              divisionBBDD=element   
              this.verDivision=true             
            };
        });

        if (this.objBBDD.properties?.categoryCode) {

            this.listaMcc.forEach(element => {
              if(element.mcc==this.objBBDD.properties?.categoryCode){
                merchantCCBBDD=element            
                };
            });
          }

        merchantId=this.objBBDD.merchantId
        if (this.objBBDD.processors && this.objBBDD.processors.length > 0) {
          let processors = this.objBBDD.processors[0]
          this.listprocessorBBDD.forEach(element => {
            console.log("this.listProcesadorFinal"+JSON.stringify(this.listProcesadorFinal))
            if (element.shortName == processors.short) {
              proces = element  
              
              if(this.listProcesadorFinal){
                this.listProcesadorFinal.push(element);
              }
              this.selectedProc=element
              this.selectProcess(this.selectedProc);              
                        
            };
          });
        }

        if (this.objBBDD.info) {
          email = this.objBBDD.info.email;
          phone = this.objBBDD.info.phone;
          web = this.objBBDD.info.websiteUrl;
          local=this.objBBDD.info.local
          franquicia=this.objBBDD.info.franquicia

          if (this.objBBDD.info.address) {
            let tempCountry = this.objBBDD.info.address.country
            this.listaCountry.forEach((element: { code: string | undefined; }) => {
              if (element.code == tempCountry) {
                country = element
                this.selectedCountry=country
              };
            });

            state = this.objBBDD.info.address.state
            city = this.objBBDD.info.address.city
            address = this.objBBDD.info.address.addressLine1
            postalCode = this.objBBDD.info.address.postalOrZipCode
          }

          if (this.objBBDD.info.businessContact) {
            businessContact_firstName = this.objBBDD.info.businessContact.firstName
            businessContact_lastName = this.objBBDD.info.businessContact.lastName
            businessContact_phone = this.objBBDD.info.businessContact.phone
            businessContact_email = this.objBBDD.info.businessContact.email
            //this.objBBDD.info.businessContact.sameAsBusinessContact
          }
          if (this.objBBDD.info.technicalContact) {
            technicalContact_firstName = this.objBBDD.info.technicalContact.firstName
            technicalContact_lastName = this.objBBDD.info.technicalContact.lastName
            technicalContact_phone = this.objBBDD.info.technicalContact.phone
            technicalContact_email = this.objBBDD.info.technicalContact.email
            technicalContact_sameContac=this.objBBDD.info.technicalContact.sameAsBusinessContact
            if(technicalContact_sameContac){this.visibleTechnicalContact=true}
          }          
          if (this.objBBDD.info.emergencyContact) {
            emergencyContact_firstName = this.objBBDD.info.emergencyContact.firstName
            emergencyContact_lastName = this.objBBDD.info.emergencyContact.lastName
            emergencyContact_phone = this.objBBDD.info.emergencyContact.phone
            emergencyContact_email = this.objBBDD.info.emergencyContact.email
            emergencyContact_sameContac=this.objBBDD.info.emergencyContact.sameAsBusinessContact
            if(emergencyContact_sameContac){this.visibleEmergencyContact=true}
          }
        }
        nombre = this.objBBDD.name;
      }// if (this.objBBDD) 
    }//if (this.data && this.data.obj) 
else{
  this.desactivado = false;
}

    this.registerForm = this.formBuilder.group({
      name: [nombre, Validators.compose([Validators.required,
        Validators.minLength(4),
        Validators.maxLength(50),
        Validators.pattern("^(?=[a-zA-Z0-9\\s._-]{4,50}$)(?!.*[_.-]{2})[^_.-].*[^_.-]$")])]      ,
 
      country: [country],   
      
      local: [local, Validators.compose([Validators.maxLength(8),  Validators.pattern("^[a-zA-Z0-9]*$") ])], 
      franquicia: [franquicia, Validators.compose([Validators.maxLength(7),  Validators.pattern("^[a-zA-Z0-9]*$") ])],  
      state: [state],
      city: [city],
      address: [address],
      postalCode: [postalCode],
      email: [email, Validators.compose([
        Validators.email,
        Validators.maxLength(255),
        Validators.pattern("^[a-zA-Z0-9._%+\-]+@[a-zA-Z0-9.\-]+\.[a-zA-Z]{2,4}$")
    ])],
      web: [web],
      phone: [phone],
      procesador: [proces],
      merchantId: [merchantId],
      regionMarcado: [regionBBDD],
      merchantCategoryCode:[merchantCCBBDD, Validators.compose([Validators.required])],
      divisionMarcado: [divisionBBDD],
      orgMarcado: [orgBBDD, Validators.compose([Validators.required])],
      businessContact_firstName: [businessContact_firstName],
      businessContact_lastName: [businessContact_lastName],
      businessContact_phone: [businessContact_phone],
      businessContact_email: [businessContact_email, Validators.compose([
        Validators.email,
        Validators.maxLength(255),
        Validators.pattern("^[a-zA-Z0-9._%+\-]+@[a-zA-Z0-9.\-]+\.[a-zA-Z]{2,4}$")
      ])],
      technicalContact_same: [technicalContact_sameContac],
      technicalContact_firstName: [technicalContact_firstName],
      technicalContact_lastName: [technicalContact_lastName],
      technicalContact_phone: [technicalContact_phone],
      technicalContact_email: [technicalContact_email, Validators.compose([
        Validators.email,
        Validators.maxLength(255),
        Validators.pattern("^[a-zA-Z0-9._%+\-]+@[a-zA-Z0-9.\-]+\.[a-zA-Z]{2,4}$")
      ])] ,
      emergencyContact_same: [emergencyContact_sameContac],
      emergencyContact_firstName: [emergencyContact_firstName],
      emergencyContact_lastName: [emergencyContact_lastName],
      emergencyContact_phone: [emergencyContact_phone],
      emergencyContact_email: [emergencyContact_email, Validators.compose([
        Validators.email,
        Validators.maxLength(255),
        Validators.pattern("^[a-zA-Z0-9._%+\-]+@[a-zA-Z0-9.\-]+\.[a-zA-Z]{2,4}$")
      ])]
    });


  }



  async selectorganizacion(listaP: any[]| undefined ) {
    this.listProcesadorFinal=[]
    if(listaP){
      listaP.forEach(element => {
          this.listprocessorBBDD.forEach(bbdd => {
                if (element.short == bbdd.shortName) {
                  if(element.active){
                      this.listProcesadorFinal.push(bbdd);
                  }
                };
          });
      });
    }

    if(this.listProcesadorFinal && this.listProcesadorFinal.length>0){
      this.selectedProc=this.listProcesadorFinal[0]
      this.selectProcess(this.selectedProc);
    }
  }


  async organizationFilter(ob: MatSelectChange) {
    let org: Organization = ob.value;
    this.selectedOrg = org;

     // Restablece los valores de los campos relacionados al cambiar de organización
     this.registerForm.get('regionMarcado')?.setValue(null);
     this.registerForm.get('divisionMarcado')?.setValue(null);
     this.registerForm.get('merchMarcado')?.setValue(null);
     this.listaRegion = [];
     this.listaDivision = [];
   

    let mostrarRegion = false;
    let mostrarDivision = false;

    // Comprobar si la organización tiene un template y las jerarquías disponibles
    if (org?.template) {
        let tieneRegion = this.commonService.checkJerarquia(org.template, TypeJerarquia.REGION);
        let tieneDivision = this.commonService.checkJerarquia(org.template, TypeJerarquia.DIVISION);

        if (tieneRegion) {
            mostrarRegion = true;
            // Traer las REGIONES de la organización seleccionada
            let sr = await this.commonService.pedirRegiones(org.domainRoot, "W");
            if (sr) {
                this.listaRegion = sr.content;
            }
        } else if (tieneDivision) {
            mostrarDivision = true;
            // Traer las DIVISIONES de la organización seleccionada
            let sr = await this.commonService.pedirDivision(org.domainRoot, "W");
            if (sr) {
                this.listaDivision = sr.content;
            }
        }
    }

    // Actualizar las banderas de visualización de región y división
    this.verDivision = mostrarDivision;
    this.verRegion = mostrarRegion;

    // Pasar los procesadores de la organización seleccionada a la función selectorganizacion
    this.selectorganizacion(org.processors);
}

  async regionFilter(ob:MatSelectChange) {
    let reg:Merchants
    reg=ob.value; 
    let mostrar=false
    if(reg && this.selectedOrg.template){
      if(this.commonService.checkJerarquia(this.selectedOrg.template, TypeJerarquia.DIVISION)){
         
          mostrar=true          
          let sr = await this.commonService.pedirDivision(reg.domain,"W");   
          if(sr){
            this.listaDivision= sr.content;
          }          
      }
    }
    this.verDivision=mostrar

    
    this.selectorganizacion(reg.processors)
  }



  async selectProcess(select: Processor) {
    this.verProcessCBS = false;
    if (select && select.shortName == "CBS") {
        this.verProcessCBS = true;      
    }
  }



 



  onFormSubmit(): void {
    console.log(this.registerForm);

    this.registerForm.markAllAsTouched() 
    if (this.registerForm.invalid) {
      return ;
    }    
    const data = this.applyFormats(this.registerForm);
    

    let regionNew:Merchants
    let divisionNew:Merchants
    regionNew=data['regionMarcado']
    divisionNew=data['divisionMarcado']
    if(this.verRegion){     
      if(!regionNew){
        this.registerForm.controls['regionMarcado'].setErrors({ 'required': true }); 
        return ;
      }
    }
    if(this.verDivision){
      if(!divisionNew){
        this.registerForm.controls['divisionMarcado'].setErrors({ 'required': true }); 
        return ;
      }
    }



    
    let tempProcess = data['procesador']
    let tempMerchantId = data['merchantId']
    if (tempProcess && tempProcess.shortName == "CBS") {
      if (!tempMerchantId) {
        this.registerForm.controls['merchantId'].setErrors({ 'required': true });      
        return;
      }
    }


    let objNew: Merchants = new Merchants();
    if (this.objBBDD?.id) {
      objNew = this.objBBDD
    }

    objNew.name = data['name']

    let orgNew: Organization
    orgNew = data['orgMarcado']
    let domainRoot= orgNew.domainRoot

    let isPulsadoRegion=false
    let isPulsadoDivision=false
    if(regionNew){
      isPulsadoRegion=true
      domainRoot=regionNew.domain
    } 
    if(divisionNew){
      isPulsadoDivision=true
      domainRoot=divisionNew.domain
    }

    objNew.domain=domainRoot
    objNew.structure = this.commonService.obtenerStructure(isPulsadoRegion,isPulsadoDivision,true,false)

    let tempCountry:any
    tempCountry = data['country']
    let tempState = data['state']
    let tempCity = data['city']
    let tempAddress = data['address']
    let tempPostalCode = data['postalCode']
    let tempWeb = data['web']
    let tempPhone = data['phone']
    
    let tempLocal = data['local'];
    let tempFranc = data['franquicia'];

    let tempmerchantCategoryCode:any
     tempmerchantCategoryCode = data['merchantCategoryCode']

    
    let tempbusinessContact_firstName = data['businessContact_firstName']
    let tempbusinessContact_lastName = data['businessContact_lastName']
    let tempbusinessContact_phone = data['businessContact_phone']
    let tempbusinessContact_email = data['businessContact_email']    
    let temptechnicalContact_same = data['technicalContact_same']
    let temptechnicalContact_firstName = data['technicalContact_firstName']
    let temptechnicalContact_lastName = data['technicalContact_lastName']
    let temptechnicalContact_phone = data['technicalContact_phone']
    let temptechnicalContact_email = data['technicalContact_email']
    let tempemergencyContact_same = data['emergencyContact_same']
    let tempemergencyContact_firstName = data['emergencyContact_firstName']
    let tempemergencyContact_lastName = data['emergencyContact_lastName']
    let tempemergencyContact_phone = data['emergencyContact_phone']
    let tempemergencyContact_email = data['emergencyContact_email']

    

    let procesor: MerchantsProcessors = new MerchantsProcessors();
    let listProcesor: MerchantsProcessors[] = [];
    if (tempProcess) {
      procesor.merchantId = tempMerchantId
      procesor.short = tempProcess.shortName
      listProcesor[0] = procesor;
    }



    let properties: MerchantsProperties = new MerchantsProperties();
    if(tempmerchantCategoryCode){
      properties.categoryCode=tempmerchantCategoryCode.mcc
    }




    let merchantsInfo: MerchantsInfo = new MerchantsInfo();
    
    merchantsInfo.websiteUrl = tempWeb;
    merchantsInfo.phone = tempPhone;
    merchantsInfo.local = tempLocal;
    merchantsInfo.franquicia = tempFranc;
    

    if (tempCountry || tempState || tempCity || tempAddress) {
      let address: MerchantsAddress = new MerchantsAddress();
      address.country = tempCountry?.code;
      address.state = tempState;
      address.city = tempCity;
      address.addressLine1 = tempAddress;
      address.postalOrZipCode = tempPostalCode;
      merchantsInfo.address = address;
    }


    if (tempbusinessContact_firstName  && tempbusinessContact_lastName 
          && tempbusinessContact_phone && tempbusinessContact_email) {
            let businessContact: MerchantsContact = new MerchantsContact();
            businessContact.email=tempbusinessContact_email
            businessContact.firstName=tempbusinessContact_firstName
            businessContact.lastName=tempbusinessContact_lastName
            businessContact.phone=tempbusinessContact_phone
            merchantsInfo.businessContact = businessContact;

            if(temptechnicalContact_same){
              businessContact.sameAsBusinessContact=true
              merchantsInfo.technicalContact = businessContact;
            }else{
              let technicalContact: MerchantsContact = new MerchantsContact();
              technicalContact.sameAsBusinessContact=false
              technicalContact.email=temptechnicalContact_email
              technicalContact.firstName=temptechnicalContact_firstName
              technicalContact.lastName=temptechnicalContact_lastName
              technicalContact.phone=temptechnicalContact_phone
              merchantsInfo.technicalContact = technicalContact;
            }


            if(tempemergencyContact_same){
              businessContact.sameAsBusinessContact=true
              merchantsInfo.emergencyContact = businessContact;
            }else{
              let emergencyContact: MerchantsContact = new MerchantsContact();
              emergencyContact.sameAsBusinessContact=false
              emergencyContact.email=tempemergencyContact_email
              emergencyContact.firstName=tempemergencyContact_firstName
              emergencyContact.lastName=tempemergencyContact_lastName
              emergencyContact.phone=tempemergencyContact_phone
              merchantsInfo.emergencyContact = emergencyContact;
            }

    }else{
      return
    }





    objNew.properties=properties
    objNew.processors = listProcesor;
    objNew.info = merchantsInfo;
    objNew.type = "MERCHANT";


    const dialogData = new ConfirmDialogData();
    dialogData.titleI18n = this.translate.instant("MERCH.create_title");
    dialogData.textI18n = this.translate.instant("MERCH.create_desc");


    this.confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '25%', panelClass: 'custom-modalbox',
      data: dialogData
    });
    this.modalManager.registerModal(this.dialogRef);

    this.confirmDialogRef.afterClosed().subscribe(async (result: ConfirmDialogActions) => {
      if (result === ConfirmDialogActions.CONFIRM) {

        console.log('Form SAVE action');
        let val:any;
        if (objNew.id) {
          val = await this.services.updateEditService(objNew)
        } else {
          val = await this.services.saveEditService(objNew)
        }


        if(val?.name){
          this.dialogRef.close(FormCreateActions.SAVED);
        }else{
          this.dialogRef.close(FormCreateActions.ERROR);
        }

      } else if (result === ConfirmDialogActions.CANCEL) {
          //no hacemos nada
      }
    });

  }


  formExit(): void {
    console.log('Form exit action');
    this.dialogRef.close(FormCreateActions.EXIT);
  }

  ocultarTechnicalContact(event:any){
    console.log(event.checked);
    this.visibleTechnicalContact=event.checked
  }
  ocultarEmergencyContact(event:any){
    console.log(event.checked);
    this.visibleEmergencyContact=event.checked
  }


  private applyFormats(formGroup: FormGroup): any {
    const values = formGroup.value;
    const body: any = {};
    // tslint:disable-next-line: forin
    for (const key in values) {
      console.debug(`Form filter field --- ${key}:${values[key]}`);
      const value = values[key];
      if (value !== 'undefined' && value != null && value !== '') {
        body[key] = value;
      }
    }
    return body;
  }

}




