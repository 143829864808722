import { Component, Inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-tabs-groups',
  templateUrl: './tabs-groups.component.html',
  styleUrls: ['./tabs-groups.component.css']
})
export class TabsGroupsComponent {


  disableAddMember = false;
  disableEdit = false;
  group: any;
  marcado : any='0';

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit() {

    this.marcado=this.data?.tab;

    if(this.marcado===0){
      this.disableAddMember = true;
      this.disableEdit=false;
    }else{
      this.disableAddMember = false;
      this.disableEdit=true;
    }

    this.group = this.data?.obj;

   

  }

}
