<div>
   <apx-chart #chartCV  
     [series]="chartOptions.series!"
     [chart]="chartOptions.chart!"
     [dataLabels]="chartOptions.dataLabels!"
     [plotOptions]="chartOptions.plotOptions!"
     [yaxis]="chartOptions.yaxis!"
     [legend]="chartOptions.legend!"
     [fill]="chartOptions.fill!"
     [stroke]="chartOptions.stroke!"
     [tooltip]="chartOptions.tooltip!"
     [xaxis]="chartOptions.xaxis!"
   ></apx-chart>
 </div>
 
