import { AfterViewInit, ChangeDetectorRef, Component, Inject, OnChanges, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { CloseDialogActions, CloseDialogData } from './close-dialog.model';

@Component({
  selector: 'app-close-dialog',
  templateUrl: './close-dialog.component.html',
  styleUrls: ['./close-dialog.component.css']
})



export class CloseDialogComponent implements OnInit, AfterViewInit {

  enableCancel: boolean=true;
  visibleButtom: boolean=true;

  constructor(@Inject(MAT_DIALOG_DATA) public data: CloseDialogData,
    public dialogRef: MatDialogRef<CloseDialogComponent>,    
    private cdr: ChangeDetectorRef) {

      if(data.enableCancel){    this.enableCancel=data.enableCancel;}
      if(data.visibleButtom){   this.visibleButtom=data.visibleButtom;}    

    }


  ngOnInit() {
    this.cdr.detectChanges();
  }

  ngAfterViewInit(): void {
    this.cdr.detectChanges();
  }
  
  cancel(): void {
      this.dialogRef.close(CloseDialogActions.CANCEL);
  }


}
