import { Hierarchy } from "./hierarchy.model";

export class Order {
  id!: string;  
  orderId!: string;  
  domain!: string;
  type!: string;
  status!: string;
  createdAt!: string;
  updatedAt!: string;
  
  history: OrderHistory | undefined;
  terminals: OrderTerminals | undefined;
  
  hierarchy: Hierarchy | undefined;

  tmsProfileId!: string;
}


export class OrderHistory {
  date!: string;
  serialnumber!: string;
  ip!: string;
  mac!: string;
}

export class OrderTerminals {
  manufacturer!: string;
  model!: string;
  numberOfTerminals!: string;
  pendings!: string;  

}