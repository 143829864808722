import { REFRESH_DASHBOARD, SERVER_URL_AUTH, SERVER_URL_MERCHANT, SERVER_URL_XIIBERO, SERVER_URL_XIIBERO_PLATFORM } from 'src/environments/environment';


export const RegexpValidators = {
  number: /^\d*$/,
  // Passwords should be at least 8 characters long and should contain one number, one character and one special character.
  password: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
};

export const Feedbacks = {
  position: 'toast-bottom-full-width' as const,
  timeoutShort: 2500 as const,
  timeoutLong: 8000 as const,
  dashboardUpdate: REFRESH_DASHBOARD
};


export const HttpTimeouts = {
  default: 180000,
  short: 20000,
  long: 600000,
};

export const Separator = ","


export const MerchantRestUrl = SERVER_URL_MERCHANT;
export const XiiberoRestUrl = SERVER_URL_XIIBERO;
export const XiiberoPlatformRestUrl = SERVER_URL_XIIBERO_PLATFORM;
export const AuthRestUrl = SERVER_URL_AUTH;




export enum FormCreateActions {
  ERROR = 2,
  SAVED = 1,
  EXIT = 0
}


export const enum ENTORNO {
  XIIBERO=0,
  GETNET=1
}

export enum Columns {
  name = 'name', 
  // createdAt = 'createdAt'
}

export enum Columns_xiibero {
  name = 'name', 
  domainRoot = 'domainRoot',
  properties_routing = 'properties.routing',
  status = 'status',
  createdAt = 'createdAt',
  view = 'view'
}

export enum TypeJerarquia {   ORGANIZATION , REGION , DIVISION, MERCHANT, SUBSIDIARY }

export enum DateFormats {
  'en' = 'dd/MM/yyyy',
  'es' = 'dd/MM/yyyy',
  'en-GB' = 'dd/MM/yyyy',
  'es-ES' = 'dd/MM/yyyy',
  'en-US' = 'MM/dd/yyyy',
}

export enum ShortDateFormats {
  'en-GB' = 'dd MMM',
  'es-ES' = 'dd MMM',
  'en-US' = 'MMM dd',
  'en' = 'MMM dd',
  'es' = 'dd MMM'
}

export const EU_DATE_FORMATS = {
  parse: {
    dateInput: 'dd/MM/YYYY'
  },
  display: {
    dateInput: 'dd/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateAllyLabel: 'dd/MM/YYYY',
    monthYeatAllLabel: 'MMMM yyyy'
  }
}

export const US_DATE_FORMATS = {
  parse: {
    dateInput: 'MM/DD/YYYY'
  },
  display: {
    dateInput: 'MM/DD/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateAllyLabel: 'M-dd-yyyy',
    monthYeatAllLabel: 'MMMM yyyy'
  }
}
