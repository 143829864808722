
    <div class="pagetitle">
        <h1  i18n>{{"User" | translate }}</h1>      
     </div>
     <section class="section">
        <form [formGroup]="registerForm">
 
 
         <div class="row">
             <div class="col-lg-4">
                <div class="card cardCustos">
                    <mat-form-field ngDefaultControl>
                        <h5 i18n>{{"First Name" | translate }} <span class="obligatorio">*</span></h5>
                        <input formControlName="firstname" matInput type="text" [readonly]="desactivado" matInput required maxlength="40">
                        <mat-error *ngIf="registerForm.get('firstname')?.hasError('required')"  i18n>{{"Please enter a value!" | translate }}</mat-error>
                        <mat-error *ngIf="registerForm.get('firstname')?.hasError('pattern')"  i18n>{{"Invalid input format" | translate }}</mat-error>
                        <mat-error *ngIf="registerForm.get('firstname')?.hasError('maxLength')"  i18n>{{"Maximum length exceeded" | translate }}</mat-error>
                    </mat-form-field>
                 </div>
            </div>
            <div class="col-lg-4">
                <div class="card cardCustos">
                    <mat-form-field ngDefaultControl>
                        <h5 i18n>{{"Last Name" | translate }} <span class="obligatorio">*</span></h5>
                        <input formControlName="lastname" matInput type="text" [readonly]="desactivado" matInput required maxlength="150">
                        <mat-error *ngIf="registerForm.get('lastname')?.hasError('required')"  i18n>{{"Please enter a value!" | translate }}</mat-error>
                        <mat-error *ngIf="registerForm.get('lastname')?.hasError('pattern')"  i18n>{{"Invalid input format" | translate }}</mat-error>
                        <mat-error *ngIf="registerForm.get('lastname')?.hasError('maxLength')"  i18n>{{"Maximum length exceeded" | translate }}</mat-error>
                    </mat-form-field>
                </div>
             </div>
             <div class="col-lg-4">
                 <div class="card cardCustos">
                     <mat-form-field ngDefaultControl>
                         <h5 i18n>{{"Email" | translate }} <span class="obligatorio">*</span></h5>
                         <input formControlName="email" matInput type="text" [readonly]="desactivado" matInput required maxlength="150">
                         <mat-error *ngIf="registerForm.get('email')?.hasError('required')"  i18n>{{"Please enter a value!" | translate }}</mat-error>
                         <mat-error *ngIf="registerForm.get('email')?.hasError('pattern')"  i18n>{{"Invalid input format" | translate }}</mat-error>
                         <mat-error *ngIf="registerForm.get('email')?.hasError('maxLength')"  i18n>{{"Maximum length exceeded" | translate }}</mat-error>
                         <mat-error *ngIf="registerForm.get('email')?.hasError('email')"  i18n>{{"email format error" | translate }}</mat-error>
                     </mat-form-field>
                  </div>
             </div>
        </div>
        <div class="row">
            <div class="col-lg-6">
                <div class="card cardCustos">
                    <mat-form-field ngDefaultControl>
                        <h5 i18n>{{"Username" | translate }} <span class="obligatorio">*</span></h5>
                        <input formControlName="username" matInput type="text" [readonly]="desactivado" matInput required maxlength="40">
                        <mat-error *ngIf="registerForm.get('username')?.hasError('required')"  i18n>{{"Please enter a value!" | translate }}</mat-error>
                        <mat-error *ngIf="registerForm.get('username')?.hasError('minLength')"  i18n>{{"Minimum length not reached" | translate }}</mat-error>
                        <mat-error *ngIf="registerForm.get('username')?.hasError('maxLength')"  i18n>{{"Maximum length exceeded" | translate }}</mat-error>
                        <mat-error *ngIf="registerForm.get('username')?.hasError('pattern')"  i18n>{{"Invalid input format" | translate }}</mat-error>
                    </mat-form-field>
                </div>
            </div>            
            <div class="col-lg-6" *ngIf="isAdmin">
                <div class="card cardCustos">
                    <mat-form-field ngDefaultControl>
                        <h5 i18n>{{"Profile" | translate }} <span class="obligatorio">*</span></h5>                   
                            <mat-select formControlName="rolMarcado" (selectionChange)="rolFilter($event)">
                                <mat-option *ngFor="let op of listaTypeProfile" [value]="op">
                                    {{op}}
                                </mat-option>
                            </mat-select>
                    </mat-form-field>
                 </div>
             </div>
        </div>


         <div class="row"  *ngIf="verAdmin">
            <div class="col-lg-6">
                <div class="card cardCustos">
                    <mat-form-field ngDefaultControl>
                        <h5 i18n>{{"Organization" | translate }} <span class="obligatorio">*</span></h5>
                        <mat-select formControlName="orgMarcado" [(value)]="selectedOrg" (selectionChange)="organizationFilter($event)">
                            <mat-option *ngFor="let op of listaOrg" [value]="op">
                                {{op.name}}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="registerForm.get('orgMarcado')?.hasError('required')" i18n>{{"REQUIRED_FIELD" | translate }}</mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="col-lg-6" *ngIf="verRegion">
                <div class="card cardCustos" >
                    <mat-form-field ngDefaultControl>
                        <h5 i18n>{{"Region" | translate }} </h5>
                        <mat-select formControlName="regionMarcado" [(value)]="selectedRegion" (selectionChange)="regionFilter($event)">
                            <mat-option *ngFor="let op of listaRegion" [value]="op">
                                {{op.name}}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="registerForm.get('regionMarcado')?.hasError('required')" i18n>{{"REQUIRED_FIELD" | translate }}</mat-error>
                    </mat-form-field>
                </div>
            </div>
    
            <div class="col-lg-6" *ngIf="verDivision">
                <div class="card cardCustos" >
                    <mat-form-field ngDefaultControl>
                        <h5 i18n>{{"Division" | translate }} </h5>
                        <mat-select formControlName="divisionMarcado" [(value)]="selectedDivision"  (selectionChange)="divisionFilter($event)">
                            <mat-option *ngFor="let op of listaDivision" [value]="op">
                                {{op.name}}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="registerForm.get('divisionMarcado')?.hasError('required')" i18n>{{"REQUIRED_FIELD" | translate }}</mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="col-lg-6">
               <div class="card cardCustos">
                    <mat-form-field ngDefaultControl>
                       <h5 i18n>{{"Merchant" | translate }}</h5>                   
                       <mat-select formControlName="merchMarcado"  (selectionChange)="merchantFilter($event)">
                           <mat-option *ngFor="let op of listaMerchant" [value]="op">
                               {{op.name}}
                           </mat-option>
                       </mat-select>
                    </mat-form-field>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="card cardCustos">
                     <mat-form-field ngDefaultControl>
                        <h5 i18n>{{"Group" | translate }}<span class="obligatorio">*</span></h5>                   
                        <mat-select formControlName="groupMarcado" >
                            <mat-option *ngFor="let op of listaGrupos" [value]="op">
                                {{op.name}}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="registerForm.get('groupMarcado')?.hasError('required')" i18n>{{ groupErrorLiteral }}</mat-error>
                     </mat-form-field>
                 </div>
             </div>
        </div>
 

 
        <div class="row botonera">
             <div class="col-lg-12">
                     <button  class="btn btn-aceptar" (click)="onFormSubmit()" i18n>{{"Submit" | translate }}</button> 
                     <button  class="btn btn-cancelar" (click)="formExit()" i18n>{{"Cancel" | translate }}</button>
             </div>
         </div>
     </form>
     </section>
 
                 
                 
                 
                 
                 
                 
 
 
