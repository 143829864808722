import {formatDate} from '@angular/common';

export class RequestFilter {  
  pageable: string | undefined;
  filter: Filter | null;
  aggregation: QueryAggregationList[] | null;

  constructor(  _filter: Filter | null , page: number, size: number, sort: string) {
    this.pageable = "page="+page+"&size="+size+"&sort="+sort;
    this.filter= _filter;
    this.aggregation = null;
  }

  static construirPage(filter:Filter | null, page :number,pageSize:number,sortId: string){
    return new RequestFilter(filter,page,pageSize,sortId); 
  }

}

export class Filter {
  operation:string = "and";
  queryFilterList: QueryFilterList[] = [];  
}

export class QueryFilterList {
  field:string | undefined;
  operation:string | undefined;
  value:string | undefined;
  queryFilterList: QueryFilterList[] | undefined = [];  

  constructor( _field: string | undefined, _operation: string, _value: string | undefined) {
    this.field = _field;
    this.operation= _operation;
    this.value=_value;
    
  }


  static construirFilterComplet(mapQuery:Map<string, QueryFilterList>, operation:string,  idColumn:string, value:string | undefined){

        if(value){
          var query = new QueryFilterList(idColumn,operation,value);
          mapQuery.set(idColumn,query);
        }else{
          mapQuery.delete(idColumn);
        }      
    
    
        if(mapQuery.size > 0)
        {
          let filter= new Filter();
          filter.operation="and";
    
          mapQuery.forEach(function logMapElements(value, key, map) {
              if (value.value=="dccNot") {
              // Si value es dccNot, envolvemos solo este filtro en "not"
              let notQuery = new QueryFilterList(value.field, operation, value.value);
              notQuery.operation = "not";
              notQuery.queryFilterList = [value];

              filter.queryFilterList.push(notQuery);
              }else{
              filter.queryFilterList.push(value);
              }
              //##### console.log(`m[${key}] = ${value}`);
          })
    
          return filter;
    
        } else {
    
          return null;
    
        }
  }
  



  static construirFilterDomainCompleto(mapQuery:Map<string, QueryFilterList>,idColumn:string, value:string){
    let filter= new Filter(); 

    let jerarquia:string[] 
    jerarquia = value.split(".");
    
    let qflOrg = new QueryFilterList(idColumn,"eq",jerarquia[0]);
    let qflReg!:QueryFilterList, qflDiv!:QueryFilterList, qflMerch!:QueryFilterList

    if(jerarquia.length==2){
      qflMerch = new QueryFilterList(idColumn,"eq",jerarquia[0]+"."+jerarquia[1]);
    }else  if(jerarquia.length==3){
      qflReg = new QueryFilterList(idColumn,"eq",jerarquia[0]+"."+jerarquia[1]);
      qflMerch = new QueryFilterList(idColumn,"eq",jerarquia[0]+"."+jerarquia[1]+"."+jerarquia[2]);
    }else  if(jerarquia.length==4){
      qflReg = new QueryFilterList(idColumn,"eq",jerarquia[0]+"."+jerarquia[1]);
      qflDiv = new QueryFilterList(idColumn,"eq",jerarquia[0]+"."+jerarquia[1]+"."+jerarquia[2]);
      qflMerch = new QueryFilterList(idColumn,"eq",jerarquia[0]+"."+jerarquia[1]+"."+jerarquia[2]+"."+jerarquia[3]);
    }





    //let qflEq = new QueryFilterList(idColumn,"eq",value.substring(0, value.length-1));//quita el punto
    //let qflSt = new QueryFilterList(idColumn,"startsWith",value);     
    let qfl = null;
    if(value){    
      
      if(jerarquia.length==1){
        qfl = new QueryFilterList(undefined, "or",undefined);
        qfl.queryFilterList = [];
        qfl.queryFilterList.push(qflOrg);
        qfl.queryFilterList.push(qflOrg);
        mapQuery.set(idColumn,qfl);
      }else if(jerarquia.length==2){
        qfl = new QueryFilterList(undefined, "or",undefined);
        qfl.queryFilterList = [];
        qfl.queryFilterList.push(qflOrg);
        qfl.queryFilterList.push(qflMerch);
        mapQuery.set(idColumn,qfl);
      }else  if(jerarquia.length==3){

        let qfl_2 = new QueryFilterList(undefined, "or",undefined);
        qfl_2.queryFilterList = [];
        qfl_2.queryFilterList.push(qflOrg);
        qfl_2.queryFilterList.push(qflMerch);

        qfl = new QueryFilterList(undefined, "or",undefined);
        qfl.queryFilterList = [];
        qfl.queryFilterList.push(qfl_2);
        qfl.queryFilterList.push(qflReg);

        mapQuery.set(idColumn,qfl);

      }else {// if(jerarquia.length==4)
       
        let qfl_1 = new QueryFilterList(undefined, "or",undefined);
        qfl_1.queryFilterList = [];
        qfl_1.queryFilterList.push(qflOrg);
        qfl_1.queryFilterList.push(qflMerch);

        let qfl_2 = new QueryFilterList(undefined, "or",undefined);
        qfl_2.queryFilterList = [];
        qfl_2.queryFilterList.push(qflDiv);
        qfl_2.queryFilterList.push(qflReg);

        qfl = new QueryFilterList(undefined, "or",undefined);
        qfl.queryFilterList = [];
        qfl.queryFilterList.push(qfl_1);
        qfl.queryFilterList.push(qfl_2);

        mapQuery.set(idColumn,qfl);
      }
      
    }else{
      mapQuery.delete(idColumn); 
      return filter;     
    }      



    if(mapQuery.size > 1) {
      filter= new Filter();
      filter.operation="and";
      mapQuery.forEach(function logMapElements(value, key, map) {          
          filter.queryFilterList.push(value);
          //##### console.log(`m[${key}] = ${value}`);
      })      
      filter.queryFilterList.push(qfl);
    } else {            
      filter.operation="or";
      filter.queryFilterList.push(qfl);
      //filter.queryFilterList.push(qflEq);
     // filter.queryFilterList.push(qflSt);              
    }

    return filter;    
 }




  static construirFilterDomain(mapQuery:Map<string, QueryFilterList>,idColumn:string, value:string){
    let filter= new Filter();    
    let qflEq = new QueryFilterList(idColumn,"eq",value.substring(0, value.length-1));//quita el punto
    let qflSt = new QueryFilterList(idColumn,"startsWith",value);     
    let qfl = null;
    if(value){      
      qfl = new QueryFilterList(undefined, "or",undefined);
      qfl.queryFilterList = [];
      qfl.queryFilterList.push(qflEq);
      qfl.queryFilterList.push(qflSt);
      mapQuery.set(idColumn,qfl);
    }else{
      mapQuery.delete(idColumn); 
      return filter;     
    }      

    if(mapQuery.size > 1) {
      filter= new Filter();
      filter.operation="and";
      mapQuery.forEach(function logMapElements(value, key, map) {          
          filter.queryFilterList.push(value);
          //##### console.log(`m[${key}] = ${value}`);
      })
      
      filter.queryFilterList.push(qfl);
    } else {            
      filter.operation="or";
      filter.queryFilterList.push(qflEq);
      filter.queryFilterList.push(qflSt);              
    }

    return filter;    
 }


  static construirFilter(mapQuery:Map<string, QueryFilterList>,idColumn:string, value:string | undefined, operator: string = "contains"){
     return this.construirFilterComplet (mapQuery,operator,idColumn, value)    
  }

  static construirFilterArray(idColumn:string, value:string[]){

    let filter= new Filter();
    filter.operation="or";
    if(value){
      value.forEach(element => {
        let query = new QueryFilterList(idColumn,"eq",element);
        filter.queryFilterList.push(query);
      });
    } 
    return filter;
  }

  static construirRangoFilter(mapQuery:Map<string, QueryFilterList>,idColumn:string, valueIni:Number | null | undefined, valueEnd:Number | null | undefined){

    if(valueIni){
      let query = new QueryFilterList(idColumn,"gte",valueIni+"");
      mapQuery.set(idColumn+"_gte",query);
    }else{
      mapQuery.delete(idColumn+"_gte");
    }


    if(valueEnd){
      let query = new QueryFilterList(idColumn,"lte",valueEnd+"");
      mapQuery.set(idColumn+"_lte",query);
    }else{
        mapQuery.delete(idColumn+"_lte");
    }      
 
 
    let filter!:Filter;
    if(mapQuery.size > 0) {
      filter= new Filter();
      filter.operation="and";
      mapQuery.forEach(function logMapElements(value, key, map) {          
          filter.queryFilterList.push(value);
          //##### console.log(`m[${key}] = ${value}`);
      })    
    }
    
    return filter;
 
  }
  static construirRangoFechaFilter(mapQuery:Map<string, QueryFilterList>,idColumn:string, valueIni:Date | null | undefined, valueEnd:Date | null | undefined){

    if(valueIni){
      let query = new QueryFilterList(idColumn,"gte",valueIni.toISOString());
      mapQuery.set(idColumn+"_gte",query);
    }else{
      mapQuery.delete(idColumn+"_gte");
    }


    if(valueEnd){
      valueEnd.setHours(23, 59, 59, 999);
      let query = new QueryFilterList(idColumn,"lte",valueEnd.toISOString());
      mapQuery.set(idColumn+"_lte",query);
    }else{
        mapQuery.delete(idColumn+"_lte");
    }      
 
 
    let filter!: Filter;

    if(mapQuery.size > 0)
    {
      filter = new Filter();
      filter.operation="and";
      mapQuery.forEach(function logMapElements(value, key, map) {          
          filter.queryFilterList.push(value);
          //##### console.log(`m[${key}] = ${value}`);
      })
    }
    
    return filter;
 
  }

  static construirRangoFechaFilterCerrado(mapQuery:Map<string, QueryFilterList>,idColumn:string, valueIni:Date | null | undefined, valueEnd:Date | null | undefined){

    if(valueIni){
      let query = new QueryFilterList(idColumn,"gte",formatDate(valueIni, 'yyyy-MM-dd', 'en'));
      mapQuery.set(idColumn+"_gte",query);
    }else{
      mapQuery.delete(idColumn+"_gte");
    }


    if(valueEnd){
      let query = new QueryFilterList(idColumn,"lte",formatDate(valueEnd, 'yyyy-MM-dd', 'en'));
      mapQuery.set(idColumn+"_lte",query);
    }else{
        mapQuery.delete(idColumn+"_lte");
    }      
 
 
    let filter!: Filter;

    if(mapQuery.size > 0)
    {
      filter = new Filter();
      filter.operation="and";
      mapQuery.forEach(function logMapElements(value, key, map) {          
          filter.queryFilterList.push(value);
          //##### console.log(`m[${key}] = ${value}`);
      })
    }
    
    return filter;
 
  }


  static construirRangoFechaFilterCerradoIgual(mapQuery:Map<string, QueryFilterList>,idColumn:string, valueIni:Date | null | undefined, valueEnd:Date | null | undefined){

    if(valueIni){
      let query = new QueryFilterList(idColumn,"gte",formatDate(valueIni, 'yyyy-MM-dd', 'en'));
      mapQuery.set(idColumn+"_gte",query);
    }else{
      mapQuery.delete(idColumn+"_gte");
    }


    if(valueEnd){
      let query = new QueryFilterList(idColumn,"lte",formatDate(valueEnd, 'yyyy-MM-dd', 'en'));
      mapQuery.set(idColumn+"_lte",query);
    }else{
        mapQuery.delete(idColumn+"_lt");
    }      
 
 
    let filter!: Filter;

    if(mapQuery.size > 0)
    {
      filter = new Filter();
      filter.operation="and";
      mapQuery.forEach(function logMapElements(value, key, map) {          
          filter.queryFilterList.push(value);
          //##### console.log(`m[${key}] = ${value}`);
      })
    }
    
    return filter;
 
  }
  static construirAndFilter(mapQuery:Map<string, QueryFilterList>, operation: string, idColumn:string, value:string){

    if(value){
      let query = new QueryFilterList(idColumn, operation, value);
      mapQuery.set(idColumn,query);
    }else{
      mapQuery.delete(idColumn);
    }      
 
 
    if(mapQuery.size > 0)
    {
      let filter= new Filter();
      filter.operation="and";
 
      mapQuery.forEach(function logMapElements(value, key, map) {
          filter.queryFilterList.push(value);
          //##### console.log(`m[${key}] = ${value}`);
      })
 
      return filter;
 
    } else {
 
      return null;
 
    }
  }
  
}

export class QueryAggregationList {
  field:string | undefined;
  type:string | undefined;

  constructor( _field: string , _type: string) {
    this.field = _field;
    this.type= _type;
  }

  static construirAggregation(mapQuery: any, field: string, aggregation: string) {
    if (!mapQuery) 
    {
      mapQuery = new Array<QueryAggregationList>();
    }
    mapQuery.push( new QueryAggregationList(field, aggregation) );
    return mapQuery;
  }

}
