import { Component, OnInit, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common'
import { authSpaConfig } from '../../../auth/auth-spa.config';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { VIWER_ENTORNO } from 'src/environments/environment';
import { ENTORNO } from 'src/app/util/constants';


@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.css']
})
export class TopbarComponent implements OnInit {
  userProfile: object | undefined;
  entorno = VIWER_ENTORNO
  titulo: string = '';


  constructor(
    @Inject(DOCUMENT) private document: Document,
    private authService: AuthService) {

  }

  ngOnInit(): void {
    this.tituloEntorno();
  }

  login() {
    this.authService.login();
  }
  tituloEntorno() {
    if (this.entorno === ENTORNO.XIIBERO) {
      this.titulo = 'Xiibero';  // Asigna el título para XIIBERO
    } else {
      this.titulo = 'Merchants';   // Asigna el título para otros entornos

    }
  }
}

