import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import {MatLegacyPaginator as MatPaginator, LegacyPageEvent as PageEvent} from '@angular/material/legacy-paginator';
import {MatSort, Sort} from '@angular/material/sort';
import {MatLegacyTableDataSource as MatTableDataSource} from '@angular/material/legacy-table';
import { Alerts } from 'src/app/models/alerts.model';
import { Merchants } from 'src/app/models/merchants.model';
import { QueryFilterList, RequestFilter } from 'src/app/models/requestFilter.model';
import { CommonService } from 'src/app/services/common.service';
import { environment } from 'src/environments/environment';
import { AlertsService } from './alerts.service';
import { Subscription, debounceTime, distinctUntilChanged } from 'rxjs';
import { UtilService } from 'src/app/services/util.service';
import { AuthService } from 'src/app/auth/auth.service';
import { DateFormats } from 'src/app/util/constants';
import { TranslateService } from '@ngx-translate/core';
import { DashboardService } from '../../dashboard/dashboard.service';
declare var require: any

enum Columns {
  description = 'description', 
  date = 'date',
  terminal_terminalId = 'terminal.serialnumber',
  organization_name = 'organization.name'
}


@Component({
  selector: 'app-alerts',
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.css']
})
export class AlertsComponent implements OnInit, AfterViewInit {

  displayedColumns =  Object.keys(Columns);
  dataSource = new MatTableDataSource<Alerts>();
  
  pageLength=0;
  pageIndex=0;
  pageSize=environment.defaultPaginacion;
  pageSizeOptions=environment.paginacion;
  sortId=Columns.date+",desc";


  terminalFilter = new FormControl();
  descriptionFilter = new FormControl();
  organizationFilter = new FormControl();
  localDateFilter = new FormGroup({
    start: new FormControl<Date | null>(null),
    end: new FormControl<Date | null>(null),
  });

  mapQuery = new Map();
  rf: RequestFilter= new RequestFilter(null,0,this.pageSize,this.sortId);

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  jerarquiaSearchSbscription!: Subscription;


  constructor(
      private servicios : AlertsService,
      public utilServer: UtilService,
      public authServer: AuthService,
      private translate : TranslateService,
      private commonService : CommonService,
      private readonly dashboardService: DashboardService
    ) {  
  }
  
  categoryCodeDesc(cod:any){
    var mcc = require('merchant-category-code');  
    let val = mcc(cod);
    if(val){
      return val.usda_description
    }    
    return null;
  }

  async lanzarLlamada(){
    let value = await this.servicios.sendService(this.rf);
    console.log(JSON.stringify(value))
    if(value){
      console.log(JSON.stringify(value.content))
      this.dataSource = new MatTableDataSource<Alerts>(value.content);
      this.pageLength=value.totalElements;
    }
}

cargarCache() {

  let value = this.utilServer.cargarCacheDomain();

  if(value && value!="undefined"){
    value = value.replace(".","")
    this.rf.filter = QueryFilterList.construirFilter(this.mapQuery, "domain", value);        
  }else{
    this.rf.filter = QueryFilterList.construirFilter(this.mapQuery, "domain", undefined);            
  }


  this.lanzarLlamada();  
}


  ngOnInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
   
    this.displayedColumns =  Object.keys(Columns);
    
  
  this.jerarquiaSearchSbscription = this.authServer.getJerarquiaSearchClicked().subscribe(() => this.cargarCache());


 
    this.descriptionFilter.valueChanges.pipe(debounceTime(700),distinctUntilChanged()).subscribe(value => {
      const idColumn = Columns.description;
      console.log(idColumn+"="+value);     
      this.rf.filter = QueryFilterList.construirFilter(this.mapQuery, idColumn, value);        
      this.lanzarLlamada();  
    });   

    this.organizationFilter.valueChanges.pipe(debounceTime(700),distinctUntilChanged()).subscribe(value => {
      const idColumn = Columns.organization_name;
      console.log(idColumn+"="+value);     
      this.rf.filter = QueryFilterList.construirFilter(this.mapQuery, idColumn, value);        
      this.lanzarLlamada();   
   }); 

    this.terminalFilter.valueChanges.pipe(debounceTime(700),distinctUntilChanged()).subscribe(value => {
      const idColumn = Columns.terminal_terminalId;
      console.log(idColumn+"="+value);     
      this.rf.filter = QueryFilterList.construirFilter(this.mapQuery, idColumn, value);        
      this.lanzarLlamada();   
  }); 

  this.localDateFilter.valueChanges.pipe(debounceTime(700),distinctUntilChanged()).subscribe(value => {        
    const idColumn = Columns.date;    
    console.log(idColumn+"->start="+value.start+"   end="+value.end);        
    this.rf.filter = QueryFilterList.construirRangoFechaFilter(this.mapQuery, idColumn, value.start,value.end);        
    this.lanzarLlamada();   
  });

  
  this.cargarCache()

  }

 
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  pageEvent(pag:PageEvent){
    console.log("pageEvent="+JSON.stringify(pag))
    this.pageSize = pag.pageSize;
    this.pageIndex = pag.pageIndex;
    this.rf = RequestFilter.construirPage(this.rf.filter , pag.pageIndex,pag.pageSize,this.sortId);
    this.lanzarLlamada();
  }
 
  announceSortChange(sortState: Sort) {
    console.log("ordenar="+JSON.stringify(sortState)) 
    let active = sortState.active.replace("_",".")
    let direccion="";
    if(sortState.direction){
      direccion=","+sortState.direction
    }
    this.sortId=active+direccion;
    this.rf = RequestFilter.construirPage(this.rf.filter , this.pageIndex,this.pageSize,this.sortId);
    this.lanzarLlamada();
  }

  public formatDatebyLocale() {
    return DateFormats[this.dashboardService.setFormat(navigator.language)] + ', HH:mm:ss'
  }

  refrescar() {
    this.lanzarLlamada();
  }
}

