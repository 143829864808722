<div class="pagetitle">
    <h1 i18n>{{"Division" | translate}}</h1>
</div>
<section class="section">
    <form [formGroup]="registerForm">


        <div class="row">
            <div class="col-lg-6">
                <div class="card cardCustos">
                    <mat-form-field ngDefaultControl>
                        <h5 i18n>{{"Name" | translate}} <span class="obligatorio">*</span></h5>
                        <input formControlName="name" matInput type="text" matInput required maxlength="40">
                        <mat-error *ngIf="registerForm.get('name')?.hasError('required')" i18n>{{"Please enter a name!" | translate}}</mat-error>
                        <mat-error *ngIf="registerForm.get('name')?.hasError('pattern')" i18n>{{"Invalid input format" | translate}}</mat-error>
                        <mat-error *ngIf="registerForm.get('name')?.hasError('maxLength')" i18n>{{"Maximum length exceeded" | translate}}</mat-error>
                    </mat-form-field>
                </div> 
            </div>
            <div class="col-lg-6">
                <div class="card cardCustos">
                    <mat-form-field ngDefaultControl>
                        <h5 i18n>{{"Short Name" | translate}}</h5>
                        <input formControlName="shortname" matInput type="text" maxlength="5">
                        <mat-error *ngIf="registerForm.get('shortname')?.hasError('pattern')" i18n>{{"Invalid input format" | translate}}</mat-error>
                        <mat-error *ngIf="registerForm.get('shortname')?.hasError('maxlength')" i18n>{{"Maximum length exceeded" | translate}}</mat-error>
                    </mat-form-field>
                </div>
            </div>
        </div>


        <div class="row">
            <div class="col-lg-6">
                <div class="card cardCustos">
                    <mat-form-field ngDefaultControl>
                        <h5 i18n>{{"Organization" | translate}} <span class="obligatorio">*</span></h5>
                        <mat-select formControlName="orgMarcado" [(value)]="selectedOrg" (selectionChange)="organizationFilter($event)">
                            <mat-option *ngFor="let op of listaOrg" [value]="op">
                                {{op.name}}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="registerForm.get('orgMarcado')?.hasError('required')" i18n>{{"REQUIRED_FIELD" | translate}}</mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="card cardCustos" *ngIf="verRegion">
                    <mat-form-field ngDefaultControl>
                        <h5 i18n>{{"Region" | translate}} </h5>
                        <mat-select formControlName="regionMarcado" [(value)]="selectedRegion">
                            <mat-option *ngFor="let op of listaRegion" [value]="op">
                                {{op.name}}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="registerForm.get('regionMarcado')?.hasError('required')" i18n>{{"REQUIRED_FIELD" | translate}}</mat-error>
                    </mat-form-field>
                </div>
            </div>
            
        </div>


        <div class="row">
            <div class="col-lg-6">
                <div class="card cardCustos">
                    <mat-form-field ngDefaultControl>
                        <h5 i18n>{{"Phone" | translate}} </h5>
                        <input formControlName="phone" matInput type="text" matInput>
                    </mat-form-field>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="card cardCustos">
                    <mat-form-field ngDefaultControl>
                        <h5 i18n>{{"Email" | translate}} </h5>
                        <input formControlName="email" matInput type="text" matInput>
                        <mat-error *ngIf="registerForm.get('email')?.hasError('pattern')" i18n>{{"Invalid input format" | translate}}</mat-error>
                        <mat-error *ngIf="registerForm.get('email')?.hasError('maxLength')" i18n>{{"Maximum length exceeded" | translate}}</mat-error>
                        <mat-error *ngIf="registerForm.get('email')?.hasError('email')" i18n>{{"email format error" | translate}}</mat-error>
                    </mat-form-field>
                </div>
            </div>
        </div>








        <div class="row botonera">
            <div class="col-lg-12">
                <button class="btn btn-aceptar" (click)="onFormSubmit()" i18n>{{"Submit" | translate}}</button>
                <button class="btn btn-cancelar" (click)="formExit()" i18n>{{"Cancel" | translate}}</button>
            </div>
        </div>



    </form>
</section>