import {  Injectable } from '@angular/core';
import { catchError } from 'rxjs';
import { ChangePassword } from 'src/app/models/changePassword.model';
import { UserProfile, Users } from 'src/app/models/users.model';
import { CommonService } from 'src/app/services/common.service';
import { RestService } from 'src/app/services/merchant/Rest.service';


@Injectable({
  providedIn: 'root',
})
export class UsersProfileService {
  
  constructor(
    private merchantRest : RestService,
    public commonService: CommonService) {

    }

  async changePassword(_body: ChangePassword){
    let value = await this.merchantRest.commonRestCallAuth(_body, 'user/changePassword',true,true)
    .catch(err => {      
      return null
    })
    return value;   
  }
  


  async editProfile(_body: UserProfile){
    let value = await this.merchantRest.commonRestCallAuth(_body, 'user/profile',true,true)   
    .catch(err => {
      return null
    })

    return value;   
  }



 
}