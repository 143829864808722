import { formatDate } from '@angular/common';RequestFilter
import { Injectable } from '@angular/core';
import moment, { weekdays } from 'moment';
import { IndividualConfig, ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable, Subject, Subscription, map } from 'rxjs';
import { ChartPayload } from 'src/app/shared/interfaces/chart.model';
import { Filter, QueryFilterList, RequestFilter } from 'src/app/models/requestFilter.model';
import { RestService } from 'src/app/services/merchant/Rest.service';
import { ServerResponse } from 'src/app/services/merchant/ServerResponse';
import { DateFormats, MerchantRestUrl } from 'src/app/util/constants';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  private subscriptions: Subscription[] = [];
  private merchantSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public merchant$: Observable<any> = this.merchantSubject.asObservable();

  constructor(private merchantRest : RestService) { }
  
  async sendQueryService(_body: RequestFilter){
    let value = await this.merchantRest.commonRestCallMerchant(_body,'transactions/query',false,false)   
    .catch(err => {
      return null
    })
    return value;   
  }

  async sendAggrTermService(_body: RequestFilter) :  Promise<ChartPayload> {
    var retValue = new Subject<any>();

      this.merchantRest.commonRestCallMerchant(_body,'terminal/aggregation',false,false).then(
        value => {
          var keys : Array<string> = new Array<string>();
          var values : Array<number> = new Array<number>();
          value!.buckets.forEach((element: { key: string; number: number; }) => {
            keys.push(element.key);
              values.push(element.number);
          });
          var test = {
            labels :  new Array<string>(),
            values : new Array<number>()
          }          
          test.labels = keys;
          test.values = values;
          retValue.next(test);
          retValue.complete();
      })
      .catch(
        err => { console.log(err); 
        }
      );              
      return retValue.toPromise();
  }

  public createSubject(value: any) {
    const retValue = new Subject<any>();
    const keys : Array<string> = new Array<string>();
    const values : Array<number> = new Array<number>();
    value!.buckets.forEach((element: { key: string; number: number; }) => {
      keys.push(element.key);
        values.push(element.number);
    });
    const test = {
      labels :  new Array<string>(),
      values : new Array<number>()
    }          
    test.labels = keys;
    test.values = values;
    retValue.next(test);
    retValue.complete();
    return retValue;
  }

  async sendAggrTxService(_body: RequestFilter) :  Promise<ChartPayload> {
    var retValue = new Subject<any>();

      this.merchantRest.commonRestCallMerchant(_body,'transactions/aggregation',false,false).then(
        value => {
          var keys : Array<string> = new Array<string>();
          var values : Array<number> = new Array<number>();
          value!.buckets.forEach((element: { key: string; number: number; }) => {
            keys.push(element.key);
              values.push(element.number);
          });
          var test = {
            labels :  new Array<string>(),
            values : new Array<number>()
          }          
          test.labels = keys;
          test.values = values;
          retValue.next(test);
          retValue.complete();
      })
      .catch(
        err => { console.log(err); 
        }
      );              
      return retValue.toPromise();
  }
 
  
  
  async sendAggrTxAmountService(_body: RequestFilter, currencies: string[], interval:any) :  Promise<any> {
    var retValue = new Subject<any>();

    this.merchantRest.commonRestCallMerchant(_body,'transactions/aggregation',false,false).then(
      value => {

      let aggTxDaily = {
            dates : new Array<string>(),
            amounts : new Map<string,number[]>(),
            operations : new Map<string,number[]>(),
      }
      currencies.length === 0 && (currencies = ['EUR', 'BGP']);
      currencies.forEach(element => {
        aggTxDaily.amounts.set(element, new Array<number>());
        aggTxDaily.operations.set(element, new Array<number>());
      });
      let currentDate = new Date(interval.from);
      for (; currentDate <= interval.to; currentDate.setDate(currentDate.getDate() + 1)) {
        let key = formatDate(currentDate, 'yyyy-MM-dd', 'en');
        aggTxDaily.dates.push(key);
        currencies.forEach(element => {
          aggTxDaily.amounts.get(element)!.push(0);
          aggTxDaily.operations.get(element)!.push(0);
        });
      }
      if (value?.buckets.length === 0) {
        value.buckets = this._manageBucketsInDateRange(aggTxDaily.dates, value!.buckets);
      }
      value!.buckets.forEach((element: { key: string; number: number; subAggregation: any}) => {
        let index = 0;
        if(aggTxDaily.dates.length>0){
          index = -1;
        }
        for (let ix=0; ix<aggTxDaily.dates.length; ix++)
        {
          if (aggTxDaily.dates[ix] == element.key) {
            index = ix;
          }

        }
        element.subAggregation.buckets.forEach((subitemCurrency: { key: string; number: number; subAggregation: any}) => {
          if (currencies.includes(subitemCurrency.key)) {
            aggTxDaily.amounts.get(subitemCurrency.key)![index] = subitemCurrency.subAggregation.value;
            aggTxDaily.operations.get(subitemCurrency.key)![index] = subitemCurrency.number;
          }
        });
      });
      retValue.next(aggTxDaily);
      retValue.complete();
      })
      .catch(
        err => { console.log(err) 
    });              
    return retValue.toPromise();   
  }

  public sendAggrTypeByDay(_body: RequestFilter, limit: Date, previousDateFrom: Date, dateRange?: any): Observable<any> {
    return this.merchantRest.getMerchantObservable(_body, 'transactions/aggregation',false,false)
    .pipe(
      map((res) => {
        console.log(res, 'res?');
        return [
          this._createAggregationsTypeByDay(res, limit),
          this._createAggregationsComparisonTypeByDay(res, limit, previousDateFrom, dateRange),
        ]
      }));
  }

  public sendAggrTypeByDayAndHour(_body: RequestFilter, curreny: string): Observable<any> {
    return this.merchantRest.getMerchantObservable(_body, 'transactions/aggregation',false,false)
    .pipe(
      map((res: any) => {
        return res.buckets[0]?.subAggregation?.name === "dateRecord.day_count"
          ? this._createAggregationCardHolderByHour(res, curreny)
          : this._createAggregationsComparisonTypeByHour(res, curreny)
      }));
  }

  public subscribeToMerchantnRestCall(_body: RequestFilter, currencies: string[], interval:any) {
    return this.merchantRest.getMerchantObservable(_body, 'transactions/aggregation',false,false)
    .pipe(
      map((res) => {
        return this._createAggregationsDaily(res, currencies, interval)
      }));
  }

  public subscribeToMerchantnRestCallMonth(_body: RequestFilter, currencies: string[], interval:any) {
    return this.merchantRest.getMerchantObservable(_body, 'transactions/aggregation',false,false)
    .pipe(
      map((res) => {
        return this._createAggregationsMonth(res, currencies, interval)
      }));
  }

  public subscribeToMerchantRestCallTypesMonth(_body: RequestFilter, currencies: string[], interval:any) {
    return this.merchantRest.getMerchantObservable(_body, 'transactions/aggregation',false,false)
    .pipe(
      map((res) => {
        return this._createAggregationsbyTypesMonth(res, currencies, interval)
      }));
  }

  private _createAggregationsTypeByDay(value: any, limit: any) {
    var keys : Array<string> = new Array<string>();
    var values : Array<number> = new Array<number>();
    var prevValues : Array<number> = new Array<number>();
    value!.buckets.forEach((element: { key: string; number: number; subAggregation: any}) => {
      var prev : number = 0;
      var next : number = 0;
      element.subAggregation.buckets.forEach((subitemCurrency: { key: string; number: number; subAggregation: any}) => {
        if (new Date(subitemCurrency.key) >= limit){
          next += subitemCurrency.number;
        } else {
          prev += subitemCurrency.number;
        }
      });
      keys.push(element.key);
      values.push(next);
      prevValues.push(prev);
    });
    var ret = {
      labels :  keys,
      values : values,
      prevValues : prevValues
    }
    return ret;
  }

  private _createAggregationsComparisonTypeByDay(value: any, limit: Date, previousDateFrom: Date, dateRange: any): {payments: any, refunds: any} {
    const payments: any[] = value?.buckets
    .filter(
      (res: { key: string; number: number; subAggregation: any}) =>
        res.key === 'PAYMENT'
    );
    const refunds: any[] = value?.buckets
    .filter(
      (res: { key: string; number: number; subAggregation: any}) =>
        res.key === 'REFUND'
    );
    const managedPaymnets: {
      actualValues: any[],
      prevValues: any[];
    } = this._managePrevAndActualDates(payments, limit, previousDateFrom, dateRange);
    const managedRefunds: {
      actualValues: any[],
      prevValues: any[];
    } = this._managePrevAndActualDates(refunds, limit, previousDateFrom, dateRange);
    
    return {
      payments: managedPaymnets,
      refunds: managedRefunds
    };
  }

  private _createAggregationsComparisonTypeByHour(value: any, currency: string): {transactions: any} {
    const transactionByType: any[] = [];
    const data = value.buckets.find((array: any) => array.key === currency) || this._createEmptyCurrencyRes();
    data.subAggregation.buckets.flatMap((bucket: any) => bucket.subAggregation.buckets).forEach((transactionType: any) => {
      transactionByType.push(transactionType);
    });
    const managedTransactions = this._manageArrayPerHour(transactionByType);
    return {
      transactions: managedTransactions
    };
  }

  private _createAggregationCardHolderByHour(value: any, currency: string): {operationsByCardsPrev: any, operationsByCardsActual: any} {
    const data = value.buckets.find((array: any) => array.key === currency) || this._createEmptyCurrencyRes();
    const yesterdaysOperations: any[] = data.subAggregation.buckets[0]?.subAggregation?.buckets;
    const todaysOperations: any[] = data.subAggregation.buckets[1]?.subAggregation?.buckets;
    const managedYesterdays = this._manageArrayPerHour(yesterdaysOperations);
    const managedTodays = this._manageArrayPerHour(todaysOperations);

    return {
      operationsByCardsPrev: {cardHolder: managedYesterdays},
      operationsByCardsActual: {cardHolder: managedTodays}
    };
  }

  private _createAggregationsDaily(value: any, currencies: string[], interval: any) {

    let aggTxDaily = {
      dates : new Array<string>(),
      amounts : new Map<string,number[]>(),
      operations : new Map<string,number[]>(),
      values: new Array<any>()
    }
    currencies.length === 0 && (currencies = ['EUR', 'BGP']);
    currencies.forEach(element => {
      aggTxDaily.amounts.set(element, new Array<number>());
      aggTxDaily.operations.set(element, new Array<number>());
    });

    let currentDate = new Date(interval.from);
    for (; currentDate <= interval.to; currentDate.setDate(currentDate.getDate() + 1)) {
      let key = formatDate(currentDate, 'yyyy-MM-dd', 'en');
      aggTxDaily.dates.push(key);
      currencies.forEach(element => {
        aggTxDaily.amounts.get(element)!.push(0);
        aggTxDaily.operations.get(element)!.push(0);
      });
    }
    

    if (value.buckets.length === 0) {
      value!.buckets = this._manageBucketsInDateRange(aggTxDaily.dates, value!.buckets);
    }

    value!.buckets.forEach((element: { key: string; number: number; subAggregation: any}) => {
      let index = 0;

      if(aggTxDaily.dates.length>0) {
        index = -1;
      }

      for (let ix=0; ix<aggTxDaily.dates.length; ix++) {
        if (aggTxDaily.dates[ix] == element.key) {
          index = ix;
        }
      }
      element.subAggregation.buckets.forEach((subitemCurrency: { key: string; number: number; subAggregation: any}) => {
        if (currencies.includes(subitemCurrency.key)) {
          aggTxDaily.amounts.get(subitemCurrency.key)![index] = subitemCurrency.subAggregation.value;
          aggTxDaily.operations.get(subitemCurrency.key)![index] = subitemCurrency.number;
        }
      });
    });
    return aggTxDaily;
  }

  private _createAggregationsMonth(value: any, currencies: string[], interval: any) {

    let aggTxMonth = {
      dates: new Array<string>(),
      amounts: new Map<string, number[]>(),
      operations: new Map<string, number[]>(),
      values: new Array<any>()
    };
  
    currencies.length === 0 && (currencies = ['EUR', 'BGP']);
    currencies.forEach(element => {
      aggTxMonth.amounts.set(element, new Array<number>());
      aggTxMonth.operations.set(element, new Array<number>());
    });
  
    let currentDate = new Date(interval.from);
    for (; currentDate <= interval.to; currentDate.setMonth(currentDate.getMonth() + 1)) {
      let key = formatDate(currentDate, 'yyyy-MM', 'en');
      aggTxMonth.dates.push(key);
      currencies.forEach(element => {
        aggTxMonth.amounts.get(element)!.push(0);
        aggTxMonth.operations.get(element)!.push(0);
      });
    }
  
    if (value.buckets.length === 0) {
      value!.buckets = this._manageBucketsInDateRange(aggTxMonth.dates, value!.buckets);
    }
  
    value!.buckets.forEach((element: { key: string; number: number; subAggregation: any }) => {
      let index = 0;
  
      if (aggTxMonth.dates.length > 0) {
        index = -1;
      }
  
      for (let ix = 0; ix < aggTxMonth.dates.length; ix++) {
        const month = parseInt(aggTxMonth.dates[ix].split('-')[1], 10).toString();
        if (month == element.key) {
          index = ix;
        }
      }
      element.subAggregation.buckets.forEach((subitemCurrency: { key: string; number: number; subAggregation: any }) => {
        if (currencies.includes(subitemCurrency.key)) {
          aggTxMonth.amounts.get(subitemCurrency.key)![index] = subitemCurrency.subAggregation.value;
          aggTxMonth.operations.get(subitemCurrency.key)![index] = subitemCurrency.number;
        }
      });
    });
  
    return aggTxMonth;
  }

  private _createAggregationsbyTypesMonth(value: any, currencies: string[], interval: any) {
    let aggTxMonth = {
      dates: new Array<string>(),
      amounts: new Map<string, Map<string, number[]>>(),
      operations: new Map<string, number[]>(),
      values: new Map<string, Map<string, number[]>>()
    };
  
    if (currencies.length === 0) {
      currencies = ['EUR', 'GBP'];
    }
  
    currencies.forEach(currency => {
      aggTxMonth.amounts.set(currency, new Map<string, number[]>());
      aggTxMonth.operations.set(currency, new Array<number>());
      aggTxMonth.values.set(currency, new Map<string, number[]>());
      ["PAYMENT", "REFUND", "VOID", "PREAUTH", "TOPUP"].forEach(type => {
        aggTxMonth.amounts.get(currency)!.set(type, new Array<number>());
        aggTxMonth.values.get(currency)!.set(type, new Array<number>());
      });
    });
  
    let currentDate = new Date(interval.from);
    for (; currentDate <= interval.to; currentDate.setMonth(currentDate.getMonth() + 1)) {
      let key = formatDate(currentDate, 'yyyy-MM', 'en');
      aggTxMonth.dates.push(key);
  
      currencies.forEach(currency => {
        aggTxMonth.operations.get(currency)!.push(0);
        ["PAYMENT", "REFUND", "VOID", "PREAUTH", "TOPUP"].forEach(type => {
          aggTxMonth.amounts.get(currency)!.get(type)!.push(0);
          aggTxMonth.values.get(currency)!.get(type)!.push(0);
        });
      });
    }
  
    if (value.buckets.length === 0) {
      value.buckets = this._manageBucketsInDateRange(aggTxMonth.dates, value.buckets);
    }
  
    value.buckets.forEach((element: { key: string; number: number; subAggregation: any }) => {
      let index = -1;
  
      for (let ix = 0; ix < aggTxMonth.dates.length; ix++) {
        const month = parseInt(aggTxMonth.dates[ix].split('-')[1], 10).toString();
        if (month == element.key) {
          index = ix;
        }
      }
  
      element.subAggregation.buckets.forEach((subitemCurrency: { key: string; number: number; subAggregation: any }) => {
        if (currencies.includes(subitemCurrency.key)) {
          subitemCurrency.subAggregation.buckets.forEach((subitemType: { key: string; number: number; subAggregation: any }) => {
            const transactionType = subitemType.key; 
  
            aggTxMonth.amounts.get(subitemCurrency.key)!.get(transactionType)![index] = subitemType.subAggregation.value;
            aggTxMonth.values.get(subitemCurrency.key)!.get(transactionType)![index] = subitemType.number;
          });
        }
      });
    });
  
    return aggTxMonth;
  }

  private _managePrevAndActualDates(dataArray:any[], limit: Date, previousDateFrom: Date, dateRange: any) {
    const limitFormat = new Date(limit.getTime());
    const lastdateOrToday = dateRange?.to || moment().toDate()
    const prevStartDate = formatDate(previousDateFrom, 'yyyy-MM-dd', 'en');
    const limitDate = formatDate(limit, 'yyyy-MM-dd', 'en');
    const prevLastDate = formatDate(limitFormat.setDate(limitFormat.getDate() - 1), 'yyyy-MM-dd', 'en')
    const actualLastDate = formatDate(lastdateOrToday, 'yyyy-MM-dd', 'en');
    const prevDates: string[] = this._manageDateRange({
        startDate: prevStartDate,
        lastDate: prevLastDate
      });
    const actualDates: string[] = this._manageDateRange({
      startDate: limitDate,
      lastDate: actualLastDate
    });
    const prevBuckets = this._manageBucketsInDateRange(prevDates, dataArray[0]?.subAggregation?.buckets);
    const actualBuckets = this._manageBucketsInDateRange(actualDates, dataArray[0]?.subAggregation?.buckets);

    return {
      actualValues: actualBuckets,
      prevValues: prevBuckets
    }
  }

  private _manageDateRange(dateString: {startDate: string, lastDate: string}) {
    let dates: string[] = [];
    let currentDate = new Date(dateString.startDate);
    const lastDate = new Date(dateString.lastDate)
    while (currentDate <= lastDate) {
      dates.push(currentDate.toISOString().split('T')[0]);
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return dates;
  }

  private _manageArrayPerHour(buckets: any[]) {
    const arraysOfHours: {key: string, number: number, subAggregation: any}[] = [];
    for (let hour = 7; hour < 24; hour++) {
      const bucketExist = buckets?.find((bucket: {key: string, number: number, subAggregation: any}) =>
        (hour).toString() === bucket.key);
      const hourInBucketArray = buckets?.filter((bucket: {key: string, number: number, subAggregation: any}) =>
        (hour).toString() === bucket.key);
      const transactionsAmountInBucket = hourInBucketArray?.reduce((acc, bucket) => acc + bucket.number, 0)
      if (bucketExist) {
        arraysOfHours.push({
          key: hour.toString(),
          number: transactionsAmountInBucket,
          subAggregation: bucketExist.subAggregation
        });
      } else {
        arraysOfHours.push({
          key: hour.toString(),
          number: 0,
          subAggregation: {
            name: "dateRecord.hourDay_sum",
            number: 0,
            value: 0
          }
        });
        
      }
    }
    return arraysOfHours;
  }

  private _manageBucketsInDateRange(dates: string[], buckets: any[]) {
  const newBuckets: any[] = []; 
    dates.forEach(date => {
      const bucketInDataArray = buckets?.find((bucket: any) => bucket.key === date);
      if (bucketInDataArray) {
        newBuckets.push(bucketInDataArray)
      } else {
        newBuckets.push(this._createEmptyBucket(date));
      }
    });
    return newBuckets;
  }

  public setFormat(localeFormat: string): keyof typeof DateFormats {
    
    if (DateFormats[localeFormat as keyof typeof DateFormats]) {
      return localeFormat as keyof typeof DateFormats;
    } else {
      return this._findInclude(localeFormat) as keyof typeof DateFormats;
    }
  }
  private _findInclude(localeFormat: string): string | undefined {
    return Object.keys(DateFormats).find(lang =>
      localeFormat.includes(lang)
    )
  }

  private _createEmptyBucket(date: any) {
    return {
      key: date,
      number: 0,
      subAggregation: {
        buckets: [
          {
            key: 'EUR',
            number: 0,
            subAggregation: {
              name: '',
              number: 0,
              value: 0
            }
          },
          {
            key: 'GBP',
            number: 0,
            subAggregation: {
              name: '',
              number: 0,
              value: 0
      }}
    ]}};
  }

  private _createEmptyCurrencyRes() {
    return {
      subAggregation: {
        buckets: [
          {
            subAggregation: {
            buckets: [
              {
                key: "PAYMENT",
                number: 0,
                subAggregation: []
              },
              {
                key: "REFUNDS",
                number: 0,
                subAggregation: []
      }]
    }}]
  }}};

  public calculateTimeZone(date: any) {
    const formatedDate = this._toIsoDate(date);
    if (isNaN(formatedDate.getTime())) {
      return '';
    }
    return this._formatDateTimeBaseOnLocale(formatedDate);
  }

  private _toIsoDate(date: any) {
    const isoDateFormat = date?.replace(/T(\d{2})\.(\d{2})\.(\d{2}\.\d{3})/, 'T$1::$2:$3');
    const dateToGtml = new Date(isoDateFormat);
    const userTimeZoneOffset = dateToGtml.getTimezoneOffset()*60000;
    const localDate = new Date(dateToGtml.getTime() - userTimeZoneOffset);
    return localDate;
  }

  private _formatDateTimeBaseOnLocale(date: Date):string {
    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric', month: '2-digit', day: '2-digit',
      hour: '2-digit', minute: '2-digit', second: '2-digit'};
    return new Intl.DateTimeFormat(navigator.language, options).format(date);
  }
}
