/** Usaremos |= para añadir flags: flags |= BuildFlags.DEBUG; agregaria el flag debug a la variable "flags"
 * Se pueden combinar flags usando un OR simple "|": flags = DEBUG | DEV; asignaria los flags DEBUG y DEV a la variable "flags"
 * Combinacion de &= y ~ para limpiar un flag por ejemplo: flags &= ~BuildFlags.DEBUG; borraria el flag debug de la variable "flags" */
export enum BuildFlags {
    None = 0,
    DEBUG = 1 << 0, // 0001 -- the bitshift is unnecessary, but done for consistency
    RELEASE = 1 << 1,     // 0010
    DEV = 1 << 2,    // 0100
    //algo = 1 << 3,   // 1000
    All = ~(~0 << 4)   // 1111
  }
  
  export enum LogLevel {
    OFF = 0,
    FATAL = 100,
    ERROR = 200,
    WARN = 300,
    INFO = 400,
    DEBUG = 500,
    TRACE = 600,
    ALL = Number.MAX_VALUE
  }