import { Injectable } from '@angular/core';
import { IndividualConfig } from 'ngx-toastr';
import { QueryAggregationList, QueryFilterList, RequestFilter } from '../models/requestFilter.model';
import {formatDate} from '@angular/common';
import { DateRange } from '../models/date-range.interface';

export interface toastPayload {
  message: string;
  title: string;
  ic: IndividualConfig;
  type: string;
}

@Injectable({
  providedIn: 'root',
})
export class QueriesService {
  constructor() {}


  // Solo operaciones SALE,AUTH etc
  // Solo success
  jDashboardUpdate =  
  {
    "total_amount": { "sum": { "field": "amount", "missing" : 0 } },
    "total_tip": { "sum": { "field": "tip", "missing" : 0 } }
  };



//  "localDate": "20220628T120000",
  public createDateRangeFilter(index: number, mapQuery: any): RequestFilter {

    let rf = new RequestFilter(null,0, 500, 'date,desc');
    let interval = this.getRangeFilter(index);
    rf.filter = QueryFilterList.construirAndFilter(mapQuery, "gte", 'date', formatDate(interval.from, 'yyyy-MM-dd', 'en'));        
    return rf;

  }

  public createDateRangeFilterDashboard(interval:DateRange, mapQuery: any): RequestFilter {
    let rf = new RequestFilter(null,0, 500, 'date,desc');
    rf.filter = QueryFilterList.construirRangoFechaFilterCerradoIgual(mapQuery, 'date', new Date(interval.from!), new Date(interval.to!));        
    return rf;

  }

  public getPreviousDateRange(dateRange: DateRange): DateRange {
    if (!dateRange.from || !dateRange.to) {
        throw new Error('Invalid date range: "from" and "to" must both be non-null.');
    }
    const difference = dateRange.to.getTime() - dateRange.from.getTime();

    const previousToDate = new Date(dateRange.from.getTime());
    const previousFromDate = new Date(previousToDate.getTime() - difference);
    previousFromDate.setDate(previousFromDate.getDate() - 1);


    return {from:previousFromDate, to:previousToDate};
}
  public getRangeFilter(index: number) {
    let lastDate = new Date();
    let myDate = new Date();
    myDate.setHours(0);
    myDate.setMinutes(0);
    myDate.setSeconds(0);
    myDate.setMilliseconds(0);
    switch (index) {
      default:
      case 0:
        break;
      case 1:
        let offset = myDate.getDay() + 6; // Sunday = 0
        offset %= 7;
        myDate.setDate(myDate.getDate() - offset);
        lastDate = new Date(myDate);
        lastDate.setDate(myDate.getDate() + 7);
        break;
      case 2:
        myDate.setDate(myDate.getDate() - 6);
        break;
      case 3:
        myDate.setDate(myDate.getDate() - 13);
        break;
      case 4:
        myDate.setDate(1);
        break;
      case 5:
        myDate.setMonth(myDate.getMonth() - 1);
        break;
    }
    return { from: myDate, to: lastDate};
}

  public getRangeFilterComparation(index: number) {

    let myDate = new Date();
    let prevDate : Date;
    myDate.setHours(0);
    myDate.setMinutes(0);
    myDate.setSeconds(0);
    myDate.setMilliseconds(0);
    prevDate = new Date(myDate);
    switch (index) {
      default:
      case 0:
        break;
      case 1:
        let offset = myDate.getDay() + 6; // Sunday = 0
        offset %= 7;
        myDate.setDate(myDate.getDate() - offset);
        prevDate = new Date(myDate);
        prevDate.setDate(prevDate.getDate() - 7);
        break;
      case 2:
        myDate.setDate(myDate.getDate() - 6);
        prevDate = new Date(myDate);
        prevDate.setDate(prevDate.getDate() - 7);
        break;
      case 3:
        myDate.setDate(myDate.getDate() - 13);
        prevDate = new Date(myDate);
        prevDate.setDate(prevDate.getDate() - 14);
        break;
      case 4:
        myDate.setDate(1);
        prevDate = new Date(myDate);
        prevDate.setMonth(myDate.getMonth() - 1);
        break;
      case 5:
        myDate.setMonth(myDate.getMonth() - 1);
        prevDate = new Date(myDate);
        prevDate.setMonth(myDate.getMonth() - 1);
        break;
    }
    return { from: prevDate, to: myDate};
  }

  public createPrevDateRangeFilter(index: number, mapQuery: any): RequestFilter {

    let rf = new RequestFilter(null,0, 500, 'date,desc');   

    let interval = this.getRangeFilterComparation(index);
    rf.filter = QueryFilterList.construirRangoFechaFilterCerrado(mapQuery, 'date', interval.from, interval.to);
    return rf;  
  }


  public createPrevDateRangeFilterDashboard(dateRange: DateRange, mapQuery: any): RequestFilter {

    let rf = new RequestFilter(null,0, 500, 'date,desc');   

    let interval = this.getPreviousDateRange(dateRange);
    rf.filter = QueryFilterList.construirRangoFechaFilterCerrado(mapQuery, 'date', interval.from, interval.to);
    return rf;  
  }


  public createCurrentSales(dateRange: DateRange, domain:string | undefined | undefined): RequestFilter {
    let mapQuery = new Map();
    let filter: RequestFilter = this.createDateRangeFilterDashboard(dateRange, mapQuery);
    filter.filter = QueryFilterList.construirAndFilter(mapQuery, "eq", 'status', 'SUCCESS'); 
    if(domain){  filter.filter = QueryFilterList.construirAndFilter(mapQuery, "startsWith", 'domain', domain);     }  

    filter.aggregation = QueryAggregationList.construirAggregation(filter.aggregation, "trx.currency", "count");
    filter.aggregation = QueryAggregationList.construirAggregation(filter.aggregation, "type", "count");
    filter.aggregation = QueryAggregationList.construirAggregation(filter.aggregation, "trx.amount", "sum");

    return filter;

  }

  public createCurrentSplitSales(dateRange: DateRange, domain:string | undefined): object {
    let mapQuery = new Map();
    let filter: RequestFilter = this.createDateRangeFilterDashboard(dateRange, mapQuery);
    filter.filter = QueryFilterList.construirAndFilter(mapQuery, "eq", 'status', 'SUCCESS');        
    filter.filter = QueryFilterList.construirAndFilter(mapQuery, "eq", 'type', 'PAYMENT');  
        if(domain){ filter.filter = QueryFilterList.construirAndFilter(mapQuery, "startsWith", 'domain', domain);     }

    filter.filter = QueryFilterList.construirAndFilter(mapQuery, "eq", 'trx.isSplitPayment', 'true');        
    filter.aggregation = QueryAggregationList.construirAggregation(filter.aggregation, "trx.transactionId", "count");
    filter.aggregation = QueryAggregationList.construirAggregation(filter.aggregation, "trx.currency", "count");
    filter.aggregation = QueryAggregationList.construirAggregation(filter.aggregation, "trx.amount", "sum");
    return filter;
  }

  public createCurrentSalesWithTips(dateRange: DateRange, domain:string | undefined): object {
    let mapQuery = new Map();
    let filter: RequestFilter = this.createDateRangeFilterDashboard(dateRange, mapQuery);
    filter.filter = QueryFilterList.construirAndFilter(mapQuery, "eq", 'status', 'SUCCESS');        
    filter.filter = QueryFilterList.construirAndFilter(mapQuery, "eq", 'type', 'PAYMENT');        
    filter.filter = QueryFilterList.construirAndFilter(mapQuery, "gt", 'trx.tips', '0');   
        if(domain){ filter.filter = QueryFilterList.construirAndFilter(mapQuery, "startsWith", 'domain', domain);     }     
    filter.aggregation = QueryAggregationList.construirAggregation(filter.aggregation, "trx.currency", "count");
    filter.aggregation = QueryAggregationList.construirAggregation(filter.aggregation, "trx.tips", "sum");
    return filter;
  }

  public createCurrentTips(index: number, domain:string | undefined): RequestFilter {
    let mapQuery = new Map();
    let filter: RequestFilter = this.createDateRangeFilter(index, mapQuery);
    filter.filter = QueryFilterList.construirAndFilter(mapQuery, "eq", 'status', 'SUCCESS');    
        if(domain){ filter.filter = QueryFilterList.construirAndFilter(mapQuery, "startsWith", 'domain', domain);     }    
    filter.aggregation = QueryAggregationList.construirAggregation(filter.aggregation, "type", "count");
    filter.aggregation = QueryAggregationList.construirAggregation(filter.aggregation, "trx.currency", "count");
    filter.aggregation = QueryAggregationList.construirAggregation(filter.aggregation, "trx.amount", "sum");
    return filter;
  }

  public createCurrentTypeBreakout(dateRange: DateRange, domain:string | undefined): RequestFilter {
    let mapQuery = new Map();
    let filter: RequestFilter = this.createDateRangeFilterDashboard(dateRange, mapQuery);
    filter.filter = QueryFilterList.construirAndFilter(mapQuery, "eq", 'status', 'SUCCESS'); 
        if(domain){ filter.filter = QueryFilterList.construirAndFilter(mapQuery, "startsWith", 'domain', domain);     }       
    filter.aggregation = QueryAggregationList.construirAggregation(filter.aggregation, "type", "count");
    return filter;
  }

  public createCurrentBrandBreakout(dateRange: DateRange, domain:string | undefined): RequestFilter {
    let mapQuery = new Map();
    let filter: RequestFilter = this.createDateRangeFilterDashboard(dateRange, mapQuery);
    filter.filter = QueryFilterList.construirAndFilter(mapQuery, "eq", 'status', 'SUCCESS');  
    filter.filter = QueryFilterList.construirAndFilter(mapQuery, "ne", 'type', 'SETTLEMENT');
        if(domain){ filter.filter = QueryFilterList.construirAndFilter(mapQuery, "startsWith", 'domain', domain);     }      
    filter.aggregation = QueryAggregationList.construirAggregation(filter.aggregation, "card.cardType", "count");
    return filter;
  }

  public createCurrentBrandBreakoutHours(dateRange: DateRange, domain:string | undefined, currency?: string): RequestFilter {
    let mapQuery = new Map();
    let filter: RequestFilter = this.createDateRangeFilterDashboard(dateRange, mapQuery);
    filter.filter = QueryFilterList.construirAndFilter(mapQuery, "eq", 'status', 'SUCCESS');  
    filter.filter = QueryFilterList.construirAndFilter(mapQuery, "ne", 'type', 'SETTLEMENT');
    if(domain){
      filter.filter = QueryFilterList.construirAndFilter(mapQuery, "startsWith", 'domain', domain);
    }
    filter.aggregation = QueryAggregationList.construirAggregation(filter.aggregation, "trx.currency", "count");
    filter.aggregation = QueryAggregationList.construirAggregation(filter.aggregation, "dateRecord.day", "count");
    filter.aggregation = QueryAggregationList.construirAggregation(filter.aggregation, "dateRecord.hourDay", "count");
    filter.aggregation = QueryAggregationList.construirAggregation(filter.aggregation, "card.cardType", "count");
    return filter;
  }

  public createCurrentTechBreakout(dateRange: DateRange, domain:string | undefined): RequestFilter {
    let mapQuery = new Map();
    let filter: RequestFilter = this.createDateRangeFilterDashboard(dateRange, mapQuery);
    filter.filter = QueryFilterList.construirAndFilter(mapQuery, "eq", 'status', 'SUCCESS');
    filter.filter = QueryFilterList.construirAndFilter(mapQuery, "ne", 'type', 'SETTLEMENT');
        if(domain){ filter.filter = QueryFilterList.construirAndFilter(mapQuery, "startsWith", 'domain', domain);     }        
    filter.aggregation = QueryAggregationList.construirAggregation(filter.aggregation, "card.entry", "count");
    return filter;
  }

  public createCurrentCurrencyBreakout(dateRange: DateRange, domain:string | undefined): RequestFilter {
    let mapQuery = new Map();
    let filter: RequestFilter = this.createDateRangeFilterDashboard(dateRange, mapQuery);
    filter.filter = QueryFilterList.construirAndFilter(mapQuery, "eq", 'status', 'SUCCESS'); 
    filter.filter = QueryFilterList.construirAndFilter(mapQuery, "ne", 'type', 'SETTLEMENT');
        if(domain){ filter.filter = QueryFilterList.construirAndFilter(mapQuery, "startsWith", 'domain', domain);     }       
    filter.aggregation = QueryAggregationList.construirAggregation(filter.aggregation, "trx.currency", "count");
    return filter;
  }

  public createCurrentAuthModeBreakout(dateRange: DateRange, domain:string | undefined): RequestFilter {
    let mapQuery = new Map();
    let filter: RequestFilter = this.createDateRangeFilterDashboard(dateRange, mapQuery);
    filter.filter = QueryFilterList.construirAndFilter(mapQuery, "eq", 'status', 'SUCCESS'); 
    filter.filter = QueryFilterList.construirAndFilter(mapQuery, "ne", 'type', 'SETTLEMENT');    
        if(domain){ filter.filter = QueryFilterList.construirAndFilter(mapQuery, "startsWith", 'domain', domain);     }       
    filter.aggregation = QueryAggregationList.construirAggregation(filter.aggregation, "cardholder.authmode", "count");
    return filter;
  }

  public createCurrentResultsBreakout(dateRange: DateRange, domain:string | undefined): RequestFilter {
    let mapQuery = new Map();
    let filter: RequestFilter = this.createDateRangeFilterDashboard(dateRange, mapQuery);
    if(domain){ filter.filter = QueryFilterList.construirAndFilter(mapQuery, "startsWith", 'domain', domain);     }       
    filter.aggregation = QueryAggregationList.construirAggregation(filter.aggregation, "status", "count");
    return filter;
  }

  public createTop5Merchants(dateRange: DateRange, currency: string, domain:string | undefined): RequestFilter {
    let mapQuery = new Map();
    let filter: RequestFilter = this.createDateRangeFilterDashboard(dateRange, mapQuery);
    filter.filter = QueryFilterList.construirAndFilter(mapQuery, "eq", 'status', 'SUCCESS'); 
        if(domain){ filter.filter = QueryFilterList.construirAndFilter(mapQuery, "startsWith", 'domain', domain);     }       
    filter.pageable = "page=0&size=5&sort=trx.amount_sum,desc";
    filter.aggregation = QueryAggregationList.construirAggregation(filter.aggregation, "merchant.merchantId", "count");
    filter.aggregation = QueryAggregationList.construirAggregation(filter.aggregation, "trx.amount", "sum");
    filter.filter = QueryFilterList.construirAndFilter(mapQuery, "eq", 'trx.currency', currency);        

    return filter;
  }

  public createMerchantNamesQuery(ids: string[]): RequestFilter {
    let rf = new RequestFilter(null,0, 5, 'id,desc');   
    rf.filter = QueryFilterList.construirFilterArray('merchantId', ids);        
    return rf;
  }
  public createCurrentModelBreakout(domain:string | undefined): RequestFilter {
    let mapQuery = new Map();
    let filter: RequestFilter = new RequestFilter(null,0, 500, 'model,desc');   
    if(domain){ filter.filter = QueryFilterList.construirAndFilter(mapQuery, "startsWith", 'domain', domain);}
    filter.aggregation = QueryAggregationList.construirAggregation(filter.aggregation, "model", "count");
    return filter;
  }

  public createAggregationByHour(dateRange: DateRange, domain:string | undefined): RequestFilter {
    let mapQuery = new Map();
    let filter: RequestFilter = this.createDateRangeFilterDashboard(dateRange, mapQuery);
        if(domain){ filter.filter = QueryFilterList.construirAndFilter(mapQuery, "startsWith", 'domain', domain);}
    filter.aggregation = QueryAggregationList.construirAggregation(filter.aggregation, "dateRecord.day", "count");
    filter.aggregation = QueryAggregationList.construirAggregation(filter.aggregation, "dateRecord.hourDay", "count");
    return filter;
  }

  public createAggregationByDailyAmount(dateRange: DateRange, domain:string | undefined): RequestFilter {
    let mapQuery = new Map();
    let filter: RequestFilter = this.createDateRangeFilterDashboard(dateRange, mapQuery);
    filter.filter = QueryFilterList.construirAndFilter(mapQuery, "eq", 'status', 'SUCCESS');        
    filter.filter = QueryFilterList.construirAndFilter(mapQuery, "eq", 'type', 'PAYMENT'); 
        if(domain){ filter.filter = QueryFilterList.construirAndFilter(mapQuery, "startsWith", 'domain', domain);     }       
    filter.aggregation = QueryAggregationList.construirAggregation(filter.aggregation, "dateRecord.day", "count");
    filter.aggregation = QueryAggregationList.construirAggregation(filter.aggregation, "trx.currency", "count");
    filter.aggregation = QueryAggregationList.construirAggregation(filter.aggregation, "trx.amount", "sum");
    return filter;
  }

  public createAggregationByMonthAmount(dateRange: DateRange, domain:string | undefined): RequestFilter {
    let mapQuery = new Map();
    let filter: RequestFilter = this.createDateRangeFilterDashboard(dateRange, mapQuery);
    filter.filter = QueryFilterList.construirAndFilter(mapQuery, "eq", 'status', 'SUCCESS');        
    filter.filter = QueryFilterList.construirAndFilter(mapQuery, "eq", 'type', 'PAYMENT'); 
        if(domain){ filter.filter = QueryFilterList.construirAndFilter(mapQuery, "startsWith", 'domain', domain);     }       
    filter.aggregation = QueryAggregationList.construirAggregation(filter.aggregation, "dateRecord.month", "count");
    filter.aggregation = QueryAggregationList.construirAggregation(filter.aggregation, "trx.currency", "count");
    filter.aggregation = QueryAggregationList.construirAggregation(filter.aggregation, "trx.amount", "sum");
    return filter;
  }

  public createAggregationByTypesMonth(dateRange: DateRange, domain:string | undefined): RequestFilter {
    let mapQuery = new Map();
  let filter: RequestFilter = this.createDateRangeFilterDashboard(dateRange, mapQuery);
  filter.filter = QueryFilterList.construirAndFilter(mapQuery, "eq", 'status', 'SUCCESS');
  if(domain){ filter.filter = QueryFilterList.construirAndFilter(mapQuery, "startsWith", 'domain', domain);     }
      if(filter.filter != null){
    filter.filter.queryFilterList.push({
      operation: "or",
      queryFilterList: [
        {
          field: "type", operation: "eq", value: "PAYMENT",
          queryFilterList: undefined
        },
        {
          field: "type", operation: "eq", value: "REFUND",
          queryFilterList: undefined
        },
        {
          field: "type", operation: "eq", value: "VOID",
          queryFilterList: undefined
        },
        {
          field: "type", operation: "eq", value: "PREAUTH",
          queryFilterList: undefined
        },
        {
          field: "type", operation: "eq", value: "TOPUP",
          queryFilterList: undefined
        }
      ],
      field: undefined,
      value: undefined
      }); 
    }

    filter.aggregation = QueryAggregationList.construirAggregation(filter.aggregation, "dateRecord.month", "count");
    filter.aggregation = QueryAggregationList.construirAggregation(filter.aggregation, "trx.currency", "count");
    filter.aggregation = QueryAggregationList.construirAggregation(filter.aggregation, "type", "terms");
    filter.aggregation = QueryAggregationList.construirAggregation(filter.aggregation, "trx.amount", "sum");
    return filter;
  }
  
  public createAggregationByDailyAmountPrev(dateRange: DateRange, domain:string | undefined): RequestFilter {
    let mapQuery = new Map();
    let filter: RequestFilter = this.createPrevDateRangeFilterDashboard(dateRange, mapQuery);
    filter.filter = QueryFilterList.construirAndFilter(mapQuery, "eq", 'status', 'SUCCESS');        
    filter.filter = QueryFilterList.construirAndFilter(mapQuery, "eq", 'type', 'PAYMENT');  
        if(domain){ filter.filter = QueryFilterList.construirAndFilter(mapQuery, "startsWith", 'domain', domain);     }      
    filter.aggregation = QueryAggregationList.construirAggregation(filter.aggregation, "dateRecord.day", "count");
    filter.aggregation = QueryAggregationList.construirAggregation(filter.aggregation, "trx.currency", "count");
    filter.aggregation = QueryAggregationList.construirAggregation(filter.aggregation, "trx.amount", "sum");
    return filter;
  }

  
  public createAggregationByDailyAmountSplitted(dateRange: DateRange, domain:string | undefined): RequestFilter {
    let mapQuery = new Map();
    let filter: RequestFilter = this.createDateRangeFilterDashboard(dateRange, mapQuery);
    filter.filter = QueryFilterList.construirAndFilter(mapQuery, "eq", 'status', 'SUCCESS');        
    filter.filter = QueryFilterList.construirAndFilter(mapQuery, "eq", 'type', 'PAYMENT');        
    filter.filter = QueryFilterList.construirAndFilter(mapQuery, "eq", 'trx.isSplitPayment', 'true');  
        if(domain){ filter.filter = QueryFilterList.construirAndFilter(mapQuery, "startsWith", 'domain', domain);     }      
    filter.aggregation = QueryAggregationList.construirAggregation(filter.aggregation, "dateRecord.day", "count");
    filter.aggregation = QueryAggregationList.construirAggregation(filter.aggregation, "trx.currency", "count");
    filter.aggregation = QueryAggregationList.construirAggregation(filter.aggregation, "trx.amount", "sum");
    return filter;
  }

  public createCompareTypeBreakout(dateRange: DateRange, domain:string | undefined): RequestFilter {
    let mapQuery = new Map();
    let range = this.getPreviousDateRange(dateRange);
    let filter = new RequestFilter(null,0, 500, 'date,desc');   
    filter.filter = QueryFilterList.construirAndFilter(mapQuery, "gte", 'date', formatDate(range.from!, 'yyyy-MM-dd', 'en'));        
   // let filter: RequestFilter = this.createDateRangeFilterDashboard(dateRange, mapQuery);

    filter.filter = QueryFilterList.construirAndFilter(mapQuery, "eq", 'status', 'SUCCESS');  
        if(domain){ filter.filter = QueryFilterList.construirAndFilter(mapQuery, "startsWith", 'domain', domain);     }   
        
    filter.aggregation = QueryAggregationList.construirAggregation(filter.aggregation, "type", "count");
    filter.aggregation = QueryAggregationList.construirAggregation(filter.aggregation, "dateRecord.day", "count");
    return filter;
  }

  public createCompareTypeBreakoutByHour(date: Date, domain:string | undefined, currency?: string): RequestFilter {
    let mapQuery = new Map();
    let filter = new RequestFilter(null,0, 500, 'date,desc');   
    filter.filter = QueryFilterList.construirAndFilter(mapQuery, "gte", 'date', formatDate(date, 'yyyy-MM-dd', 'en'));        

    filter.filter = QueryFilterList.construirAndFilter(mapQuery, "eq", 'status', 'SUCCESS');  
    if(domain){
      filter.filter = QueryFilterList.construirAndFilter(mapQuery, "startsWith", 'domain', domain);
    }
    filter.aggregation = QueryAggregationList.construirAggregation(filter.aggregation, "trx.currency", "count");
    filter.aggregation = QueryAggregationList.construirAggregation(filter.aggregation, "type", "count");
    filter.aggregation = QueryAggregationList.construirAggregation(filter.aggregation, "dateRecord.hourDay", "count");
    filter.aggregation = QueryAggregationList.construirAggregation(filter.aggregation, "dateRecord.hourDay", "sum");
    return filter;
  }

  public createCompareCardHolderBreakoutByHour(date: Date, domain:string | undefined): RequestFilter {
    let mapQuery = new Map();
    let filter = new RequestFilter(null,0, 500, 'date,desc');   
    filter.filter = QueryFilterList.construirAndFilter(mapQuery, "gte", 'date', formatDate(date, 'yyyy-MM-dd', 'en'));        

    filter.filter = QueryFilterList.construirAndFilter(mapQuery, "eq", 'status', 'SUCCESS');  
        if(domain){ filter.filter = QueryFilterList.construirAndFilter(mapQuery, "startsWith", 'domain', domain);     }   
            
    filter.aggregation = QueryAggregationList.construirAggregation(filter.aggregation, "type", "count");
    filter.aggregation = QueryAggregationList.construirAggregation(filter.aggregation, "dateRecord.hourDay", "count");
    filter.aggregation = QueryAggregationList.construirAggregation(filter.aggregation, "card.cardType", "count");
    return filter;
  }

  public createTerminalOperationCount(dateRange: DateRange, domain:string | undefined, currency:string | undefined, status:string | undefined): RequestFilter {
    let mapQuery = new Map();
    let filter: RequestFilter = this.createDateRangeFilterDashboard(dateRange, mapQuery);
        if(domain){ filter.filter = QueryFilterList.construirAndFilter(mapQuery, "startsWith", 'domain', domain);     }    
        if(currency){ filter.filter = QueryFilterList.construirAndFilter(mapQuery, "eq", 'trx.currency', currency);     }
        if(status) {
          if(status === "OTHERS"){
            filter.filter = QueryFilterList.construirAndFilter(mapQuery, "ne", 'status', 'SUCCESS'); 
            filter.filter = QueryFilterList.construirAndFilter(mapQuery, "ne", 'status', 'DECLINED'); 
          } else {
            filter.filter = QueryFilterList.construirAndFilter(mapQuery, "eq", 'status', status); 
          }
        }
    filter.aggregation = QueryAggregationList.construirAggregation(filter.aggregation, "terminal.serialnumber", "count");
    return filter;
  }

}
