<main id="main" class="main">
  <div class="pagetitle">
    <h1 i18n>{{ "Transactions" | translate }}</h1>

    <div class="botonesCabecera">
      <div class="selectButtom_navigation">
        <div class="selectButtom">
          <button
            id="icoVerTabla_transaction"
            class="botonIcoArriba botonMarcado"
            (click)="verTabla()"
            *ngIf="!isJustTransactions"
          >
            <i class="bi bi-list-ul"></i>
            <span i18n>{{ "List" | translate }}</span>
          </button>
          <button
            id="icoVerGrafica_transaction"
            class="botonIcoArriba"
            (click)="verGrafica()"
            *ngIf="!isJustTransactions"
          >
            <i class="bi bi-bar-chart-line"></i>
            <span i18n>{{ "Graph" | translate }}</span>
          </button>
        </div>
      </div>
      <div class="dropdown">
        <button
          mat-mini-fab
          *ngIf="isEntornoXiibero"
          class="button-bar"
          id="configurationMenu"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          data-bs-toggle="dropdown"
          data-bs-auto-close="outside"
        >
          <i class="bi bi-gear"></i>
        </button>
        <div class="dropdown-menu">
          <form class="px-4 py-3">
            <mat-radio-group
              aria-labelledby="tz-radio-group-label"
              class="tz-radio-group"
            >
              <mat-radio-button
                class="tz-radio-button"
                *ngFor="let timezone of timezones"
                [value]="timezone.value"
                [checked]="timezone.checked"
                (change)="timeZoneChange($event)"
                id="{{ timezone.name }}"
              >
                {{ timezone.name }}
              </mat-radio-button>
            </mat-radio-group>
            <select
              #selectZones
              class="form-control"
              *ngIf="customTimezoneEnabled"
              (change)="customTimeZoneChange(selectZones.value)"
            >
              <option
                *ngFor="let zone of allTimeZones"
                [value]="zone"
                [selected]="zone === calculateZoneOffset(false)"
              >
                {{ zone }}
              </option>
            </select>
          </form>
        </div>
      </div>
      <button
        mat-mini-fab
        (click)="refrescar()"
        class="button-bar"
        *ngIf="!isJustTransactions"
      >
        <i class="bi bi-arrow-clockwise"></i>
      </button>
    </div>
  </div>

  <mat-accordion>
    <mat-expansion-panel #matexpansionpanel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <i class="bi bi-search"></i>
        </mat-panel-title>
        <mat-panel-description> </mat-panel-description>
      </mat-expansion-panel-header>

      <div class="filterCustos">
        <div class="filterCard" *ngIf="isEntornoGetnet || isEntornoXiibero">
          <mat-form-field ngDefaultControl>
            <mat-label i18n>{{ "Serial Number" | translate }}</mat-label>
            <input
              matInput
              class="form-field"
              [formControl]="serialFilter"
              placeholder=""
            />
          </mat-form-field>
        </div>
        <div class="filterCard" *ngIf="isEntornoGetnet || isEntornoXiibero">
          <mat-form-field ngDefaultControl>
            <mat-label i18n>{{ "Model" | translate }}</mat-label>
            <input
              matInput
              class="form-field"
              [formControl]="modelFilter"
              placeholder=""
            />
          </mat-form-field>
        </div>
        <div class="filterCard" *ngIf="isEntornoXiibero">
          <mat-form-field ngDefaultControl>
            <mat-label i18n>{{ "ref" | translate }}</mat-label>
            <input
              matInput
              class="form-field"
              [formControl]="refFilter"
              placeholder=""
            />
          </mat-form-field>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>

  <div *ngIf="!isJustTransactions && graficaTransaction">
    <div class="row mt-2">
      <div class="col-lg-4">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">{{ "Type of transactions" | translate }}</h5>
            <app-chart-tx-donut
              #chart1
              chartTitle=""
              [chartDataset]="graph1Data"
            ></app-chart-tx-donut>
          </div>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">{{ "Card brands" | translate }}</h5>
            <app-chart-tx-donut
              #chart2
              chartTitle=""
              [chartDataset]="graph2Data"
            ></app-chart-tx-donut>
          </div>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">{{ "Card technologies" | translate }}</h5>
            <app-chart-tx-donut
              #chart3
              chartTitle=""
              [chartDataset]="graph3Data"
            ></app-chart-tx-donut>
          </div>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">
              {{ "Transaction by currency" | translate }}
            </h5>
            <app-chart-tx-donut
              chartTitle=""
              [chartDataset]="graph4Data"
            ></app-chart-tx-donut>
          </div>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">
              {{ "Results of transactions" | translate }}
            </h5>
            <app-chart-tx-donut
              chartTitle=""
              [chartDataset]="graph5Data"
            ></app-chart-tx-donut>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="tablaTransaction" style="overflow: auto; margin-top: 15px">
    <div class="mat-elevation-z8 tablaPersonal">
      <table
        mat-table
        [dataSource]="dataSource"
        multiTemplateDataRows
        matSort
        (matSortChange)="announceSortChange($event)"
        matTableExporter
        #exporter="matTableExporter"
      >
        <!-- merchant_name Column -->
        <ng-container matColumnDef="merchant_name">
          <th
            mat-header-cell
            *matHeaderCellDef
            class="tamano_columna_max same-size-cells"
          >
            <div mat-sort-header i18n class="th_title">{{ "Merchant" | translate }}</div>
            <div class="th_content fix-size">
              <mat-form-field>
                <mat-icon matPrefix class="my-icon"
                  ><i class="bi bi-filter"></i>
                </mat-icon>
                <input
                  matInput
                  class="form-field"
                  [formControl]="merchantFilter"
                  placeholder=""
                />
              </mat-form-field>
            </div>
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            class="tamano_columna_max"
            title="{{ element.merchant?.name }}"
          >
            {{ element.merchant?.name }}
          </td>
        </ng-container>
        <!-- merchantId Column -->
        <ng-container matColumnDef="merchant_merchantId">
          <th mat-header-cell *matHeaderCellDef class="same-size-cells">
            <div mat-sort-header i18n class="th_title">{{ "Merchant Id" | translate }}</div>
            <div class="th_content fix-size">
              <mat-form-field>
                <mat-icon matPrefix class="my-icon"
                  ><i class="bi bi-filter"></i>
                </mat-icon>
                <input
                  matInput
                  class="form-field"
                  [formControl]="merchantIdFilter"
                  placeholder=""
                  maxlength="30"
                />
              </mat-form-field>
            </div>
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.merchant?.merchantId }}
          </td>
        </ng-container>
        <!-- terminalId Column -->
        <ng-container matColumnDef="terminal_terminalId">
          <th mat-header-cell *matHeaderCellDef class="same-size-cells">
            <div mat-sort-header i18n class="th_title">{{ "Terminal Id" | translate }}</div>
            <div class="th_content fix-size">
              <mat-form-field>
                <mat-icon matPrefix class="my-icon"
                  ><i class="bi bi-filter"></i>
                </mat-icon>
                <input
                  matInput
                  class="form-field"
                  [formControl]="terminalIdFilter"
                  placeholder=""
                  maxlength="30"
                />
              </mat-form-field>
            </div>
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.terminal?.terminalId }}
          </td>
        </ng-container>

        <!-- amount Column -->
        <ng-container matColumnDef="trx_amount">
          <th mat-header-cell *matHeaderCellDef class="same-size-cells">
            <div mat-sort-header i18n class="th_title">{{ "Amount" | translate }}</div>
            <div class="th_content fix-size amount-bottom">
              <app-ngx-numeric-range-form-field
                [formControl]="amountFilter"
                label=""
                [resettable]="false"
                appearance="standard"
                (blurred)="onAmountFilterBlur()"
                (numericRangeChanged)="onAmountFilterChange($event)"
              ></app-ngx-numeric-range-form-field>
            </div>
          </th>
          <td mat-cell *matCellDef="let element" class="derecha">
            {{ element.trx?.amount | number : "1.2-2" }}
          </td>
        </ng-container>

        <!-- currency Column -->
        <ng-container matColumnDef="trx_currency">
          <th mat-header-cell *matHeaderCellDef class="same-size-cells">
            <div mat-sort-header i18n class="th_title">{{ "Currency" | translate }}</div>
            <div class="th_content fix-size">
              <mat-form-field>
                <mat-icon matPrefix class="my-icon"
                  ><i class="bi bi-filter"></i>
                </mat-icon>
                <mat-select (selectionChange)="terminalCurrencyCodeSelectFilter($event)">
                  <mat-option *ngFor="let op of terminalCurrencyCodeSelect" [value]="op">
                    {{op}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </th>
          <td mat-cell *matCellDef="let element" class="centrado">
            <span
              *ngIf="
                element.trx &&
                element.trx.currency &&
                element.trx?.currency !== 'UNKNOWN'
              "
              title="{{ getNombreMoneda(element.trx?.currency) }}"
              >{{ element.trx?.currency }}</span
            >
          </td>
        </ng-container>

        <!-- localDate Column -->
        <ng-container matColumnDef="date">
          <th mat-header-cell *matHeaderCellDef class="same-size-cells bigger-cell">
            <div mat-sort-header i18n class="th_title">{{ "Date" | translate }}</div>
            <div class="th_content">
              <mat-form-field>
                <mat-label></mat-label>
                <mat-date-range-input
                  [formGroup]="localDateFilter"
                  [rangePicker]="picker"
                >
                  <input
                    matStartDate
                    formControlName="start"
                    placeholder="{{ 'Start Date' | translate }}"
                  />
                  <input
                    matEndDate
                    formControlName="end"
                    placeholder="{{ 'End Date' | translate }}"
                  />
                </mat-date-range-input>
                <mat-datepicker-toggle
                  matSuffix
                  [for]="picker"
                ></mat-datepicker-toggle>
                <mat-date-range-picker #picker></mat-date-range-picker>
              </mat-form-field>
            </div>
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.date | date : formatDatebyLocale() : timeZoneOffset }}
          </td> </ng-container
        >s

        <!-- pan Column -->
        <ng-container matColumnDef="card_cardInfoObfuscated">
          <th mat-header-cell *matHeaderCellDef class="same-size-cells">
            <div mat-sort-header i18n class="th_title">{{ "PAN" | translate }}</div>
            <div class="th_content fix-size">
              <mat-form-field>
                <mat-icon matPrefix class="my-icon"
                  ><i class="bi bi-filter"></i>
                </mat-icon>
                <input
                  matInput
                  class="form-field"
                  [formControl]="panFilter"
                  placeholder=""
                />
              </mat-form-field>
            </div>
          </th>
          <td mat-cell *matCellDef="let element">
            {{ cleanUnnecessaryWhiteSpaces(element.card?.cardInfoObfuscated) }}
          </td>
        </ng-container>

        <!-- transactionId Column -->
        <ng-container matColumnDef="trx_transactionId">
          <th mat-header-cell *matHeaderCellDef class="same-size-cells">
            <div mat-sort-header i18n class="th_title">{{ "TransactionId" | translate }}</div>
            <div class="th_content fix-size">
              <mat-form-field>
                <mat-icon matPrefix class="my-icon"
                  ><i class="bi bi-filter"></i>
                </mat-icon>
                <input
                  matInput
                  class="form-field"
                  [formControl]="transactionIdFilter"
                  placeholder=""
                />
              </mat-form-field>
            </div>
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.trx?.transactionId }}
          </td>
        </ng-container>

        <!-- authorizationId Column -->
        <ng-container matColumnDef="trx_authcode">
          <th mat-header-cell *matHeaderCellDef class="same-size-cells">
            <div mat-sort-header i18n class="th_title">{{ "Authcode" | translate }}</div>
            <div class="th_content fix-size">
              <mat-form-field>
                <mat-icon matPrefix class="my-icon"
                  ><i class="bi bi-filter"></i>
                </mat-icon>
                <input
                  matInput
                  class="form-field"
                  [formControl]="authorizationIdFilter"
                  placeholder=""
                />
              </mat-form-field>
            </div>
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.trx?.authcode }}
          </td>
        </ng-container>

        <!-- transactionType Column -->
        <ng-container matColumnDef="type">
          <th mat-header-cell *matHeaderCellDef class="same-size-cells">
            <div mat-sort-header i18n class="th_title">{{ "Type" | translate }}</div>
            <div class="th_content fix-size">
              <mat-form-field>
                <mat-icon matPrefix class="my-icon"
                  ><i class="bi bi-filter"></i>
                </mat-icon>
                <mat-label></mat-label>
                <mat-select
                  (selectionChange)="transactionTypeSelectFilter($event)"
                >
                  <mat-option
                    *ngFor="let op of transactionTypeSelect"
                    [value]="op"
                  >
                    {{ utilService.translate(op) }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </th>
          <td mat-cell *matCellDef="let element">
            {{ utilService.translate(element.type) }}
          </td>
        </ng-container>

        <!-- logo Column -->
        <ng-container matColumnDef="logo">
          <th mat-header-cell *matHeaderCellDef></th>
          <td class="centrado card-cell" mat-cell *matCellDef="let element">
            <img
              class="entry-icon"
              [src]="getTransaction_typeLogo(element.type)"
              [alt]=""
            />
          </td>
        </ng-container>

        <!-- cardBrand Column -->
        <ng-container matColumnDef="card_cardType">
          <th mat-header-cell *matHeaderCellDef class="same-size-cells">
            <div mat-sort-header i18n class="th_title">{{ "Card brands" | translate }}</div>
            <div class="th_content fix-size">
              <mat-form-field>
                <mat-icon matPrefix class="my-icon"
                  ><i class="bi bi-filter"></i>
                </mat-icon>
                <mat-label></mat-label>
                <mat-select (selectionChange)="cardBrandSelectFilter($event)">
                  <mat-option *ngFor="let op of cardBrandSelect" [value]="op">
                    {{ translateCardBranches(op) }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </th>
          <td class="centrado card-cell" mat-cell *matCellDef="let element">
            <img
              class="card-icon"
              *ngIf="
                element.card &&
                element.card.cardType &&
                element.card?.cardType !== 'UNKNOWN'
              "
              [src]="getCardLogo(element.card?.cardType)"
              [alt]=""
              title="{{ utilService.translate(element.card?.cardType) }}"
            />
          </td>
        </ng-container>

        <!-- dcc Column -->
        <ng-container matColumnDef="dcc">
          <th mat-header-cell *matHeaderCellDef class="same-size-cells">
            <div mat-sort-header i18n class="th_title">{{ "dcc" | translate | uppercase }}</div>
            <div class="th_content fix-size">
              <mat-form-field>
                <mat-icon matPrefix class="my-icon"
                  ><i class="bi bi-filter"></i>
                </mat-icon>
                <mat-label></mat-label>
                <mat-select (selectionChange)="dccSelectFilter($event)">
                  <mat-option *ngFor="let op of dccCodeSelect" [value]="op">
                    {{ utilService.translate(op) }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </th>
          <td class="centrado card-cell" mat-cell *matCellDef="let element">
              <div *ngIf="element.trx?.dcc" class="card-icon currency-icon-container">
                <i class="bi bi-currency-exchange" ></i>
              </div>
          </td>
        </ng-container>

        <!-- tecnologia Column -->
        <ng-container matColumnDef="card_entry">
          <th mat-header-cell *matHeaderCellDef class="same-size-cells">
            <div mat-sort-header i18n class="th_title">{{ "Entry" | translate }}</div>
            <div class="th_content fix-size">
              <mat-form-field>
                <mat-icon matPrefix class="my-icon"
                  ><i class="bi bi-filter"></i>
                </mat-icon>
                <mat-label></mat-label>
                <mat-select (selectionChange)="resultEntrySelectFilter($event)">
                  <mat-option *ngFor="let op of resultEntrySelect" [value]="op">
                    {{ utilService.translate(op) }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </th>
          <td class="centrado card-cell" mat-cell *matCellDef="let element">
            <img
              *ngIf="
                element.card &&
                element.card.entry &&
                element.card?.entry !== 'UNKNOWN'
              "
              class="entry-icon"
              [src]="getCardEntryLogo(element.card?.entry)"
              title="{{ utilService.translate(element.card?.entry) }}"
              [alt]=""
            />
          </td>
        </ng-container>

        <!-- resultCode Column -->
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef class="same-size-cells">
            <div mat-sort-header i18n class="th_title">{{ "Status" | translate }}</div>
            <div class="th_content fix-size">
              <mat-form-field>
                <mat-icon matPrefix class="my-icon"
                  ><i class="bi bi-filter"></i>
                </mat-icon>
                <mat-label></mat-label>
                <mat-select (selectionChange)="resultCodeSelectFilter($event)">
                  <mat-option *ngFor="let op of resultCodeSelect" [value]="op">
                    {{ utilService.translate(op) }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            class="centrado DEFAULT {{ element.status }}"
          >
            <div>{{ manageStatus(element.status) }}</div>
          </td>
        </ng-container>

        <ng-container matColumnDef="button">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element" class="button">
            <!--BOTONES XIBERO-->
            <div
              *ngIf="
                isEntornoXiibero &&
                (haveTransactions(element) ||
                  haveTicket(element) ||
                  canBeRefunded(element) ||
                  canBeConfirmed(element))
              "
            >
              <button mat-button [matMenuTriggerFor]="menu">
                <i class="bi bi-three-dots"></i>
              </button>
              <mat-menu #menu="matMenu">
                <button
                  mat-menu-item
                  *ngIf="haveTransactions(element)"
                  matTooltip="{{ 'Transactions' | translate }}"
                  (click)="viewTransaction(element)"
                >
                  <i class="bi bi-credit-card"></i>
                  {{ "Transactions" | translate }}
                </button>
                <button
                  mat-menu-item
                  *ngIf="haveTicket(element)"
                  matTooltip="{{ 'Ticket' | translate }}"
                  (click)="viewTicket(element)"
                >
                  <i class="bi bi-file-earmark-pdf"></i>
                  {{ "Ticket" | translate }}
                </button>
                <button
                  mat-menu-item
                  *ngIf="canBeRefunded(element)"
                  matTooltip="{{ 'Refund' | translate }}"
                  (click)="viewReferenced(element, 'REFUND')"
                >
                  <i class="bi bi-bootstrap-reboot"></i>
                  {{ "Refund" | translate }}
                </button>
                <button
                  mat-menu-item
                  *ngIf="canBeConfirmed(element)"
                  matTooltip="{{ 'Confirm' | translate }}"
                  (click)="viewReferenced(element, 'CONFIRM')"
                >
                  <i class="bi bi-check-circle"></i>
                  {{ "Confirm" | translate }}
                </button>

              </mat-menu>

            </div>
            <!--BOTONES GETNET-->
            <div *ngIf="isEntornoGetnet">
              <button
                mat-icon-button
                *ngIf="!isJustTransactions && element.trx?.splitPaymentId"
                matTooltip="{{ 'Transactions' | translate }}"
                (click)="viewTransaction(element)"
              >
                <i class="bi bi-credit-card"></i>
              </button>
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="buttonCards">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element" class="button">
            <!--BOTONES GETNET-->
            <div *ngIf="isEntornoGetnet">
              <button
                mat-icon-button
                *ngIf="element.receiptInfo?.hasReceipt"
                matTooltip="{{ 'Ticket' | translate }}"
                (click)="viewTicket(element)"
              >
                <i class="bi bi-file-earmark-pdf"></i>
              </button>
            </div>
          </td>
        </ng-container>
        

        <!--  Column Expand -->
        <ng-container matColumnDef="expand" style="padding: 0 !important">
          <th mat-header-cell *matHeaderCellDef>
            &nbsp;
          </th>
          <td mat-cell *matCellDef="let element" style="padding: 0 !important">
            <button
              mat-icon-button
              matTooltip="{{ 'View' | translate }}"
              (click)="
                expandedElement = expandedElement === element ? null : element;
                $event.stopPropagation()
              "
            >
              <mat-icon *ngIf="expandedElement !== element"
                >keyboard_arrow_down</mat-icon
              >
              <mat-icon *ngIf="expandedElement === element"
                >keyboard_arrow_up</mat-icon
              >
            </button>
          </td>
        </ng-container>
        <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
        <ng-container matColumnDef="expandedDetail">
          <td
            mat-cell
            *matCellDef="let element"
            [attr.colspan]="displayedColumns.length"
          >
            <div
              class="example-element-detail"
              [@detailExpand]="
                element === expandedElement ? 'expanded' : 'collapsed'
              "
            >
              <div class="element-header">
                <span i18n></span>
              </div>
              <div class="element-body row">
                <div class="col-sm-4">
                  <div class="cardTransaction">
                    <div>
                      <span i18n>{{ "Device Info" | translate }}</span>
                    </div>
                    <mat-form-field *ngIf="isEntornoGetnet || isEntornoXiibero"
                      ><input
                        matInput
                        class="form-field"
                        disabled
                        value="{{ element.terminal?.model }}"
                        placeholder="{{ 'Model' | translate }}"
                    /></mat-form-field>
                    <mat-form-field *ngIf="isEntornoGetnet || isEntornoXiibero"
                      ><input
                        matInput
                        class="form-field"
                        disabled
                        value="{{ element.terminal?.serialnumber }}"
                        placeholder="{{ 'serialnumber' | translate }}"
                    /></mat-form-field>
                    <mat-form-field *ngIf="isEntornoGetnet || isEntornoXiibero"
                      ><input
                        matInput
                        class="form-field"
                        disabled
                        value="{{ element.terminal?.terminalId }}"
                        placeholder="{{ 'terminalId' | translate }}"
                    /></mat-form-field>
                  </div>

                  <div class="cardTransaction">
                    <div>
                      <span i18n>{{ "Card Info" | translate }}</span>
                    </div>
                    <mat-form-field *ngIf="isEntornoGetnet || isEntornoXiibero">
                      <img
                        matSuffix
                        class="card-icon"
                        [src]="getCardLogo(element.card?.cardType)"
                        [alt]=""
                      />
                      <input
                        matInput
                        class="form-field"
                        disabled
                        value="{{
                          utilService.translate(element.card?.cardType)
                        }}"
                        placeholder="{{ 'cardType' | translate }}"
                      />
                    </mat-form-field>
                    <mat-form-field *ngIf="isEntornoGetnet || isEntornoXiibero">
                      <img
                        matSuffix
                        *ngIf="
                          element.card &&
                          element.card.entry &&
                          element.card?.entry !== 'UNKNOWN'
                        "
                        class="entry-icon"
                        [src]="getCardEntryLogo(element.card?.entry)"
                        title="{{ utilService.translate(element.card?.entry) }}"
                        [alt]=""
                      />
                      <input
                        matInput
                        class="form-field"
                        disabled
                        value="{{ utilService.translate(element.card?.entry) }}"
                        placeholder="{{ 'entry' | translate }}"
                      />
                    </mat-form-field>
                    <mat-form-field *ngIf="isEntornoGetnet || isEntornoXiibero"
                      ><input
                        matInput
                        class="form-field"
                        disabled
                        value="{{
                          utilService.translate(
                            element.card?.cardInfoObfuscated
                          )
                        }}"
                        placeholder="{{ 'PAN' | translate }}"
                    /></mat-form-field>
                    <mat-form-field *ngIf="isEntornoGetnet"
                      ><input
                        matInput
                        class="form-field"
                        disabled
                        value="{{ element.card?.aid }}"
                        placeholder="{{ 'aid' | translate }}"
                    /></mat-form-field>
                  </div>
                  
                  <div class="cardTransaction">
                    <div>
                      <span i18n>{{ "CardHolder Info" | translate }}</span>
                    </div>
                    <mat-form-field *ngIf="isEntornoGetnet || isEntornoXiibero"
                      ><input
                        matInput
                        class="form-field"
                        disabled
                        value="{{
                          utilService.translate(element.cardholder?.authmode)
                        }}"
                        placeholder="{{ 'authmode' | translate }}"
                    /></mat-form-field>
                  </div>

                </div>
                <div class="col-sm-4">
                  <div class="cardTransaction">
                    <div>
                      <span i18n>{{ "Transaction Info" | translate }}</span>
                    </div>
                    <div class="subcardTransaction">
                      <span i18n>{{ "Operation" | translate }}</span>

                      <mat-form-field
                        *ngIf="isEntornoGetnet || isEntornoXiibero"
                      >
                        <img
                          matSuffix
                          class="entry-icon"
                          [src]="getTransaction_typeLogo(element.type)"
                          [alt]=""
                        />
                        <input
                          matInput
                          class="form-field"
                          disabled
                          value=" {{ utilService.translate(element.type) }}"
                          placeholder="{{ 'Type' | translate }}"
                        />
                      </mat-form-field>
                      <mat-form-field
                        *ngIf="isEntornoGetnet || isEntornoXiibero"
                        ><input
                          matInput
                          class="form-field DEFAULT {{ element.status }}"
                          disabled
                          value="{{ utilService.translate(element.status) }}"
                          placeholder="{{ 'Status' | translate }}"
                      /></mat-form-field>

                      <mat-form-field
                        *ngIf="isEntornoGetnet || isEntornoXiibero"
                        ><input
                          matInput
                          class="form-field"
                          disabled
                          value="{{
                            element.date
                              | date : 'dd/MM/yyyy, HH:mm:ss' : timeZoneOffset
                          }}"
                          placeholder="{{ 'Date' | translate }}"
                      /></mat-form-field>

                      <mat-form-field
                        *ngIf="isEntornoGetnet || isEntornoXiibero"
                        ><input
                          matInput
                          class="form-field"
                          disabled
                          value="{{
                            element.trx?.amount
                              | currency : element.trx?.currency
                          }}"
                          placeholder="{{ 'Amount' | translate }}"
                      /></mat-form-field>

                      <mat-form-field
                        *ngIf="isEntornoGetnet || isEntornoXiibero"
                        ><input
                          matInput
                          class="form-field"
                          disabled
                          value="{{
                            element.trx?.tips | currency : element.trx?.currency
                          }}"
                          placeholder="{{ 'tips' | translate }}"
                      /></mat-form-field>
                      <mat-form-field *ngIf="isEntornoXiibero"
                        ><input
                          matInput
                          class="form-field"
                          disabled
                          value="{{ element.trx?.country }}"
                          placeholder="{{ 'Country' | translate }}"
                      /></mat-form-field>

                      <mat-form-field *ngIf="isEntornoXiibero"
                        ><input
                          matInput
                          class="form-field"
                          disabled
                          value="{{ element.trx?.ref }}"
                          placeholder="{{ 'ref' | translate }}"
                          title="{{ element.trx?.ref }}"
                      /></mat-form-field>

                      <mat-form-field
                        *ngIf="isEntornoGetnet || isEntornoXiibero"
                        ><input
                          matInput
                          class="form-field"
                          disabled
                          value="{{ element.trx?.splitPaymentId }}"
                          placeholder="{{ 'splitPaymentId' | translate }}"
                      /></mat-form-field>
                      <mat-form-field
                        *ngIf="isEntornoGetnet || isEntornoXiibero"
                        ><input
                          matInput
                          class="form-field"
                          disabled
                          value="{{ element.trx?.sessionId }}"
                          placeholder="{{ 'sessionId' | translate }}"
                      /></mat-form-field>
                      <mat-form-field
                        *ngIf="isEntornoGetnet || isEntornoXiibero"
                        ><input
                          matInput
                          class="form-field"
                          disabled
                          value="{{ element.trx?.arc }}"
                          placeholder="{{ 'arc' | translate }}"
                      /></mat-form-field>

                      <mat-form-field *ngIf="isEntornoXiibero"
                        ><input
                          matInput
                          class="form-field"
                          disabled
                          value="{{
                            element.trx?.referenceData?.transactionId
                          }}"
                          placeholder="{{ 'Reference' | translate }}"
                      /></mat-form-field>

                      <td
                        mat-cell
                        *matCellDef="let element"
                        class="centrado DEFAULT {{ element.status }}"
                      >
                        <div>{{ utilService.translate(element.status) }}</div>
                      </td>
                    </div>
                    <div class="linea"></div>
                    <div class="subcardTransaction">
                      <span i18n>{{ "Processor" | translate }}</span>
                      <mat-form-field
                        *ngIf="isEntornoGetnet || isEntornoXiibero"
                        ><input
                          matInput
                          class="form-field"
                          disabled
                          value="{{ element.trx?.processor?.shortName }}"
                          placeholder="{{ 'Processor' | translate }}"
                      /></mat-form-field>
                      <mat-form-field
                        *ngIf="isEntornoGetnet || isEntornoXiibero"
                        ><input
                          matInput
                          class="form-field"
                          disabled
                          value="{{ element.trx?.processor?.mid }}"
                          placeholder="{{ 'MID' | translate }}"
                      /></mat-form-field>
                    </div>
                  </div>
                </div>
                <div class="col-sm-4">

<div class="cardTransaction">
  <div>
    <span i18n>{{ "Merchant Info" | translate }}</span>
  </div>
  <mat-form-field
    ><input
      matInput
      class="form-field"
      disabled
      value="{{ element.merchant?.name }}"
      placeholder="{{ 'Merchant' | translate }}"
  /></mat-form-field>
  <mat-form-field
    ><input
      matInput
      class="form-field"
      disabled
      value="{{ element.merchant?.merchantId }}"
      placeholder="{{ 'Merchant Id' | translate }}"
  /></mat-form-field>
</div>
<div class="cardTransaction" *ngIf="element.trx?.dcc">
  <div>
    <span i18n>{{ "DCC.title" | translate }}</span>
  </div>
  <mat-form-field
    *ngIf="isEntornoGetnet || isEntornoXiibero"
    ><input
      matInput
      class="form-field"
      disabled
      value="{{
        element.trx?.dcc?.amount
          | currency : element.trx?.dcc?.currency
      }}"
      placeholder="{{ 'DCC.amount' | translate }}"
  /></mat-form-field>
  <mat-form-field *ngIf="isEntornoGetnet || isEntornoXiibero">
    <input
    matInput
    class="form-field"
    disabled
    value="{{
      element.trx?.dcc?.exchange + ' ' + element.trx?.currency + '/' + element.trx?.dcc?.currency
      }}"
    placeholder="{{ 'DCC.exchange' | translate }}"
/></mat-form-field>
<mat-form-field *ngIf="isEntornoGetnet || isEntornoXiibero">
  <input
  matInput
  class="form-field"
  disabled
  value="{{
    element.trx?.dcc?.commision/100
      | percent : '1.2-4'
  }}"
  placeholder="{{ 'DCC.commision' | translate }}"
/></mat-form-field>
<mat-form-field *ngIf="element.trx?.dcc?.markUp || element.trx?.dcc?.markUp === 0 && isEntornoGetnet || isEntornoXiibero">
<input
matInput
class="form-field"
disabled
value="{{
  element.trx?.dcc?.markUp/100
    | percent : '1.2'
}}"
placeholder="{{ 'DCC.markup' | translate }}"
/></mat-form-field>
<mat-form-field *ngIf="element.trx?.dcc?.margenECB || element.trx?.dcc?.margenECB === 0 && isEntornoGetnet || isEntornoXiibero">
<input
matInput
class="form-field"
disabled
value="{{
element.trx?.dcc?.margenECB/100
  | percent : '1.2'
}}"
placeholder="{{ 'DCC.margenECB' | translate }}"
/></mat-form-field>

<mat-form-field *ngIf="isEntornoGetnet || isEntornoXiibero">
<input
matInput
class="form-field"
disabled
value="{{
element.trx?.dcc?.acquirer ? element.trx?.dcc?.acquirer: 'DCC.no-acquierer' | translate
}}"
placeholder="{{ 'DCC.acquirer' | translate }}"
/></mat-form-field>

</div>

                  <div *ngIf="isEntornoGetnet" class="cardTransaction">
                    <div>
                      <span i18n>{{ "Receipt" | translate }}</span>
                    </div>
                    <mat-checkbox
                      *ngIf="isEntornoGetnet"
                      [ngModel]="element.receiptInfo?.hasReceipt"
                      [disabled]="true"
                      >{{ "hasReceipt" | translate }}</mat-checkbox
                    >
                    <mat-checkbox
                      *ngIf="isEntornoGetnet"
                      [ngModel]="element.receiptInfo?.hasCopy"
                      [disabled]="true"
                      >{{ "hasCopy" | translate }}</mat-checkbox
                    >
                    <mat-checkbox
                      *ngIf="isEntornoGetnet"
                      [ngModel]="element.receiptInfo?.hasDigitalSignature"
                      [disabled]="true"
                      >{{ "hasDigitalSignature" | translate }}</mat-checkbox
                    >
                    <mat-checkbox
                      *ngIf="isEntornoGetnet"
                      [ngModel]="element.receiptInfo?.requireSendReceipt"
                      [disabled]="true"
                      >{{ "Digital receipt" | translate }}</mat-checkbox
                    >
                  </div>
                </div>
              </div>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: ['expandedDetail']"
          class="example-detail-row"
        ></tr>
      </table>

      <button
        id="export"
        class="icoDownload"
        mat-icon-button
        (click)="exportIt()"
      >
        <i class="bi bi-file-earmark-arrow-down"></i>
      </button>

      <mat-paginator
        [length]="pageLength"
        [pageSize]="pageSize"
        [pageSizeOptions]="pageSizeOptions"
        (page)="pageEvent($event)"
        showFirstLastButtons
        aria-label="Select page of periodic elements"
      >
      </mat-paginator>
    </div>
  </div>

  <div class="row botonera" *ngIf="verBotones">
    <div class="col-lg-12">
      <button class="btn btn-cancelar" (click)="formExit()" i18n>
        {{ "Cancel" | translate }}
      </button>
    </div>
  </div>
</main>
