export class FileRequest {
    id: string | undefined;
    type: string | undefined;

    constructor(_id: string) {
        this.id = _id;
    }
}

export class HasFilesResponse {
    result: boolean = false;

    constructor(values: Object = {}) {
        Object.assign(this, values);
    }
}
  