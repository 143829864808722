import { Users } from "./users.model";

export class Groups {
  id!: string ;
  name!: string ;
  description!: string ;
  members!:Users[];
  status!: string ;  
  structure!: string ;  
  domain!: string;
  access!: Acces[] ;
  isVisible!: boolean | string;  

}

export class Acces {
  domain!: string ;
  structure!: string ;
  role!: string ;
}

