
import { Merchants } from "../merchants.model";

export class DataExport_Merchant_Xiibero {

  organizationName: string | undefined; 
  regionName: string | undefined;
  divisionName: string | undefined;
  categoryCode: string | undefined;
  name: string | undefined;
  country: string | undefined;
  state: string | undefined;
  city: string | undefined;

  constructor(values: Merchants = new Merchants) {
    this.organizationName= values.hierarchy?.ORGANIZATION?.name
    this.regionName= values.hierarchy?.REGION?.name
    this.divisionName= values.hierarchy?.DIVISION?.name
    this.categoryCode= values.properties?.categoryCode
    this.name= values.name
    this.country= values.info?.address?.country
    this.state= values.info?.address?.state
    this.city= values.info?.address?.city
  }


}


