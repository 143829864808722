import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatLegacySelect, MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { AuthService } from 'src/app/auth/auth.service';
import { Merchants } from 'src/app/models/merchants.model';
import { Organization } from 'src/app/models/organization.model';
import { QueryFilterList, RequestFilter } from 'src/app/models/requestFilter.model';
import { OrganizationsService } from 'src/app/pages/views/organizations/organizations.service';
import { EHierarchyLevel, UtilService } from 'src/app/services/util.service';
import { ENTORNO, TypeJerarquia } from 'src/app/util/constants';
import { VIWER_ENTORNO } from 'src/environments/environment';



@Component({
  selector: 'app-bar-jerarquia',
  templateUrl: './bar-jerarquia.component.html',
  styleUrls: ['./bar-jerarquia.component.css']
})
export class BarJerarquiaComponent implements OnInit {

  
  entorno = VIWER_ENTORNO;

  @Output()  propagar = new EventEmitter<string>();
  @Input() nivel !: string;

  registerForm!: FormGroup;

  verRegion:boolean = false;
  verDivision:boolean = false;
  verMerchant:boolean = false;

  readonlyOrganization:boolean = false;
  readonlyRegion:boolean = false;  
  readonlyDivision:boolean = false; 
  readonlyMerchant:boolean = false;  


  listaOrg: Organization[] = [];
  listaRegion:Merchants[] = [];
  listaDivision:Merchants[] = [];
  listaMerchant:Merchants[] = [];

  selectedOrg: Organization = new Organization();
  selectedInicializacion: Organization = new Organization();
  selectedRegion: Merchants = new Merchants();
  selectedDivision: Merchants = new Merchants();
  selectedMerchant: Merchants = new Merchants();


  constructor(private formBuilder: FormBuilder, private authServer: AuthService,private orgService: OrganizationsService,
    private commonService: UtilService) {       
    }

/*
"domain": "Custosmobile.R0002.D0001.M0018"
"domain": "norg.R0002.D0004.M0018"
*/

  async cargar() {  
    
    let rfOrg: RequestFilter = new RequestFilter(null, 0, 100, "name,desc");
    //let srOrg = await this.orgService.find(rfOrg,true);
    let srOrg = await this.orgService.lookup(rfOrg,true);
    
    if (srOrg) {
      this.listaOrg = srOrg.content; 
     
      if(this.listaOrg.length==1){
        // Si solo hay 1 ponemos por defecto valor
        this.selectedOrg = this.listaOrg[0];
        this.selectedInicializacion=this.selectedOrg;
       this.readonlyOrganization=true;
        localStorage.setItem('ORGANIZATION', JSON.stringify(this.listaOrg[0]));
        this.authServer.jerarquiaSearchClicked.next("");
        
        this.procesarOrganizacion(this.selectedOrg);

      } else { 
        let index = this.findOrgIndex();
        if (index !== -1) {
          this.selectedOrg = this.listaOrg[index];
          this.selectedInicializacion = this.selectedOrg;
          localStorage.setItem('ORGANIZATION', JSON.stringify(this.selectedOrg));
          this.authServer.jerarquiaSearchClicked.next("");
        }
      }
    } 

  }


  findOrgIndex(): number {
    const hostname = window.location.hostname;
    const prefix = hostname.split(/[.-]/)[0].toLowerCase();
    let index = this.findIndexByCriteria(prefix);
    if (index !== -1) {
        return index;
    }
    const tokenClaimsStr = localStorage.getItem('id_token_claims_obj');
    if (tokenClaimsStr) {
        const tokenClaims = JSON.parse(tokenClaimsStr).domain.toLowerCase();
        index = this.findIndexByCriteria(tokenClaims);
        if (index !== -1) {
            return index;
        }
    }
    return -1;
}

findIndexByCriteria(criteria: string): number {
    for (let i = 0; i < this.listaOrg.length; i++) {
        const domainRoot = this.listaOrg[i].domainRoot.toLowerCase();
        if (domainRoot.includes(criteria) || criteria.includes(domainRoot)) {
            return i;
        }
    }
    return -1; 
}

  

  async ngOnInit(): Promise<void> {
    this.registerForm = this.formBuilder.group({
      orgMarcado: [],
      regionMarcado: [],
      divisionMarcado: [], 
      merchantMarcado: [],
  });

    await this.cargar();

    let orgBBDD = null; 
    let regionBBDD=null;
    let divisionBBDD=null;
    let merchantBBDD=null;

    let precargaOrg = localStorage.getItem('ORGANIZATION'); 

    

   // alert(precargaOrg)
    if(precargaOrg){
      let obj: Organization
      obj = JSON.parse(precargaOrg)
      this.selectedOrg=obj

      if( obj?.template){
       
          if(this.commonService.checkJerarquia(obj.template, TypeJerarquia.REGION)){
            //traemos las REGIONES
              //let sr = await this.commonService.pedirRegiones(obj.domainRoot,"R");     
              let sr = await this.commonService.lookup(obj.domainRoot, EHierarchyLevel.REGION, "R");

              if(sr){
                this.verRegion=true 
                this.listaRegion= sr.content;

               this.readonlyRegion = false;
                if(sr.content.length === 1) {
                 this.readonlyRegion = true
                  this.selectedRegion = this.listaRegion[0];
                  this.procesarRegion(this.selectedRegion);
                }
              }

          }else if(this.commonService.checkJerarquia(obj.template, TypeJerarquia.DIVISION)){
            //traemos las DIVISIONES
              //let sr = await this.commonService.pedirDivision(obj.domainRoot,"R");   
              let sr = await this.commonService.lookup(obj.domainRoot, EHierarchyLevel.DIVISION, "R");
              
              this.readonlyDivision = false;
              if(sr){
                this.verDivision=true
                this.listaDivision= sr.content;

                this.readonlyDivision = false
                if(sr.content.length === 1) {
                  this.readonlyDivision = true
                   this.selectedDivision = this.listaDivision[0];
                   this.procesarDivision(this.selectedDivision);
                 }
              }
          } else{
            //traemos las  MECHARNT
              //let sr = await this.commonService.pedirMerchant(obj.domainRoot,"R");   
              let sr = await this.commonService.lookup(obj.domainRoot, EHierarchyLevel.MERCHANT, "R");

              this.readonlyMerchant = false;
              if(sr){
                  this.verMerchant=true
                  this.listaMerchant= sr.content;

                  this.readonlyMerchant = false
                  if(sr.content.length === 1) {
                    this.readonlyMerchant = true
                    this.selectedMerchant = this.listaMerchant[0];
                    this.procesarDivision(this.selectedMerchant);
                  }
                }
          }          
      }else  if(this.entorno==ENTORNO.GETNET){
        //traemos las  MECHARNT

        if(obj.domainRoot && obj.domainRoot !== undefined){
          //let sr = await this.commonService.pedirMerchant(obj.domainRoot,"R");
          let sr = await this.commonService.lookup(obj.domainRoot, EHierarchyLevel.MERCHANT, "R");
        
        if(sr){
            this.verMerchant=true
            this.listaMerchant= sr.content;
          }
      }
    } 
     

      this.listaOrg.forEach(async element => {
        if (element.domainRoot == obj.domainRoot) {
          orgBBDD = element
                  
        };
      });
    }//fin ORGANIZATION



    let precargaReg = localStorage.getItem('REGION');
    if(precargaReg){
      let obj: Merchants
      obj = JSON.parse(precargaReg)
      if(obj && this.selectedOrg.template){
        let tieneDivision = this.commonService.checkJerarquia(this.selectedOrg.template, TypeJerarquia.DIVISION)
        if(tieneDivision){  this.verDivision=true   }

        if(tieneDivision){         
            //traemos las DIVISIONES
            //let srDiv = await this.commonService.pedirDivision(obj.domain,"R");   
            let srDiv = await this.commonService.lookup(obj.domain, EHierarchyLevel.DIVISION, "R");
            if(srDiv){
              this.listaDivision= srDiv.content;
            }
        }else{
            //traemos las  MECHARNT
            //let sr = await this.commonService.pedirMerchant(obj.domain,"R");   
            let sr = await this.commonService.lookup(obj.domain, EHierarchyLevel.MERCHANT, "R");
            if(sr){
              this.listaMerchant= sr.content;
            }
        }        
      }
      
      this.listaRegion.forEach(async element => {
        if(element.domain==obj.domain){
            regionBBDD=element              
          };
      });

    }// fin REGION




    let precargaDiv= localStorage.getItem('DIVISION');
    if(precargaDiv){     
      let obj: Merchants
      obj = JSON.parse(precargaDiv)

      //let sr = await this.commonService.pedirMerchant(obj.domain,"R");   
      let sr = await this.commonService.lookup(obj.domain, EHierarchyLevel.MERCHANT, "R");

      if(sr){
        this.listaMerchant= sr.content;
      }

      this.listaDivision.forEach(async element => {
        if(element.domain==obj.domain){
            divisionBBDD=element
          };
      });

    }// fin DIVISION


    let precargaMec = localStorage.getItem('MERCHANT');
      if(precargaMec){
        let obj: Merchants
        obj = JSON.parse(precargaMec)
        this.listaMerchant.forEach(element => {
          if(element.domain==obj.domain){
              merchantBBDD=element              
            };
        });
      }// fin MERCHANT





    
      if(this.nivel){
        if(this.nivel=="DIVISION"){
         this.readonlyRegion = false;  
         this.readonlyDivision = true; 
         this.readonlyMerchant = true; 
        }else  if(this.nivel=="MERCHANT"){
         this.readonlyRegion = false;  
         this.readonlyDivision = false; 
         this.readonlyMerchant = true;  
        }
      }


      
    this.registerForm = this.formBuilder.group({
      orgMarcado: [{value: orgBBDD, disabled:this.readonlyOrganization}    ],
      regionMarcado: [{value: regionBBDD, disabled:this.readonlyRegion}],
      divisionMarcado: [{value: divisionBBDD, disabled:this.readonlyDivision}], 
      merchantMarcado: [{value: merchantBBDD, disabled:this.readonlyMerchant}],
  });


  }




  async procesarOrganizacion(org:Organization) {

    this.selectedOrg = org;

    localStorage.setItem('ORGANIZATION', JSON.stringify(org));
    localStorage.removeItem('REGION');
    localStorage.removeItem('DIVISION');
    localStorage.removeItem('MERCHANT');
    this.listaRegion= [];
    this.listaDivision = [];
    this.listaMerchant = [];
    this.verRegion = false;  
    this.verDivision = false; 
    this.verMerchant = false;  

   // this.propagar.emit("{'ORGANIZATION':'"+ org?.domainRoot+"'}");
   this.propagar.emit("UPDATE");


    if(org && org.template){
      let tieneRegion = this.commonService.checkJerarquia(org.template, TypeJerarquia.REGION)
      let tieneDivision = this.commonService.checkJerarquia(org.template, TypeJerarquia.DIVISION)
      
      if(tieneRegion){
        //traemos las REGIONES
        
              //let sr = await this.commonService.pedirRegiones(org.domainRoot,"R");     
              let sr = await this.commonService.lookup(org.domainRoot, EHierarchyLevel.REGION, "R");

              if(sr && sr.content && sr.content.length>0){
                this.verRegion=true                
                this.listaRegion= sr.content;

               this.readonlyRegion = false;
                if(sr.content.length === 1) {
                  this.readonlyRegion = true
                  this.selectedRegion = this.listaRegion[0];
     
                  this.registerForm.controls['regionMarcado'].setValue(this.selectedRegion);
                  this.procesarRegion(this.selectedRegion);
                }
              }
              
      }else if(tieneDivision){
         //traemos las DIVISIONES
            //let sr = await this.commonService.pedirDivision(org.domainRoot,"R");
            let sr = await this.commonService.lookup(org.domainRoot, EHierarchyLevel.DIVISION, "R");
            if(sr && sr.content && sr.content.length>0){
              this.verDivision=true
              this.listaDivision= sr.content;

             this.readonlyDivision = false;
              if(sr.content.length === 1) {
               this.readonlyDivision = true
                this.selectedDivision = this.listaDivision[0];
                this.registerForm.controls['divisionMarcado'].setValue(this.selectedDivision);
                this.procesarDivision(this.selectedDivision);
              }
            }
      } else{        
          //traemos las  MERCHANT
            //let sr = await this.commonService.pedirMerchant(org.domainRoot,"R");   
            let sr = await this.commonService.lookup(org.domainRoot, EHierarchyLevel.MERCHANT, "R");
            if(sr){
              this.verMerchant=true
              this.listaMerchant= sr.content;

             this.readonlyMerchant = false;
              if(sr.content.length === 1) {
               this.readonlyMerchant = true
                this.selectedMerchant = this.listaMerchant[0];
                this.registerForm.controls['merchantMarcado'].setValue(this.selectedMerchant);
                this.procesarMerchant(this.selectedMerchant);
              }

            }
      } 
    // if(org.template){
    }else  if(this.entorno==ENTORNO.GETNET){
      //traemos las  MECHARNT
      if(org !== undefined && org.domainRoot ) {

        let sr = await this.commonService.pedirMerchant(org.domainRoot,"R"); 
        if(sr){
          this.verMerchant=true
          this.listaMerchant= sr.content;
        }
    }}
  }

  async procesarRegion(reg: Merchants) {

    this.selectedRegion = reg;

    localStorage.setItem('REGION', JSON.stringify(reg));
    localStorage.removeItem('DIVISION');
    localStorage.removeItem('MERCHANT');
    this.listaDivision = [];
    this.listaMerchant = [];
    this.verDivision = false; 
    this.verMerchant = false; 

    this.propagar.emit("UPDATE");


    if(reg && this.selectedOrg.template){

      let tieneDivision = this.commonService.checkJerarquia(this.selectedOrg.template, TypeJerarquia.DIVISION)
      
      if(tieneDivision){         
          //traemos las DIVISIONES
          //let srDiv = await this.commonService.pedirDivision(reg.domain,"R");   
          let srDiv = await this.commonService.lookup(reg.domain, EHierarchyLevel.DIVISION, "R");
          if(srDiv && srDiv.content && srDiv.content.length>0){
            this.verDivision=true
            this.listaDivision= srDiv.content;

           this.readonlyDivision = false;

            if(srDiv.content.length === 1) {
             this.readonlyDivision = true
              this.selectedDivision = this.listaDivision[0];
              
              this.registerForm.controls['divisionMarcado'].setValue(this.selectedDivision);

              this.procesarDivision(this.selectedDivision);
            }
          }
      }else{        
          //traemos las  MECHARNT
          //let sr = await this.commonService.pedirMerchant(reg.domain,"R");   
          let sr = await this.commonService.lookup(reg.domain, EHierarchyLevel.MERCHANT, "R");

          if(sr && sr.content && sr.content.length>0){
            this.verMerchant=true   
            this.listaMerchant= sr.content;

           this.readonlyMerchant = false;

            if(sr.content.length === 1) {
             this.readonlyMerchant = true
              this.selectedMerchant = this.listaMerchant[0];
              this.registerForm.controls['merchantMarcado'].setValue(this.selectedMerchant);
              this.procesarMerchant(this.selectedMerchant);
            }
          }
      }
    }
  }

  async procesarDivision(reg:Merchants) {

    this.selectedDivision = reg;


    localStorage.setItem('DIVISION', JSON.stringify(reg));
    localStorage.removeItem('MERCHANT');
    this.listaMerchant = [];

    this.propagar.emit("UPDATE");

    this.verRegion=true 

    if(reg){
         //traemos las  MECHARNT
         //let sr = await this.commonService.pedirMerchant(reg.domain,"R");   
         let sr = await this.commonService.lookup(reg.domain, EHierarchyLevel.MERCHANT, "R");
         if(sr){
           this.listaMerchant= sr.content;

          this.readonlyMerchant = false;
           if(sr.content.length === 1) {
           this.readonlyMerchant = true;
            this.selectedMerchant = this.listaMerchant[0];
            this.registerForm.controls['merchantMarcado'].setValue(this.selectedMerchant);

            this.procesarMerchant(this.selectedMerchant);
          }
         }      
    }
  }

  async procesarMerchant(reg: Merchants){
    this.selectedMerchant = reg;

    localStorage.setItem('MERCHANT', JSON.stringify(reg));
    
    this.propagar.emit("UPDATE");

  }

  async organizationFilter(ob:MatSelectChange) {
    let org:Organization
    org=ob.value; 

    this.procesarOrganizacion(org)
  }

  async regionFilter(ob:MatSelectChange) {
    let reg:Merchants
    reg=ob.value; 

    this.procesarRegion(reg);
  }
 
  async divisionFilter(ob:MatSelectChange) {
    let div:Merchants
    div=ob.value; 

    this.procesarDivision(div);
  }

  async merchantFilter(ob:MatSelectChange) {
    let reg:Merchants
    reg=ob.value; 

    this.procesarMerchant(reg);
  }


  borrarFiltro(){
    localStorage.removeItem('ORGANIZATION');
    localStorage.removeItem('REGION');
    localStorage.removeItem('DIVISION');
    localStorage.removeItem('MERCHANT');
    this.listaRegion= [];
    this.listaDivision = [];
    this.listaMerchant = [];
    this.verRegion = false;  
    this.verDivision = false; 
    this.verMerchant = false;  
    this.selectedOrg=new Organization();
    if(this.readonlyOrganization){
      if(this.selectedInicializacion){
        this.procesarOrganizacion(this.selectedInicializacion)
      }else{
        this.procesarOrganizacion(this.selectedOrg)
      }
    }

   this.propagar.emit("UPDATE");
  }

}
