import { Inject, Injectable } from '@angular/core';
import { IndividualConfig, ToastrService } from 'ngx-toastr';
import { AUTH_URL } from 'src/app/auth/app.oauth-tokens';
import { Filter, QueryFilterList, RequestFilter } from 'src/app/models/requestFilter.model';
import { Rol } from 'src/app/models/rol.model';
import { CommonService } from 'src/app/services/common.service';
import { RestService } from 'src/app/services/merchant/Rest.service';
import { AuthRestUrl } from 'src/app/util/constants';


@Injectable({
  providedIn: 'root',
})
export class RolsService {
  private url: any;
  constructor(
    private merchantRest : RestService, 
    public commonService: CommonService) {
    }
  
 
    async findService(_body: RequestFilter, showLoading: boolean){
      //AuthRestUrl+'user/'
      let value = await this.merchantRest.commonRestCallAuth(_body, 'role/',showLoading,true)
      .catch(err => {      
        return null
      })
      return value;   
    }
  
    async saveEditService(_body: Rol){
      
      let value = await this.merchantRest.commonRestCallAuth(_body, 'role/save',true,true)   
      .catch(err => {
        return null
      })
      return value;   
    }

    async deleteService(_id: string){
      let _body={"id":_id} 
      let value = await this.merchantRest.commonRestCallAuth(_body, 'role/delete',true,true)   
      .catch(err => {
        return null
      })
  
      return value;   
    }
  


 
}