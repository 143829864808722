<main id="main" class="main">
   <div class="pagetitle">
      <h1 i18n>{{"Dashboard" | translate }}</h1>
      <div class="filter d-flex justify-content-end">
         <div class="w-100 position-relative filter-datePicker">
            <mat-form-field class="position-absolute datePicker" ngDefaultControl>
               <mat-label></mat-label>
               <mat-date-range-input class="fs-6" [formGroup]="localDateFilter" [rangePicker]="picker">
                  <input matStartDate formControlName="start" placeholder="Start" readonly (click)="picker.open()">
                  <input matEndDate formControlName="end" placeholder="End" readonly (click)="picker.open()">
               </mat-date-range-input>
               <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
               <mat-date-range-picker #picker (closed)="onPickerClosed()"></mat-date-range-picker>
            </mat-form-field>
         </div>
         <div *ngIf="dashboardCurrencies.length > 1" class="d-flex justify-content-end">
            <button type="button"class="btn-h-60 btn btn-primary h-75" data-bs-toggle="dropdown">
               <div class="d-flex justify-content-between w-100">
                  <div>
                     <i class="bi-cash-coin"></i>
                  </div>
                  &nbsp;
                  <div>
                     {{ dashboardCurrency.isoname }}
                  </div>
               </div>
            </button>
            <ul class="dropdown-menu dropdown-menu-end dropdown-menu-arrow pl-3">
               <li class="dropdown-header text-start">
                  <h3 i18n>{{"Currency" | translate }}</h3>
               </li>
               <li type="button" *ngFor="let item of dashboardCurrencies" (click)="selectChangeCurrency($event, item)"
                  class="dropdown-item" [ngClass]="{'active' : item.name === dashboardCurrency.name}" href="#">
                  <img height="40px" width="40px" *ngIf="item.isoname!=='default'" [src]="'assets/flags/' + item.isoname + '.svg' | lowercase">
                  <img height="40px" width="40px" *ngIf="item.isoname==='default'" [src]="'assets/flags/default.jpg' | lowercase" alt ="item.name"> 
                  &nbsp; 
                  {{ item.isoname }}
               </li>
            </ul>
         </div>
      </div>
   </div>

   <section class="section nodashboarddata" *ngIf="!this.hasAccess('transaction') && !this.hasAccess('terminal')">
      <div class="row">
         <div class="col-lg-12">
            <div class="row">
                  <div class="card info-card sales-card">
                     <div class="card-body d-flex justify-content-center">
                        <h2 class="card-title">{{"no data to display" | translate }}</h2>
                     </div>
                  </div>
            </div>
         </div>
      </div>
   </section>

   <section class="section dashboard" *ngIf="this.hasAccess('transaction') || this.hasAccess('terminal')">
      <div class="row">
         <div class="col-lg-12">
            <div class="row dashboard_row-content">
               <div class="col-lg-3 dashboard-summary">
                  <div class="row dashboard-summary-cards">
                     <div class="col-xxl-12 col-md-12">
                        <app-summary-section [staticTerminals]="summarySectionStaticTerminalsAmounts" 
                        [staticTerminalsByCurrency]="summarySectionStaticTerminalsAmountsByCurrency"
                        [staticTerminalsSuccess]="summarySectionStaticSuccessStatusOperations"
                        [staticTerminalsDeclined]="summarySectionStaticDeclinedStatusOperations"
                        [staticTerminalsCurrency]="dashboardCurrency.isoname"
                        ></app-summary-section>
                     </div>
                     <div class="col-xxl-12 col-md-12 pb-2">
                        <div class="card info-card sales-card"  *ngIf="this.hasAccess('transaction')">
                           <div class="card-body" *ngIf="this.hasAccess('transaction')">
                              <div class="summary-card-titles">{{ "Payments" | translate }} </div>
                              <div class="d-flex align-items-center">
                                 <div class="card-icon rounded-circle d-flex align-items-center justify-content-center"> 
                                       <img class="bi" src="assets/img/transaction_type/PAYMENT.svg" style="width: 45px;" alt="PAYMENT">
                                    </div>
                                 <div class="ps-3 row">
                                    <div class="dashboard-card-amount col-sm-12">
                                       <span class="dashboard-summary-amount h3"> {{dashboardIndicators.payments | currency : dashboardCurrency.isoname : 'symbol' : '1.2-2' : locale }}</span>
                                    </div>
                                    <div class="dashboard-card-option col-sm-12">
                                       <span class="fs-6 lh-1 pt-1 fw-bold">{{dashboardIndicators.paymentOps}}   &nbsp;</span>
                                       <span class="text-success fs-6 lh-1 pt-1" i18n>{{"operations" | translate }}</span>
                                    </div>
                                    <div class="dashboard-card-option col-sm-12">
                                       <span class="fs-6 lh-1 pt-1 fw-bold">{{dashboardTipsIndicators.tipsOps}} &nbsp;</span>
                                       <span class="text-success fs-6 lh-1 pt-1" i18n>{{"operations with tips" | translate }}</span>
                                       <span class="fw-bold"> {{dashboardTipsIndicators.tips | currency : dashboardCurrency.isoname : 'symbol' : '1.2-2' : locale }}</span>
                                    </div>
                                    <div class="dashboard-card-oprion col-sm-12">
                                       <span class="fs-6 lh-1 pt-1 fw-bold">{{dashboardSplitIndicators.splitOps}} &nbsp;</span>
                                       <span class="text-success fs-6 lh-1 pt-1" i18n>{{"split operations" | translate }}</span>
                                       <span class="fw-bold"> {{dashboardSplitIndicators.split | currency : dashboardCurrency.isoname : 'symbol' : '1.2-2' : locale }}</span>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div class="col-xxl-12 col-md-12 pb-2" *ngIf="this.hasAccess('transaction')">
                        <div class="card info-card revenue-card">
                           <div class="card-body">
                              <div class="summary-card-titles">{{"Voids" | translate }} </div>
                              <div class="d-flex align-items-center">
                                 <div class="card-icon rounded-circle d-flex align-items-center justify-content-center">                                     
                                    <img class="bi" src="assets/img/transaction_type/VOID.svg" style="width: 45px;" alt="VOID">
                                 </div>
                                 <div class="ps-3 row">
                                    <div class="dashboard-card-amount col-sm-12">
                                       <span class="dashboard-summary-amount h3"> {{dashboardIndicators.voids | currency : dashboardCurrency.isoname : 'symbol' : '1.2-2' : locale }}</span>
                                    </div>
                                    <div class="dashboard-card-oprion col-sm-12">
                                       <span class="fs-6 lh-1 pt-1 fw-bold">{{dashboardIndicators.voidOps}}
                                          &nbsp;</span>
                                       <span class="text-success fs-6 lh-1 pt-1" i18n>{{"operations" | translate }}</span>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div class="col-xxl-12 col-md-12 pb-2" *ngIf="this.hasAccess('transaction')">
                        <div class="card info-card customers-card">
                           <div class="card-body">
                              <div class="summary-card-titles">{{"Refunds" | translate }} </div>
                              <div class="d-flex align-items-center">
                                 <div class="card-icon rounded-circle d-flex align-items-center justify-content-center">                         
                                    <img class="bi" src="assets/img/transaction_type/REFUND.svg" style="width: 45px;" alt="REFUND">
                                 </div>
                                 <div class="ps-3 row">
                                    <div class="dashboard-card-option col-sm-12">
                                       <span class="dashboard-summary-amount h3"> {{dashboardIndicators.refunds | currency : dashboardCurrency.isoname : 'symbol' : '1.2-2' : locale }}</span>
                                    </div>
                                    <div class="dashboard-card-option col-sm-12">
                                       <span class="fs-6 lh-1 pt-1 fw-bold">{{dashboardIndicators.refundOps}}
                                          &nbsp;</span>
                                       <span class="text-success fs-6 lh-1 pt-1" i18n>{{"operations" | translate }}</span>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div class="col-xxl-12 col-md-12 pb-2" *ngIf="this.hasAccess('transaction')">
                        <div class="card info-card preaut-card">
                           <div class="card-body">
                              <div class="summary-card-titles">{{"Preauthoritations" | translate }} </div>
                              <div class="d-flex align-items-center">
                                 <div class="card-icon rounded-circle d-flex align-items-center justify-content-center">         
                                    <img class="bi" src="assets/img/transaction_type/PREAUTH.svg" style="width: 45px;" alt="PREAUTH">
                                 </div>
                                 <div class="ps-3 row">
                                    <div class="dashboard-card-option col-sm-12">
                                       <span class="dashboard-summary-amount h3"> {{dashboardIndicators.preauth | currency : dashboardCurrency.isoname : 'symbol' : '1.2-2' : locale }}</span>
                                    </div>
                                    <div class="dashboard-card-option col-sm-12">
                                       <span class="fs-6 lh-1 pt-1 fw-bold">{{dashboardIndicators.preauthOps}}&nbsp;</span>
                                       <span class="text-success fs-6 lh-1 pt-1" i18n>{{"operations" | translate }}</span>
                                    </div>
                                 <!--
                                    <div class="dashboard-card-option col-sm-12">
                                       <span class="fs-6 lh-1 pt-1 fw-bold">{{dashboardIndicators.modauthOps}}&nbsp;</span>
                                    <span class="text-success fs-6 lh-1 pt-1" i18n>{{"Modify of preauthoritations" | translate }}</span>
                                    <span class="fw-bold"> {{dashboardIndicators.modauth | currency : dashboardCurrency.isoname : 'symbol' : '1.2-2' : locale }}</span>
                                    </div>
                                 -->
                                    <div class="dashboard-card-option col-sm-12">
                                       <span class="fs-6 lh-1 pt-1 fw-bold">{{dashboardIndicators.confirmOps}}&nbsp;</span>                                                          
                                       <span class="text-success fs-6 lh-1 pt-1" i18n>{{"Confirmations" | translate }}</span>
                                       <span class="fw-bold"> {{dashboardIndicators.confirm | currency : dashboardCurrency.isoname : 'symbol' : '1.2-2' : locale }}</span>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div class="col-xxl-12 col-md-12 pb-2" *ngIf="this.hasAccess('transaction')">
                        <div class="card info-card preautModi-card">
                           <div class="card-body">
                              <div class="summary-card-titles">{{"Modify of preauthoritations" | translate }} </div>
                              <div class="d-flex align-items-center">
                                 <div class="card-icon rounded-circle d-flex align-items-center justify-content-center"> 
                                    <img class="bi" src="assets/img/transaction_type/TOPUP.svg" style="width: 45px;" alt="TOPUP">
                                 </div>
                                 <div class="ps-3 row">
                                    <div class="dashboard-card-option col-sm-12">
                                       <span class="dashboard-summary-amount h3"> {{dashboardIndicators.modauth | currency : dashboardCurrency.isoname : 'symbol' : '1.2-2' : locale }}</span>
                                    </div>
                                    <div class="dashboard-card-option col-sm-12">
                                       <span class="fs-6 lh-1 pt-1 fw-bold">{{dashboardIndicators.modauthOps}}&nbsp;</span>
                                    <span class="text-success fs-6 lh-1 pt-1" i18n>{{"operations" | translate }}</span>
                                    <!-- <span class="fw-bold"> {{dashboardIndicators.modauth | currency : dashboardCurrency.isoname : 'symbol' : '1.2-2' : locale }}</span> -->
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="col-lg-9 dashboard-summary dashboard-summary-charts" *ngIf="this.hasAccess('transaction') || this.hasAccess('terminal')">
                  <mat-tab-group preserveContent dynamicHeight (selectedTabChange)="openGroups()">
                     <mat-tab>
                        <ng-template mat-tab-label>
                           <i class="bi bi-graph-up"></i>&nbsp; {{"Accumulations" | translate }}
                        </ng-template>
                        <ng-template matTabContent>
                           <div class="row tabsgraficas" *ngIf="this.hasAccess('transaction') && salesSelectedIndex > 0">
                              <div id="aggTxByHour" class="Accumulations card-chart">
                                 <div  class="card">
                                    <div class="card-body">
                                       <span class="card-title">{{"Transactions grouped by hour" | translate }} 
                                          <button id="aggTxByHour_angle-expand"    (click)="expand('aggTxByHour','Accumulations')" mat-icon-button >
                                             <i class="bi bi-arrows-angle-expand"></i>
                                          </button>
                                          <button id="aggTxByHour_angle-contract" (click)="contrand('aggTxByHour','Accumulations')" style="display:none" mat-icon-button >
                                             <i class="bi bi-arrows-angle-contract"></i>
                                          </button>
                                       </span>
                                       <app-chart-tx-heatmap chartTitle="" [monday]="aggTxByHour.monday"
                                          [tuesday]="aggTxByHour.tuesday" [wednesday]="aggTxByHour.wednesday"
                                          [thursday]="aggTxByHour.thursday" [friday]="aggTxByHour.friday"
                                          [saturday]="aggTxByHour.saturday" [sunday]="aggTxByHour.sunday">
                                       </app-chart-tx-heatmap>
                                    </div>
                                 </div>
                              </div>
                              <div id="aggTxDaily" class="Accumulations card-chart" >
                                 <div  class="card" >
                                    <div class="card-body">
                                       <span class="card-title">{{"Daily Transactions" | translate }}
                                          <button id="aggTxDaily_angle-expand" (click)="expand('aggTxDaily','Accumulations')" mat-icon-button >
                                             <i class="bi bi-arrows-angle-expand"></i>
                                          </button>
                                          <button id="aggTxDaily_angle-contract" (click)="contrand('aggTxDaily','Accumulations')" style="display:none" mat-icon-button >
                                          <i class="bi bi-arrows-angle-contract"></i>
                                          </button>
                                       </span>
                                       <app-chart-tx-daily id="ChartTxDailyNumberComponent" *ngIf="aggTxDaily.dates" chartTitle="" [dates]="aggTxDaily.dates"
                                          [values]="aggTxDaily.values" [currency]="dashboardCurrency.isoname">
                                       </app-chart-tx-daily>
                                    </div>
                                 </div>
                              </div>
                              <div id="aggTxDailyAmount" class="Accumulations card-chart">
                                 <div  class="card">
                                    <div class="card-body">
                                       <span class="card-title">{{"Daily amount transactions" | translate }}
                                          <button id="aggTxDailyAmount_angle-expand"    (click)="expand('aggTxDailyAmount','Accumulations')" mat-icon-button >
                                             <i class="bi bi-arrows-angle-expand"></i>
                                          </button>
                                          <button id="aggTxDailyAmount_angle-contract" (click)="contrand('aggTxDailyAmount','Accumulations')" style="display:none" mat-icon-button >
                                          <i class="bi bi-arrows-angle-contract"></i>
                                          </button>
                                       </span>
                                       <app-chart-tx-daily-amount *ngIf="aggTxDailyAmount.values" chartTitle="Amount by currency"
                                          [dates]="aggTxDailyAmount.dates" [values]="aggTxDailyAmount.values"  [currency]="dashboardCurrency.isoname">
                                       </app-chart-tx-daily-amount>
                                    </div>
                                 </div>
                              </div>
                              <div id="aggSplitTxDailyAmount" class="Accumulations card-chart">
                                 <div  class="card">
                                    <div class="card-body">
                                       <span class="card-title" i18n>{{"Daily amount of splitted transactions" | translate }}
                                          <button id="aggSplitTxDailyAmount_angle-expand"    (click)="expand('aggSplitTxDailyAmount','Accumulations')" mat-icon-button >
                                             <i class="bi bi-arrows-angle-expand"></i>
                                          </button>
                                          <button id="aggSplitTxDailyAmount_angle-contract" (click)="contrand('aggSplitTxDailyAmount','Accumulations')" style="display:none" mat-icon-button >
                                          <i class="bi bi-arrows-angle-contract"></i>
                                          </button>
                                       </span>
                                       <app-chart-tx-daily-amount *ngIf="aggSplitTxDailyAmount.values" chartTitle="Splitted transaction amount by currency"
                                          [dates]="aggSplitTxDailyAmount.dates" [values]="aggSplitTxDailyAmount.values" [currency]="dashboardCurrency.isoname">
                                       </app-chart-tx-daily-amount>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </ng-template>
                     </mat-tab>
                     <mat-tab>
                        <ng-template mat-tab-label>
                           <i class="bi bi-bar-chart-line"></i>&nbsp; {{"Comparatives" | translate }}
                        </ng-template>
                        <ng-template matTabContent>
                           <div class="row tabsgraficas" *ngIf="this.hasAccess('transaction') && salesSelectedIndex > 0">

                              <div id="aggCmpTxDaily" class="Comparatives card-chart" >
                                 <div class="card">
                                    <div class="card-body">
                                       <span class="card-title">{{"Compare daily Transactions" | translate }}
                                          <button id="aggCmpTxDaily_angle-expand"    (click)="expand('aggCmpTxDaily','Comparatives')" mat-icon-button >
                                             <i class="bi bi-arrows-angle-expand"></i>
                                             </button>
                                             <button id="aggCmpTxDaily_angle-contract" (click)="contrand('aggCmpTxDaily','Comparatives')" style="display:none" mat-icon-button >
                                             <i class="bi bi-arrows-angle-contract"></i>
                                             </button>
                                       </span>
                                       <app-chart-tx-compare sequence="1" chartTitle="" 
                                          [dataToCompare]="aggCmpTxDailySales"
                                          [dashboardCurrency]="dashboardCurrency.isoname">
                                       </app-chart-tx-compare>
                                    </div>
                                 </div>
                              </div>
                              
                              <div id="aggCmpTxDailyAmount" class="Comparatives card-chart" >
                                 <div class="card">
                                    <div class="card-body">
                                       <span class="card-title">{{"Compare daily amount transactions" | translate }}
                                          <button id="aggCmpTxDailyAmount_angle-expand"    (click)="expand('aggCmpTxDailyAmount','Comparatives')" mat-icon-button >
                                             <i class="bi bi-arrows-angle-expand"></i>
                                             </button>
                                             <button id="aggCmpTxDailyAmount_angle-contract" (click)="contrand('aggCmpTxDailyAmount','Comparatives')" style="display:none" mat-icon-button >
                                             <i class="bi bi-arrows-angle-contract"></i>
                                             </button>
                                       </span>
                                       <app-chart-tx-compare sequence="2" chartTitle=""
                                          [dataToCompare]="aggCmpTxDailyAmountSales"
                                          [dashboardCurrency]="dashboardCurrency.isoname"
                                          [importsChart]="true">
                                       </app-chart-tx-compare>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div class="row tabsgraficas" *ngIf="this.hasAccess('transaction') && salesSelectedIndex > 0">

                              <div id="aggCmpTypeDaily" class="Comparatives card-chart" >
                                 <div class="card">
                                    <div class="card-body">
                                       <span class="card-title">{{"Compare transactions by type" | translate }}
                                          <button id="aggCmpTypeDaily_angle-expand"    (click)="expand('aggCmpTypeDaily','Comparatives')" mat-icon-button >
                                             <i class="bi bi-arrows-angle-expand"></i>
                                             </button>
                                             <button id="aggCmpTypeDaily_angle-contract" (click)="contrand('aggCmpTypeDaily','Comparatives')" style="display:none" mat-icon-button >
                                             <i class="bi bi-arrows-angle-contract"></i>
                                             </button>
                                       </span>
                                       <app-chart-compare-values  chartTitle=""
                                          [labels]="aggCmpTypeDaily.labels" 
                                          [values]="aggCmpTypeDaily.values"
                                          [values2]="aggCmpTypeDaily.prevValues"
                                          [prevDates]="aggCmpTypeDaily.prevDates"
                                          [actualDates]="aggCmpTypeDaily.actualDates">
                                       </app-chart-compare-values>
                                    </div>
                                 </div>
                              </div>
                              <div id="aggCmpTypeDailyLine" class="Comparatives card-chart" >
                                 <div class="card">
                                    <div class="card-body">
                                       <span class="card-title">{{"COMPARE_TRANSACTIONS_BY_TYPE_DAILY" | translate }}
                                          <button id="aggCmpTypeDailyLine_angle-expand"    (click)="expand('aggCmpTypeDailyLine','Comparatives')" mat-icon-button >
                                             <i class="bi bi-arrows-angle-expand"></i>
                                          </button>
                                          <button id="aggCmpTypeDailyLine_angle-contract" (click)="contrand('aggCmpTypeDailyLine','Comparatives')" style="display:none" mat-icon-button >
                                             <i class="bi bi-arrows-angle-contract"></i>
                                          </button>
                                       </span>
                                       <app-chart-compare-tipes-by-day  chartTitle=""
                                          [aggCmpTypeByDay]="aggCmpTypeByDay">
                                       </app-chart-compare-tipes-by-day>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </ng-template>
                     </mat-tab>
                     <mat-tab>
                        <ng-template mat-tab-label>
                           <i class="bi bi-pie-chart-fill"></i>&nbsp; {{"Transaction breakdowns" | translate }}
                        </ng-template>
                        <ng-template matTabContent>
                           <div class="row tabsgraficas" *ngIf="this.hasAccess('transaction')">
                              <div id="graph1Data" class="breakdowns card-chart" >
                                 <div class="card">
                                    <div class="card-body">
                                       <span class="card-title">{{"Type of transactions" | translate }}
                                          <button id="graph1Data_angle-expand"    (click)="expand('graph1Data','breakdowns')" mat-icon-button >
                                             <i class="bi bi-arrows-angle-expand"></i>
                                          </button>
                                          <button id="graph1Data_angle-contract" (click)="contrand('graph1Data','breakdowns')" style="display:none" mat-icon-button >
                                          <i class="bi bi-arrows-angle-contract"></i>
                                          </button>
                                       </span>
                                       <app-chart-tx-donut #chart1 chartTitle="" [chartDataset]="graph1Data">
                                       </app-chart-tx-donut>
                                    </div>
                                 </div>
                              </div>
                              <div id="graph2Data" class="breakdowns card-chart" >
                                 <div class="card">
                                    <div class="card-body">
                                       <span class="card-title">{{"Card brands" | translate }}
                                          <button id="graph2Data_angle-expand"    (click)="expand('graph2Data','breakdowns')" mat-icon-button >
                                             <i class="bi bi-arrows-angle-expand"></i>
                                          </button>
                                          <button id="graph2Data_angle-contract" (click)="contrand('graph2Data','breakdowns')" style="display:none" mat-icon-button >
                                          <i class="bi bi-arrows-angle-contract"></i>
                                          </button>
                                       </span>
                                       <app-chart-tx-donut #chart2 chartTitle="" [chartDataset]="graph2Data">
                                       </app-chart-tx-donut>
                                    </div>
                                 </div>
                              </div>
                              <div id="graph3Data" class="breakdowns card-chart" >
                                 <div class="card">
                                    <div class="card-body">
                                       <span class="card-title">{{"Card technologies" | translate }}
                                          <button id="graph3Data_angle-expand"    (click)="expand('graph3Data','breakdowns')" mat-icon-button >
                                             <i class="bi bi-arrows-angle-expand"></i>
                                          </button>
                                          <button id="graph3Data_angle-contract" (click)="contrand('graph3Data','breakdowns')" style="display:none" mat-icon-button >
                                          <i class="bi bi-arrows-angle-contract"></i>
                                          </button>
                                       </span>
                                       <app-chart-tx-donut #chart3 chartTitle="" [chartDataset]="graph3Data">
                                       </app-chart-tx-donut>
                                    </div>
                                 </div>
                              </div>
                              <div id="graph4Data" class="breakdowns card-chart" >
                                 <div class="card">
                                    <div class="card-body">
                                       <span class="card-title">{{"Transaction by currency" | translate }}
                                          <button id="graph4Data_angle-expand"    (click)="expand('graph4Data','breakdowns')" mat-icon-button >
                                             <i class="bi bi-arrows-angle-expand"></i>
                                          </button>
                                          <button id="graph4Data_angle-contract" (click)="contrand('graph4Data','breakdowns')" style="display:none" mat-icon-button >
                                          <i class="bi bi-arrows-angle-contract"></i>
                                          </button>
                                       </span>
                                       <app-chart-tx-donut chartTitle="" [chartDataset]="graph4Data">
                                       </app-chart-tx-donut>
                                    </div>
                                 </div>
                              </div>
                              <div id="graph5Data" class="breakdowns card-chart" >
                                 <div class="card">
                                    <div class="card-body">
                                       <span class="card-title">{{"Results of transactions" | translate }}
                                          <button id="graph5Data_angle-expand"    (click)="expand('graph5Data','breakdowns')" mat-icon-button >
                                             <i class="bi bi-arrows-angle-expand"></i>
                                          </button>
                                          <button id="graph5Data_angle-contract" (click)="contrand('graph5Data','breakdowns')" style="display:none" mat-icon-button >
                                          <i class="bi bi-arrows-angle-contract"></i>
                                          </button>
                                       </span>
                                       <app-chart-tx-donut chartTitle="" [chartDataset]="graph5Data">
                                       </app-chart-tx-donut>
                                    </div>
                                 </div>
                              </div>
                              <div id="graph8Data" class="breakdowns card-chart" >
                                 <div class="card">
                                    <div class="card-body">
                                       <span class="card-title">{{"Authorization Mode" | translate }}
                                          <button id="graph8Data_angle-expand"    (click)="expand('graph8Data','breakdowns')" mat-icon-button >
                                             <i class="bi bi-arrows-angle-expand"></i>
                                          </button>
                                          <button id="graph8Data_angle-contract" (click)="contrand('graph8Data','breakdowns')" style="display:none" mat-icon-button >
                                          <i class="bi bi-arrows-angle-contract"></i>
                                          </button>
                                       </span>
                                       <app-chart-tx-donut chartTitle="" [chartDataset]="graph8Data">
                                       </app-chart-tx-donut>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </ng-template>
                     </mat-tab>
                     <mat-tab>
                        <ng-template mat-tab-label>
                           <i class="bi bi-bar-chart-steps"></i>&nbsp; {{"Other charts" | translate }}
                        </ng-template>
                        <ng-template matTabContent>
                           <div class="row tabsgraficas" *ngIf="this.hasAccess('terminal')">
                              <div id="graph6Data" class="Other col-md-6 card-chart" >
                                 <div class="card flex-fill">
                                    <div class="card-body">
                                       <span class="card-title">{{"Device models" | translate }}
                                          <button id="graph6Data_angle-expand"    (click)="expand('graph6Data','Other')" mat-icon-button >
                                             <i class="bi bi-arrows-angle-expand"></i>
                                          </button>
                                          <button id="graph6Data_angle-contract" (click)="contrand('graph6Data','Other')" style="display:none" mat-icon-button >
                                             <i class="bi bi-arrows-angle-contract"></i>
                                          </button>
                                       </span>
                                       <app-chart-model chartTitle="" [chartDataset]="graph6Data"></app-chart-model>
                                    </div>
                                 </div>
                              </div>

                              <div id="graph7Data" class="Other col-md-6 other-flex card-chart" *ngIf="isOrganization">
                                 <div class="card flex-fill">
                                    <div class="card-body flex-column justify-content-between">
                                       <span class="card-title">{{"Top 5 Merchants" | translate }}
                                          <button id="graph7Data_angle-expand"    (click)="expand('graph7Data','Other')" mat-icon-button >
                                             <i class="bi bi-arrows-angle-expand"></i>
                                          </button>
                                          <button id="graph7Data_angle-contract" (click)="contrand('graph7Data','Other')" style="display:none" mat-icon-button >
                                             <i class="bi bi-arrows-angle-contract"></i>
                                          </button>
                                       </span>
                                       <app-chart-top5-merchant chartTitle="{{'Amount' | translate }}" [chartDataset]="graph7Data">
                                       </app-chart-top5-merchant>
                                    </div>
                                 </div>
                              </div>

                              <div id="aggTxMonthAmount" class="Other col-md-6 other-flex card-chart" >
                                 <div  class="card flex-fill" >
                                    <div class="card-body flex-column justify-content-between">
                                       <span class="card-title" i18n>{{"Last_6_months_transactions_and_amounts" | translate }}
                                          <button id="aggTxMonthAmount_angle-expand"    (click)="expand('aggTxMonthAmount','Other')" mat-icon-button >
                                             <i class="bi bi-arrows-angle-expand"></i>
                                          </button>
                                          <button id="aggTxMonthAmount_angle-contract" (click)="contrand('aggTxMonthAmount','Other')" style="display:none" mat-icon-button >
                                          <i class="bi bi-arrows-angle-contract"></i>
                                          </button>
                                       </span>
                                       <app-chart-last-months-sales-tx-amount [chartData]="aggTxMonthAmount" [dates]="aggTxMonthAmount.dates" [values]="aggTxMonthAmount.values" [operations]="aggTxMonthAmount.operations" [currency]="dashboardCurrency.isoname"></app-chart-last-months-sales-tx-amount>
                                    </div>
                                 </div>
                              </div>
                              <div id="aggTxMonth" class="Other col-md-6 other-flex card-chart" >
                                 <div  class="card flex-fill" >
                                    <div class="card-body flex-column justify-content-between">
                                       <span class="card-title" i18n>{{"Last_6_months_transactions" | translate }}
                                          <button id="aggTxMonth_angle-expand"    (click)="expand('aggTxMonth','Other')" mat-icon-button >
                                             <i class="bi bi-arrows-angle-expand"></i>
                                          </button>
                                          <button id="aggTxMonth_angle-contract" (click)="contrand('aggTxMonth','Other')" style="display:none" mat-icon-button >
                                          <i class="bi bi-arrows-angle-contract"></i>
                                          </button>
                                       </span>
                                       <app-chart-last-months-tx [chartData]="aggTxMonth" [dates]="aggTxMonth.dates" [values]="aggTxMonth.values" [operations]="aggTxMonth.operations" [currency]="dashboardCurrency.isoname"></app-chart-last-months-tx>
                                    </div>
                                 </div>
                              </div>
                              <div id="aggAmountMonth" class="Other col-md-6 other-flex card-chart" >
                                 <div  class="card flex-fill" >
                                    <div class="card-body flex-column justify-content-between">
                                       <span class="card-title" i18n>{{"Last_6_months_amounts" | translate }}
                                          <button id="aggAmountMonth_angle-expand"    (click)="expand('aggAmountMonth','Other')" mat-icon-button >
                                             <i class="bi bi-arrows-angle-expand"></i>
                                          </button>
                                          <button id="aggAmountMonth_angle-contract" (click)="contrand('aggAmountMonth','Other')" style="display:none" mat-icon-button >
                                          <i class="bi bi-arrows-angle-contract"></i>
                                          </button>
                                       </span>
                                       <app-chart-last-months-amount [chartData]="aggTxMonth" [dates]="aggTxMonth.dates" [values]="aggTxMonth.values" [operations]="aggTxMonth.operations" [currency]="dashboardCurrency.isoname"></app-chart-last-months-amount>
                                    </div>
                                 </div>
                              </div>
                              
                           </div>
                        </ng-template>
                        
                     </mat-tab>
                     <mat-tab>
                        <ng-template mat-tab-label>
                           <i class="bi bi-bar-chart-line-fill"></i>&nbsp; {{"MULTI_CURRENCY" | translate }}
                        </ng-template>
                        <ng-template matTabContent>
                           <div class="row tabsgraficas" *ngIf="this.hasAccess('transaction') && salesSelectedIndex > 0">
                              <div id="aggTxDailyMultiCurrency" class="Accumulations card-chart" >
                                 <div  class="card" >
                                    <div class="card-body">
                                       <span class="card-title">{{"Daily Transactions" | translate }}
                                          <button id="aggTxDailyMultiCurrency_angle-expand"    (click)="expand('aggTxDailyMultiCurrency','Accumulations')" mat-icon-button >
                                             <i class="bi bi-arrows-angle-expand"></i>
                                          </button>
                                          <button id="aggTxDailyMultiCurrency_angle-contract" (click)="contrand('aggTxDailyMultiCurrency','Accumulations')" style="display:none" mat-icon-button >
                                          <i class="bi bi-arrows-angle-contract"></i>
                                          </button>
                                       </span>
                                       <app-chart-tx-daily *ngIf="aggTxDaily.dates" chartTitle="" [dates]="aggTxDaily.dates"
                                          [values]="aggTxDaily.values" [currency]="dashboardCurrency.isoname" [isMultiCurrency]="true">
                                       </app-chart-tx-daily>
                                    </div>
                                 </div>
                              </div>
                              <div id="aggTxDailyAmountMultiCurrency" class="Accumulations card-chart">
                                 <div  class="card">
                                 <div class="card-body">
                                    <span class="card-title">{{"Daily amount transactions" | translate }}
                                       <button id="aggTxDailyAmountMultiCurrency_angle-expand"    (click)="expand('aggTxDailyAmountMultiCurrency','Accumulations')" mat-icon-button >
                                          <i class="bi bi-arrows-angle-expand"></i>
                                       </button>
                                       <button id="aggTxDailyAmountMultiCurrency_angle-contract" (click)="contrand('aggTxDailyAmountMultiCurrency','Accumulations')" style="display:none" mat-icon-button >
                                       <i class="bi bi-arrows-angle-contract"></i>
                                       </button>
                                    </span>
                                    <app-chart-tx-daily-amount *ngIf="aggTxDailyAmount.values" chartTitle="Amount by currency"
                                       [dates]="aggTxDailyAmount.dates" [values]="aggTxDailyAmount.values"  [currency]="dashboardCurrency.isoname" [isMultiCurrency]="true">
                                    </app-chart-tx-daily-amount>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </ng-template>
                     </mat-tab>
                     <mat-tab>
                        <ng-template mat-tab-label>
                           <i class="bi bi-clock"></i>&nbsp; {{"OVERVIEW" | translate }}
                        </ng-template>
                        <ng-template matTabContent>
                           <div class="row tabsgraficas" *ngIf="this.hasAccess('transaction') && salesSelectedIndex > 0">
                              <div id="aggCmpTypeHourlyToday" class="Comparatives card-chart" >
                                 <div class="card">
                                    <div class="card-body">
                                       <span class="card-title">{{"TABLES_CONFIG.TRANSACTIONS_DAILY_PER_HOUR.PAYMENTS.TABLE_TITLE" | translate }}
                                          <button id="aggCmpTypeHourlyToday_angle-expand"    (click)="expand('aggCmpTypeHourlyToday','Comparatives')" mat-icon-button >
                                             <i class="bi bi-arrows-angle-expand"></i>
                                          </button>
                                          <button id="aggCmpTypeHourlyToday_angle-contract" (click)="contrand('aggCmpTypeHourlyToday','Comparatives')" style="display:none" mat-icon-button >
                                             <i class="bi bi-arrows-angle-contract"></i>
                                          </button>
                                       </span>
                                       <app-chart-compare-tipes-by-hour
                                          [aggCmpTransactionsByHour]="aggCmpByHour.aggCmpTransactionsByHour"
                                          transactionType="PAYMENTS"
                                          >
                                       </app-chart-compare-tipes-by-hour>
                                    </div>
                                 </div>
                              </div>
                              <div id="aggCmpTypeHourlyTodayAmount" class="Comparatives card-chart" >
                                 <div class="card">
                                    <div class="card-body">
                                       <span class="card-title">{{"TABLES_CONFIG.TRANSACTIONS_DAILY_PER_HOUR.TOTAL_CLEAN_AMOUNT.TABLE_TITLE" | translate }}
                                          <button id="aggCmpTypeHourlyTodayAmount_angle-expand"    (click)="expand('aggCmpTypeHourlyTodayAmount','Comparatives')" mat-icon-button >
                                             <i class="bi bi-arrows-angle-expand"></i>
                                          </button>
                                          <button id="aggCmpTypeHourlyTodayAmount_angle-contract" (click)="contrand('aggCmpTypeHourlyTodayAmount','Comparatives')" style="display:none" mat-icon-button >
                                             <i class="bi bi-arrows-angle-contract"></i>
                                          </button>
                                       </span>
                                       <app-chart-compare-tipes-by-hour
                                          [aggCmpTransactionsByHour]="aggCmpByHour.aggCmpTransactionsByHour"
                                          transactionType="TOTAL_CLEAN_AMOUNT"
                                          [currency]="dashboardCurrency.isoname"
                                          >
                                       </app-chart-compare-tipes-by-hour>
                                    </div>
                                 </div>
                              </div>
                              <div id="aggCmpTypeHourlyTodayVisa" class="Comparatives card-chart" >
                                 <div class="card">
                                    <div class="card-body">
                                       <span class="card-title">{{"TABLES_CONFIG.TRANSACTIONS_DAILY_PER_HOUR.TRANSACTIONS_VISA.TABLE_TITLE" | translate }}
                                          <button id="aggCmpTypeHourlyTodayVisa_angle-expand"    (click)="expand('aggCmpTypeHourlyTodayVisa','Comparatives')" mat-icon-button >
                                             <i class="bi bi-arrows-angle-expand"></i>
                                          </button>
                                          <button id="aggCmpTypeHourlyTodayVisa_angle-contract" (click)="contrand('aggCmpTypeHourlyTodayVisa','Comparatives')" style="display:none" mat-icon-button >
                                             <i class="bi bi-arrows-angle-contract"></i>
                                          </button>
                                       </span>
                                       <app-chart-compare-tipes-by-hour
                                          [aggCmpTransactionsByHour]="aggCmpByHour.aggCmpCardHoldersByHour"
                                          transactionType="TRANSACTIONS_VISA"
                                          >
                                       </app-chart-compare-tipes-by-hour>
                                    </div>
                                 </div>
                              </div>
                              <div id="aggCmpTypeHourlyTodayMasterard" class="Comparatives card-chart" >
                                 <div class="card">
                                    <div class="card-body">
                                       <span class="card-title">{{"TABLES_CONFIG.TRANSACTIONS_DAILY_PER_HOUR.TRANSACTIONS_MASTERCARD.TABLE_TITLE" | translate }}
                                          <button id="aggCmpTypeHourlyTodayMasterard_angle-expand"    (click)="expand('aggCmpTypeHourlyTodayMasterard','Comparatives')" mat-icon-button >
                                             <i class="bi bi-arrows-angle-expand"></i>
                                          </button>
                                          <button id="aggCmpTypeHourlyTodayMasterard_angle-contract" (click)="contrand('aggCmpTypeHourlyTodayMasterard','Comparatives')" style="display:none" mat-icon-button >
                                             <i class="bi bi-arrows-angle-contract"></i>
                                          </button>
                                       </span>
                                       <app-chart-compare-tipes-by-hour
                                          [aggCmpTransactionsByHour]="aggCmpByHour.aggCmpCardHoldersByHour"
                                          transactionType="TRANSACTIONS_MASTERCARD"
                                          >
                                       </app-chart-compare-tipes-by-hour>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </ng-template>
                     </mat-tab>
                  </mat-tab-group>
               </div>
            </div>
         </div>
      </div>
   </section>
</main>