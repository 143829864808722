export const DEFAULT_LOCALE = "es";

export const LOCALES = [{
    name: 'es',
    options: {
      months: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
      shortMonths: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
      days: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
      shortDays: ['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab'],
      toolbar: {
        download: 'Descarga SVG',
        selection: 'Selección',
        selectionZoom: 'Zoom Selección',
        zoomIn: 'Zoom dentro',
        zoomOut: 'Zoom fuera',
        pan: 'Desplazamiento',
        reset: 'Resetar Zoom',
        exportToSVG: 'Descarga SVG',
        exportToPNG: 'Descarga PNG',
        exportToCSV: 'Descarga CSV',
        menu: 'Menú'
      }
    }
  },
  {
    name: 'en',
    options: {
      months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
      shortMonths: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      days: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
      shortDays: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
      toolbar: {
        download: 'Download SVG',
        selection: 'Selection',
        selectionZoom: 'Selection Zoom',
        zoomIn: 'Zoom In',
        zoomOut: 'Zoom Out',
        pan: 'Panning',
        reset: 'Reset Zoom',
        exportToSVG: 'Download SVG',
        exportToPNG: 'Download PNG',
        exportToCSV: 'Download CSV',
        menu: 'Menu'
      }
    }
  }
]