import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import {MatLegacyPaginator as MatPaginator, LegacyPageEvent as PageEvent} from '@angular/material/legacy-paginator';
import {MatSort, Sort} from '@angular/material/sort';
import {MatLegacyTableDataSource as MatTableDataSource} from '@angular/material/legacy-table';
import { Merchants } from 'src/app/models/merchants.model';
import { QueryFilterList, RequestFilter } from 'src/app/models/requestFilter.model';
import { CommonService } from 'src/app/services/common.service';
import { VIWER_ENTORNO, environment } from 'src/environments/environment';
import { ConfirmDialogActions, ConfirmDialogData } from 'src/app/components/confirm-dialog/confirm-dialog.model';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { Subscription, debounceTime, distinctUntilChanged } from 'rxjs';
import { ENTORNO } from 'src/app/util/constants';
import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { OrganizationsService } from '../organizations/organizations.service';
import { Organization } from 'src/app/models/organization.model';
import { FormRegionComponent } from './form-new/form-region.component';
import { UtilService } from 'src/app/services/util.service';
import { AuthService } from 'src/app/auth/auth.service';
import { TranslateService } from '@ngx-translate/core';

import * as XLSX from "xlsx";
import { DataExport_Region_Xiibero } from 'src/app/models/dataExport/dataExport_region_Xiibero.model ';
import { MerchantService } from '../merchants/merchant.service';
import { ModalManagerService } from 'src/app/services/modalManager.service';
declare var require: any


enum Columns_Xiibero {
  name = 'name', 
  organization_xiibero = 'hierarchy.ORGANIZATION.name',
  info_email = 'info.email', 
  view = 'view'
}


@Component({
  selector: 'app-region',
  templateUrl: './region.component.html',
  styleUrls: ['./region.component.css']
})
export class RegionComponent implements OnInit, AfterViewInit {

 
  displayedColumns =  Object.keys(Columns_Xiibero);
  dataSource = new MatTableDataSource<Merchants>();

  entorno = VIWER_ENTORNO
  
  pageLength=0;
  pageIndex=0;
  pageSize=environment.defaultPaginacion;
  pageSizeOptions=environment.paginacion;
  sortId = Columns_Xiibero.name + ",desc";//idColumna + "," + direccion


  idFilter = new FormControl();
  merchantEmailFilter = new FormControl();
  nameFilter = new FormControl();
  organizationFilter= new FormControl();
  
  organizationXiiberoSelect!: Organization;
  organizationXiiberoList:Organization[] = [];


  jerarquiaSearchSbscription!: Subscription;

  mapQuery = new Map();
  requestFilter: RequestFilter = new RequestFilter(null,0,this.pageSize,this.sortId);

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  private confirmDialogRef!: MatDialogRef<ConfirmDialogComponent>;
  private dialogRef!: MatDialogRef<FormRegionComponent>;



  constructor(
      private merchantService : MerchantService,
      public dialog: MatDialog,
      public authServer: AuthService,
      public utilServer: UtilService,
      private orgService: OrganizationsService,
      public commonService : CommonService,
      private translate: TranslateService,
      private modalManager: ModalManagerService) {  
  }
  
  categoryCodeDesc(cod:any){
    let mcc = require('merchant-category-code');  
    let val = mcc(cod);
    if(val){
      return val.usda_description;
    }
    return  this.translate.instant("NO_define");
  }

  async getDataSourceAndElements() {
    if(this.entorno === ENTORNO.XIIBERO){
      this.requestFilter.filter = QueryFilterList.construirFilter(this.mapQuery, "type", "REGION"); 
    }
    const dataSourceAndPageLength = this.merchantService.lanzarLlamada(this.requestFilter);
    this.dataSource = (await dataSourceAndPageLength)?.dataSource as MatTableDataSource<Merchants>;
    this.pageLength = (await dataSourceAndPageLength)?.pageLength as number;
  }

cargarCache() {

  let value = this.utilServer.cargarCacheDomain();

  if(value && value!="undefined"){
    this.requestFilter.filter = QueryFilterList.construirFilter(this.mapQuery, "domain", value);        
  }else{
    this.requestFilter.filter = QueryFilterList.construirFilter(this.mapQuery, "domain", undefined);            
  }


  this.getDataSourceAndElements();  
}


async ngOnInit(): Promise<void> {

  
  this.jerarquiaSearchSbscription = this.authServer.getJerarquiaSearchClicked().subscribe(() => this.cargarCache());

    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
   this.displayedColumns =  Object.keys(Columns_Xiibero);
    

      await this.cargar();

   
    this.merchantEmailFilter.valueChanges.pipe(debounceTime(700),distinctUntilChanged()).subscribe((value) => {
      const idColumn = Columns_Xiibero.info_email;
      console.log(idColumn+"="+value);     
      this.requestFilter.filter = QueryFilterList.construirFilter(this.mapQuery, idColumn, value);        
      this.getDataSourceAndElements();     
    });
    this.nameFilter.valueChanges.pipe(debounceTime(700),distinctUntilChanged()).subscribe(value => {
      const idColumn = Columns_Xiibero.name;
      console.log(idColumn+"="+value);     
      this.requestFilter.filter = QueryFilterList.construirFilter(this.mapQuery, idColumn, value);        
      this.getDataSourceAndElements();  
    });       

   

  this.cargarCache()
 
  }



  async cargar(){   
    //organizaciones
      let rfOrg: RequestFilter= new RequestFilter(null,0, 100,"name,desc");       
      let srOrg = await this.orgService.find(rfOrg,true);     
      if(srOrg){
        this.organizationXiiberoList= srOrg.content;
        if(this.organizationXiiberoList.length==1){
          // Si solo hay 1 ponemos por defecto valor
          this.organizationXiiberoSelect = this.organizationXiiberoList[0];
        }
      }

    }



  organizationXiiberoFilter(ob:MatSelectChange) {
    let org:Organization
    org=ob.value; 

    let value:string | undefined
    
    const idColumn = "domain";
    if(org){
      value=org.domainRoot+"."
    }

    this.requestFilter.filter = QueryFilterList.construirFilter(this.mapQuery, idColumn, value);        
    this.getDataSourceAndElements();  
  }


 
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  pageEvent(pag:PageEvent){
    console.log("pageEvent="+JSON.stringify(pag)) 
    this.pageSize = pag.pageSize;
    this.pageIndex = pag.pageIndex;
    this.requestFilter = RequestFilter.construirPage(this.requestFilter.filter , pag.pageIndex,pag.pageSize, this.sortId);
    this.getDataSourceAndElements();
  }
 
  announceSortChange(sortState: Sort) {
    console.log("ordenar="+JSON.stringify(sortState)) 
    let active = sortState.active.replace("_",".")
    let direccion="";
    if(sortState.direction){
      direccion=","+sortState.direction
    }
    this.sortId = active + direccion;
    this.requestFilter = RequestFilter.construirPage(this.requestFilter.filter , this.pageIndex,this.pageSize,this.sortId);
    this.getDataSourceAndElements();
  }

  refrescar() {
    this.getDataSourceAndElements();
  }








  
  onNew(){
    this.dialogRef = this.dialog.open(FormRegionComponent, {
      width: '70%', panelClass: 'custom-modalbox-big',
      data: {//vacio al ser nuevo
      }
    });
    this.modalManager.registerModal(this.dialogRef);

    this.dialogRef.afterClosed().subscribe((result) => {
      if (result === 1) {        
        console.log('CONFIRM recived from dialog window');
        this.getDataSourceAndElements();
      } else if (result === 0) {
        console.log('CANCEL recived from dialog window');
      }
    });
   }

   onEdit(row: any) {

    this.dialogRef = this.dialog.open(FormRegionComponent, {
      width: '70%', panelClass: 'custom-modalbox-big',
      data: {
        obj: row
      }
    });
    this.modalManager.registerModal(this.dialogRef);

    this.dialogRef.afterClosed().subscribe((result) => {
      if (result === 1) {        
        console.log('CONFIRM recived from dialog window');
        this.getDataSourceAndElements();
      } else if (result === 0) {
        console.log('CANCEL recived from dialog window');
      }
    });
  }



   onDelete(row: any) {

    console.log('Delete pressed: ', row);
 
      const dialogData = new ConfirmDialogData();
      this.translate
      .get([
        "Delete Division",        
        "Are you sure you want to delete it?"
      ])
      .subscribe(translation => {
          dialogData.titleI18n = translation["Delete Region"];
          dialogData.textI18n = translation["Are you sure you want to delete it?"];
          dialogData.textValue = row.name;
      
        this.confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
          width: '25%', panelClass: 'custom-modalbox',
          data: dialogData
        });
        this.modalManager.registerModal(this.dialogRef);

      });

    this.confirmDialogRef.afterClosed().subscribe(async (result: ConfirmDialogActions) => {
      if (result === ConfirmDialogActions.CONFIRM) {
        await this.merchantService.deleteService(row.id);
        console.log('CONFIRM recived from dialog window');
        this.getDataSourceAndElements();
      } else if (result === ConfirmDialogActions.CANCEL) {
        console.log('CANCEL recived from dialog window');
      }
    });
  }





  
  exportIt() {
    let newDate = new Date();
    let nombre = "region-"+newDate.toISOString().slice(0, 10)+"-"+newDate.getTime();
    const fileName = nombre+".xlsx";

    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.procesarDataExport(this.dataSource.data));
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    let Heading
    if(this.entorno==ENTORNO.XIIBERO){
      Heading = [[this.translate.instant("Organization"),this.translate.instant("Name")]];
    }else{
      //GETNET
      Heading=[['']]
    }
    XLSX.utils.sheet_add_aoa(ws, Heading);
    XLSX.utils.book_append_sheet(wb, ws, "Region");

    XLSX.writeFile(wb, fileName);
  }

  private procesarDataExport(data:Merchants[]): any[]  {
    
    if(this.entorno==ENTORNO.XIIBERO){
      
      let result : DataExport_Region_Xiibero[] = [] ;
      data.forEach(row => {
        let tempRow:DataExport_Region_Xiibero = new DataExport_Region_Xiibero(row);
        result.push(tempRow);
      });
      return result;
    }else{   
      //GETNET 
      return [];
    }
  }

}

