import { Terminals } from "../terminals.model";

export class DataExport_Terminals {

  terminalId: string | undefined;
  serialnumber: string | undefined;
  merchantName: string | undefined;
  merchantId:string | undefined;
  manufacturer: string | undefined;
  model: string | undefined;
  status: string | undefined; 
  currency:string | undefined;
  country:string | undefined;



  constructor(values: Terminals = new Terminals) {
    this.terminalId= values.terminalId
    this.serialnumber= values.serialnumber
    this.merchantName= values.merchant?.name
    this.merchantId=values.merchant?.merchantId    
    this.manufacturer= values.manufacturer
    this.model= values.model
    this.status= values.status
    this.currency=values.properties?.currency
    this.country=values.properties?.country
    //Object.assign(this, values);
  }



}


