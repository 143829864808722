import { Hierarchy } from "./hierarchy.model";

export class Terminals {
  id: string | undefined;
  terminalId: string | undefined;
  serialnumber: string | undefined;
  model: string | undefined;
  manufacturer: string | undefined;
  status: string | undefined;  
  
  firmwareVersion: string | undefined;  
  appVersion: string | undefined; 
  configVersion: string | undefined;   

  properties: TerminalsProperties | undefined;
  functionalities: TerminalsFunctionalities = new TerminalsFunctionalities;
  organization: OrganizationProperties = new OrganizationProperties;
  merchant: MerchantProperties = new MerchantProperties;

  hasFiles: boolean | undefined;  
  
  hierarchy: Hierarchy | undefined;
}



export class MerchantProperties {
  name:string | undefined;
  merchantId:string | undefined;
}


export class OrganizationProperties {
  name:string | undefined;
  domainRoot:string | undefined;
}


export class TerminalsProperties {
  currency:string | undefined;
  country:string | undefined;
  keysInfo: keysInfoProperties[] | undefined;
}

export class TerminalsFunctionalities {  
  preauthorization:boolean | undefined;
  dcc:boolean | undefined;
  keyentry:boolean | undefined;
  digitalSignature:boolean | undefined;
  clessCVMLimit:number | undefined;
  tip:boolean | undefined;
  advancedTip:any;
  splitPayment:boolean | undefined;
  copyReceipt:boolean | undefined;
  pinEntryPropietary:boolean | undefined;
  offlineDetail:boolean | undefined;
  manualVoid:boolean | undefined;
  automaticInit:boolean | undefined;
  digitalReceipt:boolean | undefined;
  digitalReceiptSendingMode: string | undefined;
}



export class keysInfoProperties {
  type:string | undefined;
  ksi:string | undefined;
  version:number | undefined;
  cvv:string | undefined;
  validFrom:string | undefined;
}
