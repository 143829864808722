import { Transaction } from "../transaction.model";




export class DataExport_Transaction {
  merchantName:string | undefined;
  merchantId:string | undefined;
  terminalId:string | undefined;
  date!: string;
  amount: number| undefined;
  currency:string | undefined;
  type!: string;
  status!: string;
  card: string | undefined;
  pan: string | undefined;
  transactionId: string | undefined;
  authCode: string | undefined;
  
  
  
  constructor(values: Transaction = new Transaction) {
    //ordena para que salga en la exportacion
    this.merchantName= values.merchant?.name
    this.merchantId=values.merchant?.merchantId  
    this.terminalId= values.terminal?.terminalId
    this.date=new Date(values.date).toISOString();
    this.amount=values.trx?.amount
    this.currency=values.trx?.currency

    this.type=values.type
    this.status= values.status
      
    this.card=values.card?.cardType
    this.pan=values.card?.cardInfoObfuscated
    this.transactionId=values.trx?.transactionId
    this.authCode=values.trx?.authcode

    //Object.assign(this, values);
  }
}
