<div id="chart">
   <apx-chart #chartMDL  
     [series]="chartOptions.series!"
     [chart]="chartOptions.chart!"
     [labels]="chartOptions.labels!"
     [fill]="chartOptions.fill!"
     [responsive]="chartOptions.responsive!"
     [colors]="chartOptions.colors!"
     [plotOptions]="chartOptions.plotOptions!"
     [legend]="chartOptions.legend!"
     ></apx-chart>
 </div>