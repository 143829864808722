export class Properties {
      id: string | undefined;
      emailProperties!: EmailProperties ;  
   
  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
  
//  isUsing2FA: string | undefined;  
}




export class EmailProperties {

  host: string | undefined;
  userName!: string | undefined;
  password: string | undefined;
  from: string | undefined;
  port!: number;
  transportProtocol: string | undefined;
  auth!: boolean | true;
  starttlsEnable!: boolean | true;
  starttlsRequired!: boolean | true;
  constructor() {    
  }
}

