<main id="main" class="main">


   <section class="section">
      <div class="row">
         <div class="col-lg-6">
            <div class="card">
               <div class="card-body">
                  
<button type="button" class="btn " (click)="loadCSS(2)">Style Xiibero</button> 
<button type="button" class="btn " (click)="loadCSS(3)">Style Genet</button>
               </div>
            </div>
            </div>
         </div>

         <div class="row">
            <div class="col-lg-6">
               <div class="card">
                  <div class="card-body">
                     <h5 class="card-title">Default Buttons</h5>
                     <button type="button" class="btn btn-primary">Primary</button> <button type="button" class="btn btn-secondary">Secondary</button> <button type="button" class="btn btn-success">Success</button> <button type="button" class="btn btn-danger">Danger</button> <button type="button" class="btn btn-warning">Warning</button> <button type="button" class="btn btn-info">Info</button> <button type="button" class="btn btn-light">Light</button> <button type="button" class="btn btn-dark">Dark</button> <button type="button" class="btn btn-link">Link</button>
                  </div>
               </div>
               </div>
            </div>
   </section>

</main>