import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder,  FormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { ConfirmDialogActions, ConfirmDialogData } from 'src/app/components/confirm-dialog/confirm-dialog.model';
import { Acces, Groups } from 'src/app/models/groups.model';
import { Merchants } from 'src/app/models/merchants.model';
import { Organization } from 'src/app/models/organization.model';
import { QueryFilterList, RequestFilter } from 'src/app/models/requestFilter.model';
import { Rol } from 'src/app/models/rol.model';
import { OrganizationsService } from 'src/app/pages/views/organizations/organizations.service';
import { ENTORNO, FormCreateActions, TypeJerarquia } from 'src/app/util/constants';
import { RolsService } from '../../rol/rol.service';
import { GroupsService } from '../groups.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { UtilService } from 'src/app/services/util.service';
import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { NotifierService } from 'angular-notifier';
import { VIWER_ENTORNO } from 'src/environments/environment';
import { ModalManagerService } from 'src/app/services/modalManager.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/auth/auth.service';


@Component({
  selector: 'app-form-groups',
  templateUrl: './form-groups.component.html',
  styleUrls: ['./form-groups.component.css']
})
export class FormGroupsComponent implements OnInit {
  registerForm!: FormGroup;
  desactivado:boolean = false;
  
  verRegion:boolean = false;  
  verDivision:boolean = false; 
  soloLectura:boolean = false; 
  entorno = VIWER_ENTORNO;
  listaRol:Rol[] = [];
  listaOrg:Organization[] = [];
  listaRegion:Merchants[] = [];
  listaDivision:Merchants[] = [];
  listaMerchant:Merchants[] = [];
  
  selectedOrg:Organization = new Organization();
  selectedRegion: Merchants = new Merchants();
  selectedDivision: Merchants = new Merchants();
  selectedMerch:Merchants = new Merchants();

  private confirmDialogRef!: MatDialogRef<ConfirmDialogComponent>;


  private readonly notifier: NotifierService;
  isAdmin: boolean = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private services: GroupsService,
    private commonService: UtilService,
    private rolsService: RolsService,
    private authService: AuthService,
    notifierService: NotifierService,
    private translate: TranslateService,
    public spinnerService: NgxSpinnerService,
    public orgService: OrganizationsService,
    public modalManager: ModalManagerService,
    public dialogRef: MatDialogRef<FormGroupsComponent>,
    public dialog: MatDialog) { 
     
      this.notifier= notifierService
  }


  async cargar(){

    
    this.spinnerService.show();

    //roles
      let rfRol: RequestFilter= new RequestFilter(null,0, 100,"name,desc");
      let mapQuery = new Map();
      rfRol.filter = QueryFilterList.construirFilter(mapQuery, "status", "ACTIVE");   
      let srRoles = await this.rolsService.findService(rfRol,false);
      if(srRoles){
        //console.log(JSON.stringify(srRoles.content))
        this.listaRol= srRoles.content;
      }

      //organizaciones
      let rfOrg: RequestFilter= new RequestFilter(null,0, 100,"name,desc");       
      //let srOrg = await this.orgService.findCreate(rfOrg,true);
      let srOrg = await this.orgService.find(rfOrg,true);
      if(srOrg){
        this.listaOrg= srOrg.content;    
        if(this.listaOrg.length==1){
          // Si solo hay 1 ponemos por defecto valor
          this.selectedOrg = this.listaOrg[0];
          this.pulsarOrganizationFilter(this.selectedOrg);
        }
      }
     
      this.spinnerService.hide();

    }


  async ngOnInit(): Promise<void> {
    let perfil = this.authService.userAccessLevel();
    if(perfil === "ADMIN"){
      this.isAdmin=true
    }
    
    this.registerForm = this.formBuilder.group({
      id:[""],
      name: ["", Validators.compose([Validators.required,
        Validators.minLength(4),
        Validators.maxLength(50),
        Validators.pattern("^(?=[a-zA-Z0-9._-]{4,50}$)(?!.*[_.-]{2})[^_.-].*[^_.-]$")])]      ,
      description: ["",Validators.compose([
        Validators.maxLength(255), 
        Validators.pattern("^[0-9a-zA-ZÀ-ÿ\\s\\Q.@[]{}()+_-,=:!?^$|/\\E]*$")])],
      rolMarcado:[null, Validators.compose([Validators.required])],
      orgMarcado:[null, Validators.compose([Validators.required])],      
      regionMarcado: [null],
      divisionMarcado: [null], 
      merchantMarcado:[null],
      isVisible: [false],
    });


   await this.cargar();

    let nombre, id , description;
    let isVisible;
    let rolBBDD=null;
    let orgBBDD=null;
    let regionBBDD=null;
    let divisionBBDD=null;
    let merchantBBDD=null;
    if(this.data?.obj){
      //si entra es EDICION
      this.desactivado=true;
      this.soloLectura=true

      let groupBBDD:Groups
      groupBBDD = this.data.obj;


      if(groupBBDD.access && groupBBDD.access.length > 0 &&this.listaRol){
        let rol = groupBBDD.access[0].role;
        this.listaRol.forEach(element => {
              if(element.name==rol){
                rolBBDD=element
              };
          });
      }

      if(groupBBDD.domain){
        let dominio = groupBBDD.domain       
        
        let hasDominio = this.commonService.ObtenerJerarquia(dominio,groupBBDD.structure);
        let dominioOrg=hasDominio.get("O")
        let dominioRegion=hasDominio.get("R")
        let dominioDivision=hasDominio.get("D")
        let dominioMerchant=hasDominio.get("M")

        this.selectedOrg = (this.listaOrg.find(domain => domain.name==dominio)) as Organization;
        this.listaOrg.forEach(async element => {
          if (element.domainRoot == dominioOrg) {
            orgBBDD = element
          };
        });

        if(orgBBDD){ await this.pulsarOrganizationFilter(orgBBDD);       }

        if(dominioRegion){
          this.listaRegion.forEach(element => {
            if(element.domain==dominioRegion){
              regionBBDD=element  
              this.verRegion=true              
            };
          });
          if(regionBBDD){ await this.pulsarRegionFilter(regionBBDD);       }
        }
          
        if(dominioDivision){
           this.listaDivision.forEach(element => {
            if(element.domain==dominioDivision){
              divisionBBDD=element   
              this.verDivision=true             
            };
          });           
          if(divisionBBDD){ await this.pulsarDivisionFilter(divisionBBDD);       }         
        }

        if(dominioMerchant){
            this.listaMerchant.forEach(element => {
              if(element.domain==dominioMerchant){
                  merchantBBDD=element                
                };
            });
        }
      }

      id= groupBBDD.id
      nombre=groupBBDD.name;
      isVisible=groupBBDD.isVisible== "true" ? true:false;
      description=groupBBDD.description;
    }
    
    this.registerForm = this.formBuilder.group({

      name: [nombre, Validators.compose([Validators.required,
        Validators.minLength(4),
        Validators.maxLength(50),
        Validators.pattern("^(?=[a-zA-Z0-9._-]{4,50}$)(?!.*[_.-]{2})[^_.-].*[^_.-]$")])]      ,
      description: [description,Validators.compose([
        Validators.maxLength(255), 
        Validators.pattern("^[0-9a-zA-ZÀ-ÿ\s\Q.@[\]{}()+_\-,=:!?^$|]*$")])],//"^[0-9a-zA-ZÀ-ÿ\\s\\Q.@[]{}()+_-,=:!?^$|/\\E]*$")])],
      id:[id],
      rolMarcado:[rolBBDD, Validators.compose([Validators.required])]      ,
      orgMarcado:[orgBBDD, Validators.compose([Validators.required])]      ,
      regionMarcado: [regionBBDD],
      divisionMarcado: [divisionBBDD], 
      merchantMarcado: [merchantBBDD],
      isVisible:[isVisible]
    });


  }


  async organizationFilter(ob:MatSelectChange) {
      let org:Organization
      org=ob.value; 
      this.pulsarOrganizationFilter(org);
  }

  async pulsarOrganizationFilter(org:Organization) {
    this.selectedOrg = org;

    let mostrarRegion=false
    let mostrarDivision=false
    if(this.entorno==ENTORNO.XIIBERO){
    if(org?.template){

      let tieneRegion = this.commonService.checkJerarquia(org.template, TypeJerarquia.REGION)
      let tieneDivision = this.commonService.checkJerarquia(org.template, TypeJerarquia.DIVISION)
      

      if(tieneRegion){    mostrarRegion=true  }
      if(tieneDivision){  mostrarDivision=true   }


      if(tieneRegion){
        //traemos las REGIONES
              let sr = await this.commonService.pedirRegiones(org.domainRoot,"R");     
              if(sr){
                this.listaRegion= sr.content;
              }
      }else if(tieneDivision){
         //traemos las DIVISIONES
            let sr = await this.commonService.pedirDivision(org.domainRoot,"R");   
            if(sr){
              this.listaDivision= sr.content;
            }
      } else{
          //traemos las  MECHARNT
            let sr = await this.commonService.pedirMerchant(org.domainRoot,"R");   
            if(sr){
              this.listaMerchant= sr.content;
            }
      } 
    }
  }else{
    let sr = await this.commonService.pedirMerchant(org.domainRoot,"R");   // ver
    if(sr){
      this.listaMerchant= sr.content;
    }
  }

    this.verDivision=mostrarDivision
    this.verRegion=mostrarRegion

  }
 

  async regionFilter(ob:MatSelectChange) {
    let reg:Merchants
    reg=ob.value; 
    this.pulsarRegionFilter(reg);   
  }

  async pulsarRegionFilter(reg:Merchants) {

    let mostrarDivision=false

    if(reg && this.selectedOrg.template){

      let tieneDivision = this.commonService.checkJerarquia(this.selectedOrg.template, TypeJerarquia.DIVISION)
      if(tieneDivision){  mostrarDivision=true   }


      if(tieneDivision){         
          //traemos las DIVISIONES
          let srDiv = await this.commonService.pedirDivision(reg.domain,"R");   
          if(srDiv){
            this.listaDivision= srDiv.content;
          }
      }else{
          //traemos las  MECHARNT
          let sr = await this.commonService.pedirMerchant(reg.domain,"R");   
          if(sr){
            this.listaMerchant= sr.content;
          }
      }
    }

    this.verDivision=mostrarDivision
  }

  async divisionFilter(ob:MatSelectChange) {
    let reg:Merchants
    reg=ob.value; 
    this.pulsarDivisionFilter(reg)
  }

  async pulsarDivisionFilter(reg:Merchants) {  
    if(reg){
         //traemos las  MECHARNT
         let sr = await this.commonService.pedirMerchant(reg.domain,"R");   
         if(sr){
           this.listaMerchant= sr.content;
         }     
    }
  }



  onFormSubmit(): void {
    console.log(this.registerForm);
    
    if (this.registerForm.invalid) {
      return;
    }    
    const data = this.applyFormats(this.registerForm);

    let groupsNew : Groups = new Groups();

    groupsNew.id=data['id']  
    groupsNew.name=data['name']
    groupsNew.isVisible=data['isVisible']
    groupsNew.description=data['description']  
    
    let rolNew:Rol
    rolNew=data['rolMarcado']
 
    let domainDetail="O"
    let isPulsadoRegion=false
    let isPulsadoDivision=false
    let isPulsadoMerchant=false
    let isPulsadoSubsidiary=false
     

    let orgNew: Organization
    orgNew = data['orgMarcado']
    let domainRoot= orgNew.domainRoot


    let regionNew:Merchants
    regionNew=data['regionMarcado']
    if(regionNew){
      isPulsadoRegion=true
      domainRoot=regionNew.domain
    }
    
    let divisionNew:Merchants
    divisionNew=data['divisionMarcado']
    if(divisionNew){
      isPulsadoDivision=true
      domainRoot=divisionNew.domain
    }

    let merchantNew:Merchants
    merchantNew=data['merchantMarcado']
    if(merchantNew){
      isPulsadoMerchant=true
      domainRoot=merchantNew.domain
    }
    

    domainDetail = this.commonService.obtenerStructure(isPulsadoRegion,isPulsadoDivision,isPulsadoMerchant,false)

    groupsNew.structure=domainDetail
    let accessNew = new Acces();
    accessNew.structure = domainDetail;
    accessNew.domain = domainRoot;
    accessNew.role = rolNew.name;

    groupsNew.domain = domainRoot;
    groupsNew.access = [accessNew];
    const dialogData = new ConfirmDialogData(); 
    dialogData.titleI18n = this.translate.instant("GROUP.create_title");
    dialogData.textI18n = this.translate.instant("GROUP.create_desc");


    this.confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '25%', panelClass: 'custom-modalbox',
      data: dialogData
    });
    this.modalManager.registerModal(this.dialogRef);

    this.confirmDialogRef.afterClosed().subscribe(async (result: ConfirmDialogActions) => {
      if (result === ConfirmDialogActions.CONFIRM) {
        
          console.log('Form SAVE action');
          await this.services.saveEditService(groupsNew)
          this.dialogRef.close(FormCreateActions.SAVED);
          
          this.notifier.notify('success', this.translate.instant("Save Successfull"));  

      }
    });

  }


  formExit(): void {
    console.log('Form exit action');
    this.dialogRef.close(FormCreateActions.EXIT);
  }



  private applyFormats(formGroup: FormGroup): any {
    const values = formGroup.value;
    const body:any = {};
    // tslint:disable-next-line: forin
    for (const key in values) {
      console.debug(`Form filter field --- ${key}:${values[key]}`);
      const value = values[key];
      if (value !== 'undefined' && value != null && value !== '') {
        body[key] = value;
      }
    }
    return body;
  }

}




